<template>
  <div style="background-color: #f8f8f8" class="tw-p-4">
    <div class="nc-user-info tw-flex tw-flex-row tw-items-center" style="column-gap: 20px">
      <figure class="nc-avatar">
        <img :src="contactAvatar" />
      </figure>
      <div class="tw-flex tw-flex-col tw-justify-start" style="row-gap: 5px">
        <lazy-library-text :text="`${salutation || ''} ${fullName}`"></lazy-library-text>
        <span
          v-for="(info, index) in preferredCommunicationInfos"
          :key="`preferred-info-${index}-${contact.contact_id}`"
          class="phone"
        >
          <lazy-library-text :text="info.value"></lazy-library-text>
        </span>
      </div>
    </div>
    <div class="nc-divider tw-my-4"></div>
    <span v-if="contactAddress" class="nc-location-card tw-mt-4 tw-flex tw-flex-row tw-items-center">
      <lazy-library-text tag="span" class="tw-pl-4" :text="contactAddress"></lazy-library-text>
    </span>
    <div class="nc-divider tw-my-4"></div>
    <div class="tw-flex tw-w-full tw-items-center tw-justify-end">
      <span title="Edit" @click="$emit('edit')">
        <i class="nc-icon-edit btnIcon n-cursor-pointer" style="font-size: 24px" />
      </span>
      <span v-if="lengthOfContacts > 1" title="Remove" @click="$emit('remove')">
        <i class="nc-icon-times-circle btnIcon n-cursor-pointer" style="font-size: 20px" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { SalutationsConstants } from '~/units/contacts/constants/salutations.constants';

export default defineNuxtComponent({
  name: 'RegisterContactCard',

  props: {
    contact: {
      required: true,
    },

    lengthOfContacts: {
      required: true,
      type: Number,
    },
  },

  computed: {
    contactAvatar() {
      if (this.contact) {
        const nuxtApp = useNuxtApp();
        const LS_CDN_URL = nuxtApp.$config.public.LS_CDN_URL;
        if (this.contact.salutation_id === 3) {
          return LS_CDN_URL + 'avatars/male-avatar.png';
        } else {
          return LS_CDN_URL + 'avatars/female-avatar.png';
        }
      }
      return '/assets/images/avatar.png';
    },

    salutation() {
      if (this.contact) {
        const salutation = SalutationsConstants.salutations.find((i) => i.salutation_id === this.contact.salutation_id);
        if (salutation && !salutation.is_hidden) {
          return `${salutation.name}${salutation.is_dot ? '.' : ''}`;
        }
      }
      return null;
    },

    fullName() {
      let fullName = '';
      if (this.contact && this.contact.name) {
        fullName += this.contact.name;
      }

      if (this.contact && this.contact.surname) {
        fullName += ` ${this.contact.surname}`;
      }

      return fullName;
    },

    contactAddress() {
      if (this.contact && this.contact.contact_addresses && this.contact.contact_addresses.length > 0) {
        if (this.contact.contact_addresses[0].display_name) {
          return this.contact.contact_addresses[0].display_name;
        }
      } else if (this.contact && this.contact.contact_address && this.contact.contact_address.display_name) {
        if (this.contact.contact_address.display_name) {
          return this.contact.contact_address.display_name;
        }
      }
      return null;
    },

    preferredCommunicationInfos() {
      if (this.contact) {
        return this.contact.communication_infos.filter((info) => info.preferred);
      }
      return null;
    },
  },
});
</script>

<style scoped>
.nc-avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  display: block;
  overflow: hidden;
}

.nc-avatar img {
  height: 100%;
  width: 100%;
}

.nc-location-card {
}

.nc-location-card:before {
  content: '\e91b';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: #fff;
  background-color: #c4c4c4;
  font-family: 'neuron-common-icons';
  font-size: 1.875rem;
  border-radius: 50%;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0; /* do not shrink - initial value: 1 */
  flex-basis: 60px;
}
</style>
