<template>
  <div
    id="register-question-area"
    class="tw-mb-5 tw-hidden tw-w-full tw-border-b tw-border-[var(--default-border-color)] lg:tw-flex lg:tw-flex-row lg:tw-justify-center"
  >
    <lazy-library-title tag="h3" :text="title" class="tw-py-6"></lazy-library-title>
  </div>
  <div class="register-question-area tw-grid tw-grid-cols-12 tw-py-12">
    <div class="tw-col-span-12 tw-flex tw-flex-col" style="row-gap: 25px">
      <register-question
        v-for="(item, key) in questions"
        :key="`applicant_questions-${key}`"
        v-model:applicant-answers-model="answeredQuestions"
        :question="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import { PFilterSearchType } from '~/units/properties/types';
import { useApplicantsStore } from '~/units/applicants/store';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import RegisterQuestion from '~/components/common/register/RegisterQuestion.vue';
import { ComponentMode } from '~/units/core/types';

export default defineNuxtComponent({
  name: 'RegisterQuestionsArea',
  components: { RegisterQuestion, NcSelect },

  props: {
    searchType: {
      required: true,
      type: String as PropType<PFilterSearchType>,
    },

    mode: {
      required: true,
      type: String as PropType<ComponentMode>,
    },

    title: {
      required: false,
      type: String,
      default: 'Questions',
    },
  },

  mixins: [ComponentMixin],

  data() {
    return {
      answeredQuestions: {},
    };
  },

  async setup(props) {
    const store = useApplicantsStore();
    await store.fetchApplicantQuestions(props.searchType);
  },

  mounted() {
    if (this.mode === ComponentMode.UPDATE && this.applicantUpdateView) {
      const questions = this.applicantUpdateView.question_answers;

      let questionsAnswers = {};
      questions.forEach((question) => {
        if (question.answer && question.answer.values) {
          questionsAnswers = {
            ...questionsAnswers,
            [question.contact_question_id]: question.answer.values,
          };
        }
      });

      this.answeredQuestions = { ...questionsAnswers };
    }
  },

  computed: {
    ...mapState(useApplicantsStore, ['buyerQuestions', 'tenantQuestions', 'applicantUpdateView']),

    questions() {
      if (this.searchType === 'sales') {
        return this.buyerQuestions;
      }
      return this.tenantQuestions;
    },
  },

  methods: {},
});
</script>

<style scoped></style>
