export class ApiService {
  handleError(error: {
    data?: {
      result?: { [key: string]: string[] };
      data?: {
        result?: { [key: string]: string[] };
      };
    };
  }) {
    const errors: { key: string; message: string }[] = [];

    // for api proxy calls, we have to look data in data tree
    const rootError =
      error && error.data && error.data.data ? error.data.data : error && error.data ? error.data : null;

    if (rootError && rootError.result) {
      Object.keys(rootError?.result).forEach((key) => {
        if (rootError?.result && rootError?.result[key]) {
          errors.push({
            key,
            message: rootError?.result[key][0],
          });
        }
      });

      return {
        apiErrorMessage: errors && errors.length > 0 && errors[0] ? errors[0].message : '',
        errors,
      };
    }
  }
}
