<template>
  <div v-if="version === 'v1'">
    <div class="pm-modal-content filter-content px-0 px-lg-3">
      <div class="item-wrapper">
        <lazy-library-title text="Property Type" class="all-style-title" tag="h3"> </lazy-library-title>
        <ol class="pm-tab-items filter-items" style="padding-left: 0 !important">
          <property-type-item
            v-for="(propertyType, index) in propertyTypes"
            :key="`property_type-${index}`"
            :active-type-index="activeTypeIndex"
            :index="index"
            :selected-type-styles="selectedTypeStyles"
            :property-type="propertyType"
            @change-active-type="changeActiveType(index)"
          />
        </ol>
      </div>
      <div class="main-wrapper" :class="mobileSubStyleTab ? 'pm-active' : ''">
        <div class="pm-tab-wrapper filter-wrapper">
          <property-styles-list
            v-if="activePropertyType && selectedTypeStylesModel"
            v-model:selected-type-styles="selectedTypeStylesModel"
            :showing-property-styles="showingPropertyStyles"
            :active-property-type="activePropertyType"
            @close="mobileSubStyleTab = false"
          />
        </div>
      </div>
    </div>
    <div
      v-if="(isMobile && !mobileSubStyleTab) || !isMobile"
      class="buttons-area px-0 px-lg-4 mt-3 mt-lg-0 nc-text tw-flex tw-flex-row tw-items-center tw-justify-end"
    >
      <lazy-library-text
        type="bold"
        text="Cancel"
        class="tw-mr-5 tw-cursor-pointer"
        @click="cancel()"
      ></lazy-library-text>
      <nc-button label="Save" :width="100" @on-click="save()"></nc-button>
    </div>
  </div>

  <div v-else-if="version === 'v2'">
    <div class="pm-modal-content filter-content px-0 px-lg-3">
      <div class="item-wrapper">
        <h4>Property Type</h4>
        <ol class="pm-tab-items filter-items" style="padding-left: 0 !important">
          <property-type-item
            v-for="(propertyType, index) in propertyTypes"
            :key="`property_type-${index}`"
            :active-type-index="activeTypeIndex"
            :index="index"
            :selected-type-styles="selectedTypeStyles"
            :property-type="propertyType"
            @change-active-type="changeActiveType(index)"
            version="v2"
          />
        </ol>
      </div>
      <div class="main-wrapper" :class="mobileSubStyleTab ? 'pm-active' : ''">
        <div class="pm-tab-wrapper filter-wrapper">
          <property-styles-list
            v-if="activePropertyType && selectedTypeStylesModel"
            v-model:selected-type-styles="selectedTypeStylesModel"
            :showing-property-styles="showingPropertyStyles"
            :active-property-type="activePropertyType"
            @close="mobileSubStyleTab = false"
            version="v2"
          />
        </div>
      </div>
    </div>
    <div v-if="(isMobile && !mobileSubStyleTab) || !isMobile" class="tw-flex tw-items-center tw-justify-end">
      <lazy-library-text
        type="bold"
        text="Cancel"
        class="tw-mr-5 tw-cursor-pointer"
        @click="cancel()"
      ></lazy-library-text>
      <button class="n-primary" @click="save()">Save</button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';

import type { PropType } from 'vue';
import PropertyStylesList from '~/components/common/properties/PropertyStylesList.vue';
import PropertyTypeItem from '~/components/common/properties/PropertyTypeItem.vue';
import { usePropertyTypeStyleStore } from '~/units/property_type_styles/store';
import type { PropertyStyle, PropertyType } from '~/units/property_type_styles/types';
import type { PFilterPropertyTypes } from '~/units/properties/types';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { useCoreStore } from '~/units/core/store';

export default defineNuxtComponent({
  name: 'AllPropertyTypesStyles',
  components: {
    NcButton,
    PropertyStylesList,
    PropertyTypeItem,
  },
  props: {
    selectedTypeStyles: {
      required: true,
      type: Array as PropType<PFilterPropertyTypes[]>,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  data(): {
    activeTypeIndex: number;
    mobileSubStyleTab: boolean;
    selectedTypeStylesCopy: PFilterPropertyTypes[];
  } {
    return {
      activeTypeIndex: 0,
      mobileSubStyleTab: false,
      selectedTypeStylesCopy: [],
    };
  },

  created() {
    this.selectedTypeStylesCopy = [...this.selectedTypeStyles];
  },

  emits: ['close', 'update:selectedTypeStyles'],

  computed: {
    ...mapState(usePropertyTypeStyleStore, ['propertyStyles', 'propertyTypes']),
    ...mapState(useCoreStore, ['isMobile']),

    selectedTypeStylesModelOrigin: {
      get() {
        return this.selectedTypeStyles;
      },
      set(value: PFilterPropertyTypes[]) {
        this.$emit('update:selectedTypeStyles', value);
      },
    },

    selectedTypeStylesModel: {
      get() {
        return this.selectedTypeStylesCopy;
      },
      set(value: PFilterPropertyTypes[]) {
        this.selectedTypeStylesCopy = value;
      },
    },

    showingPropertyStyles(): PropertyStyle[] {
      if (this.activePropertyType && this.propertyStyles) {
        return this.propertyStyles.filter(
          (style) => style.property_type_id === this.activePropertyType?.property_type_id,
        );
      }
      return [];
    },

    activePropertyType(): PropertyType | null {
      if (this.propertyTypes) {
        return this.propertyTypes[this.activeTypeIndex] || {};
      }
      return null;
    },
  },
  methods: {
    changeActiveType(index: number) {
      this.activeTypeIndex = index;
      this.mobileSubStyleTab = true;
    },

    save() {
      this.selectedTypeStylesModelOrigin = this.selectedTypeStylesCopy;
      this.close();
    },

    close() {
      this.$emit('close');
    },

    cancel() {
      this.close();
    },
  },
});
</script>

<style scoped>
.cancel-button-area {
  margin-right: 10px;
}
</style>
