<template>
  <!-- for old structure -->
  <nc-button v-if="isOldStructure" @on-click="didClick()" :is-disabled="isDisabled" :label="label"> </nc-button>
  <button
    v-else-if="!isOldStructure"
    :class="`n-${styleName}`"
    class="n-primary tw-cursor-pointer"
    @click="didClick()"
    :disabled="isDisabled"
  >
    <label class="button-label tw-cursor-pointer">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </button>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';
import NcButton from '~/components/common/inputs/NcButton.vue';

export default defineNuxtComponent({
  name: 'LibraryButton',
  components: { NcButton },

  // Still WIP

  props: {
    label: {
      required: false,
      type: String,
    },

    isDisabled: {
      required: false,
      type: Boolean,
    },

    height: {
      required: false,
      type: Number,
      default: 60,
    },

    width: {
      required: false,
      type: Number,
      default: null,
    },

    styleName: {
      required: false,
      default: 'primary',
      type: String,
    },

    isLink: {
      required: false,
      type: Boolean,
      default: false,
    },

    linkTarget: {
      required: false,
      type: String,
      default: null,
    },
  },

  emits: ['didClick'],

  computed: {
    ...mapState(useWebsiteStore, ['isOldStructure']),
  },

  methods: {
    didClick() {
      this.$emit('didClick');
    },
  },
});
</script>
