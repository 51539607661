import { defineStore } from 'pinia';
import { PFilterSearchType } from '~/units/properties/types';
import { ApplicantQuestionsService, ApplicantsService } from '~/units/applicants/services';
import { useContactsStore } from '~/units/contacts/store';
import { ContactProfiles } from '~/constants/contacts';
import type { Applicant } from '~/units/applicants/types';
import { ApplicantsConstants } from '~/units/applicants/constants';

export const useApplicantsStore = defineStore('applicants', {
  state: (): {
    tenantQuestions: any[];
    buyerQuestions: any[];
    applicant: null | Applicant;
    applicants: Applicant[];
    applicantUpdateView: null | Applicant;
  } => {
    return {
      tenantQuestions: [],
      buyerQuestions: [],

      applicant: null,
      applicants: [],

      applicantUpdateView: null,
    };
  },
  actions: {
    async changeActiveLoggedApplicantAndSearch(selectedApplicant: Applicant) {
      await this.changeActiveLoggedApplicant(selectedApplicant);
      this.applicant = { ...selectedApplicant };
    },

    async changeActiveLoggedApplicant(selectedApplicant: Applicant) {
      const contactsStore = useContactsStore();
      await contactsStore.updateSession(selectedApplicant.applicant_key);
    },

    async updateStatusOfApplicant(contactApplicantId: number, status: boolean) {
      try {
        const applicantsService = new ApplicantsService();
        const { data } = await applicantsService.updateStatus(contactApplicantId, status);
        this.applicants = this.applicants.map((applicant) => {
          if (applicant.contact_applicant_id === contactApplicantId) {
            return data;
          }
          return applicant;
        });

        if (this.applicant?.contact_applicant_id === contactApplicantId) {
          const applicantWillBeSelectedNext = this.applicants.find(
            (item) =>
              item.contact_applicant_status_id === ApplicantsConstants.STATUS_ON &&
              this.applicant?.profile_id === item.profile_id,
          );
          if (applicantWillBeSelectedNext) {
            await this.changeActiveLoggedApplicantAndSearch(applicantWillBeSelectedNext);
          }
        }
      } catch (e) {}
    },

    async fetchApplicants() {
      const contactsStore = useContactsStore();

      if (contactsStore.contactInfo?.contact_id && this.applicants.length === 0) {
        const applicantsService = new ApplicantsService();
        const { data } = await applicantsService.fetch(contactsStore.contactInfo?.contact_id);
        this.applicants = data;
      }
    },

    async fetchApplicant(applicantKey: string) {
      try {
        const applicantsService = new ApplicantsService();
        const { data } = await applicantsService.get(applicantKey);
        this.applicant = data;
      } catch (e) {}
    },

    getDesiredApplicant(
      type: PFilterSearchType,
      isSessionPriority = false,
    ): {
      applicant_key: string | undefined;
      profile_id: number | undefined;
    } {
      const contactsStore = useContactsStore();
      const neededProfileId = type === PFilterSearchType.LETTINGS ? ContactProfiles.TENANT : ContactProfiles.BUYER;

      if (contactsStore.contactInfo?.applicant_information.applicants) {
        const sessionKey = contactsStore.sessionData?.session;
        if (sessionKey) {
          if (isSessionPriority) {
            const prioritisedApplicant = contactsStore.contactInfo?.applicant_information.applicants.find(
              (item) => item.applicant_key === sessionKey,
            );

            return {
              applicant_key: prioritisedApplicant?.applicant_key,
              profile_id: prioritisedApplicant?.profile_id,
            };
          }

          const sessionApplicantHasDesiredProfile = contactsStore.contactInfo?.applicant_information.applicants.find(
            (item) => item.profile_id === neededProfileId && item.applicant_key === sessionKey,
          );
          if (sessionApplicantHasDesiredProfile) {
            return {
              applicant_key: sessionApplicantHasDesiredProfile.applicant_key,
              profile_id: sessionApplicantHasDesiredProfile.profile_id,
            };
          }
        }
        const latestApplicantHasDesiredProfile = contactsStore.contactInfo?.applicant_information.applicants.find(
          (item) => item.profile_id === neededProfileId,
        );
        return {
          applicant_key: latestApplicantHasDesiredProfile?.applicant_key,
          profile_id: latestApplicantHasDesiredProfile?.profile_id,
        };
      }
      return {
        applicant_key: undefined,
        profile_id: undefined,
      };
    },

    async fetchApplicantBySessionAndType(type: PFilterSearchType) {
      try {
        const applicant = this.getDesiredApplicant(type);

        if (applicant && applicant.applicant_key) {
          const applicantsService = new ApplicantsService();
          const { data } = await applicantsService.get(applicant.applicant_key);
          this.applicant = data;
        }
      } catch (e) {}
    },

    async createApplicant(body: any) {
      const applicantsService = new ApplicantsService();

      const { data } = await applicantsService.create({
        ...body,
        unknown_user_branch: true,
      });
      const contactId: number = data.contacts[0].contact_id;

      const contactsStore = useContactsStore();

      await contactsStore.updateSession(data.applicant_key, contactId);

      return true;
    },

    async updateApplicant(applicantId: number, body: any) {
      const applicantsService = new ApplicantsService();

      const { data } = await applicantsService.update(applicantId, body);

      this.applicant = data;
      this.applicantUpdateView = data;
      this.applicants = [];

      const contactsStore = useContactsStore();
      await contactsStore.updateSession(data.applicant_key);

      return true;
    },

    async fetchApplicantQuestions(searchType: PFilterSearchType) {
      const type = searchType === 'lettings' ? 'tenant' : 'buyer';
      const state = (() => {
        if (type === 'tenant') {
          return 'tenantQuestions';
        }
        return 'buyerQuestions';
      })();

      if (!this[state].length) {
        const applicantQuestionsService = new ApplicantQuestionsService();

        const result = await applicantQuestionsService.fetch(type);
        if (result && result.data) {
          this[state] = result.data.map((question: any) => {
            return {
              ...question,
              is_required: true,
            };
          });
        }
      }
    },

    async fetchApplicantToUpdate(applicantKey: string) {
      if (this.applicant && this.applicant.applicant_key === applicantKey) {
        this.applicantUpdateView = { ...this.applicant };
      } else if (!this.applicant || this.applicant.applicant_key !== applicantKey) {
        try {
          const applicantsService = new ApplicantsService();
          const { data } = await applicantsService.get(applicantKey);
          if (!this.applicant) {
            this.applicant = data;
          }
          this.applicantUpdateView = { ...data };
        } catch (e) {
          // keep it in initial version
        }
      }
    },
  },
  getters: {
    isApplicantExist(state) {
      return !!state.applicant;
    },

    getApplicantByType(state) {
      return (searchType: PFilterSearchType) => {
        if (state.applicant) {
          if (searchType === PFilterSearchType.SALES && state.applicant.profile_id === ContactProfiles.BUYER) {
            return state.applicant;
          } else if (
            (searchType === PFilterSearchType.LETTINGS && state.applicant.profile_id) === ContactProfiles.TENANT
          ) {
            return state.applicant;
          }
        }
        return null;
      };
    },

    listApplicantsByType() {
      return (searchType: PFilterSearchType) => {
        if (searchType) {
          const desiredProfileId =
            searchType === PFilterSearchType.LETTINGS ? ContactProfiles.TENANT : ContactProfiles.BUYER;

          return this.applicants.filter((applicant) => applicant.profile_id === desiredProfileId);
        }
        return this.applicants;
      };
    },

    requiredQuestionsByType(state) {
      return (type: PFilterSearchType) => {
        const questions = (() => {
          if (type === PFilterSearchType.LETTINGS) {
            return state.tenantQuestions;
          }
          return state.buyerQuestions;
        })();

        if (questions) {
          return questions
            .filter((question: { is_required: boolean }) => question.is_required)
            .map((question: { contact_question_id: number; question: string }) => {
              return {
                contact_question_id: question.contact_question_id,
                question: question.question,
              };
            });
        }
        return [];
      };
    },

    loggedContact(state) {
      const contactsStore = useContactsStore();
      return state.applicant?.contacts.find(
        (contact: any) => contact.contact_id === contactsStore?.sessionData?.contact_id,
      );
    },

    getContactApplicantIdByType() {
      return (searchType: PFilterSearchType) => {
        const contactsStore = useContactsStore();
        if (contactsStore.contactInfo?.applicant_information.applicants) {
          const desiredProfileId =
            searchType === PFilterSearchType.LETTINGS ? ContactProfiles.TENANT : ContactProfiles.BUYER;
          const applicant = contactsStore.contactInfo.applicant_information.applicants.find(
            (item) => item.profile_id === desiredProfileId,
          );
          return applicant?.contact_applicant_id;
        }
        return null;
      };
    },
  },
});
