import { defineStore } from 'pinia';
import { PropertyTypeStylesService } from '~/units/property_type_styles/services';
import type { FeaturedTypeAndStylesComponent, PropertyStyle, PropertyType } from '~/units/property_type_styles/types';

export const usePropertyTypeStyleStore = defineStore('property-type-styles', {
  state: (): {
    propertyTypes: null | PropertyType[];
    propertyStyles: null | PropertyStyle[];
    featuredTypeAndStyles: FeaturedTypeAndStylesComponent[];
  } => {
    return {
      propertyTypes: null,
      propertyStyles: null,

      featuredTypeAndStyles: [
        {
          id: 1,
          property_type_id: 1,
          name: 'Detached',
          icon: 'detached',
          styles: [5, 6, 22, 23, 24, 25, 27, 30, 69, 77],
          isJustType: false,
          size: 30,
        },
        {
          id: 2,
          property_type_id: 1,
          name: 'Semi-Detached',
          icon: 'semi-detached',
          styles: [4, 6, 22, 23, 24, 25, 27, 30, 77],
          isJustType: false,
          size: 48,
        },
        {
          id: 3,
          property_type_id: 1,
          name: 'Terraced',
          icon: 'terraced',
          styles: [2, 3, 6, 23, 24, 25, 27, 30, 69, 77],
          isJustType: false,
          size: 64,
        },
        {
          id: 4,
          property_type_id: 2,
          name: 'Flats',
          icon: 'flat',
          styles: [8, 9, 10, 11, 12, 28, 29],
          isJustType: false,
          size: 36,
        },
        {
          id: 5,
          property_type_id: 3,
          name: 'Bungalow',
          icon: 'bungalow',
          styles: [13, 14, 15, 16],
          isJustType: true,
          size: 48,
        },
        {
          id: 6,
          property_type_id: 6,
          name: 'Land',
          icon: 'land',
          styles: [21, 58, 59, 67],
          isJustType: true,
          size: 48,
        },
        {
          id: 7,
          property_type_id: 4,
          name: 'Park Home',
          icon: 'detached',
          styles: [39, 62, 17],
          isJustType: false,
          size: 30,
        },
      ],
    };
  },
  actions: {
    async fetch() {
      if (!this.propertyTypes && !this.propertyStyles) {
        const propertyTypeStyleService = new PropertyTypeStylesService();

        const [types, styles] = await Promise.all([
          propertyTypeStyleService.fetchTypes(),
          propertyTypeStyleService.fetchStyles(),
        ]);

        this.propertyTypes = types.data;
        this.propertyStyles = styles.data;
      }
    },
  },
  getters: {},
});
