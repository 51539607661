<template>
  <div class="floor-tab">
    <div class="floor-plan-items tw-mx-0 tw-grid tw-grid-cols-12 tw-justify-start tw-gap-x-5">
      <div
        v-for="(floorPlan, index) in data"
        :key="`floorPlan-${index}`"
        class="floor-plan-card tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3"
        @click="showOnLightbox(floorPlan.image, 'single')"
      >
        <figure>
          <library-image :src="floorPlan.image" :isRatioFree="true" :alt="`Floor Plan ${index + 1}`"></library-image>
          <!-- <img :src="floorPlan.image" alt="" /> -->
        </figure>
        <p>Floor Plan {{ index + 1 }}</p>
      </div>
    </div>
    <full-screen-popup
      v-if="fullScreenPopupConfig && fullScreenPopupConfig.item && fullScreenPopupConfig.is_active"
      :item="fullScreenPopupConfig.item"
      :component="component"
      @close="closeLightbox()"
    />
  </div>
</template>

<script lang="ts">
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';
import FullScreenPopup from '~/components/common/FullScreenPopup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { type FullScreenModalType, FullScreenModalTypes } from '~/units/core/types';

export default defineNuxtComponent({
  name: 'FloorPlan',
  components: { FullScreenPopup },

  mixins: [ComponentMixin, FullScreenPopupMixin],

  props: {
    data: {
      required: true,
    },
  },

  computed: {
    fullScreenPopupType(): FullScreenModalType {
      return FullScreenModalTypes.SINGLE;
    },
  },
});
</script>

<style scoped>
.floor-tab {
  padding: 2rem;
}

.floor-tab .floor-plan-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}

.floor-tab .floor-plan-items .floor-plan-card {
  position: relative;
  display: block;
  width: 21%;
  cursor: pointer;
  margin: 1rem 0;
}

.floor-tab .floor-plan-items .floor-plan-card figure {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #000;
  -webkit-transition:
    border-color 0.3s,
    opacity 0.3s ease-in;
  transition:
    border-color 0.3s,
    opacity 0.3s ease-in;
}

.floor-tab .floor-plan-items .floor-plan-card figure img {
  display: block;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.floor-tab .floor-plan-items .floor-plan-card p {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1rem 0 0;
}

.floor-tab .floor-plan-items .floor-plan-card::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: var(--ternary-bg-color);
  font-family: 'lifesycle-pm';
  font-size: 0.75rem;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-40deg) scale(0);
  transform: translate(-50%, -50%) rotate(-40deg) scale(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition:
    transform 0.3s ease-in,
    -webkit-transform 0.3s ease-in;
}

.floor-tab .floor-plan-items .floor-plan-card:hover figure {
  opacity: 0.5;
  border-color: transparent;
}

.floor-tab .floor-plan-items .floor-plan-card:hover::after {
  -webkit-transform: translate(-50%, -50%) rotate(-40deg) scale(1);
  transform: translate(-50%, -50%) rotate(-40deg) scale(1);
}

@media (max-width: 991px) {
  .floor-tab .floor-plan-items .floor-plan-card {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .floor-tab .floor-plan-items .floor-plan-card {
    width: 45%;
  }
}

@media (max-width: 575px) {
  .floor-tab .floor-plan-items .floor-plan-card {
    width: 100%;
  }
}
</style>
