<template>
  <div class="pm-modal-content success-content">
    <h2 :style="'font-size:' + fontSize">{{ text }}</h2>
    <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="135" height="135" rx="67.5" fill="#0055A5" />
      <path
        d="M55.0951 64.3415H48.0989C46.8745 64.3415 46 65.3895 46 66.628V89.4939C46 90.8277 46.8745 91.7805 48.0989 91.7805H55.0951C56.232 91.7805 57.194 90.8277 57.194 89.4939V66.628C57.194 65.3895 56.232 64.3415 55.0951 64.3415ZM51.597 87.9695C50.3727 87.9695 49.4981 87.0168 49.4981 85.6829C49.4981 84.4444 50.3727 83.3963 51.597 83.3963C52.7339 83.3963 53.6959 84.4444 53.6959 85.6829C53.6959 87.0168 52.7339 87.9695 51.597 87.9695ZM79.5821 50.8125C79.5821 43.7622 75.3843 43 73.2854 43C71.4489 43 70.6618 46.811 70.312 48.5259C69.7873 50.622 69.35 52.718 68.0382 54.1471C65.2397 57.2912 63.753 61.1974 60.2549 64.9131C60.08 65.1989 59.9925 65.4848 59.9925 65.7706V86.1593C59.9925 86.7309 60.4298 87.2073 60.9545 87.3026C62.3538 87.3026 64.1903 88.1601 65.5896 88.827C68.3881 90.1608 71.7987 91.7805 75.9965 91.7805H76.2589C80.0194 91.7805 84.4795 91.7805 86.2285 89.0175C87.0156 87.8742 87.1905 86.4451 86.7533 84.7302C88.24 83.1105 88.9396 80.0617 88.24 77.5846C89.7267 75.3933 89.9016 72.2492 89.0271 70.0579C90.0765 68.9146 90.7761 67.1044 90.6887 65.3895C90.6887 62.436 88.4149 59.7683 85.5289 59.7683H76.6087C77.3083 57.1006 79.5821 54.814 79.5821 50.8125Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'Success',
  props: {
    text: {
      required: false,
      type: String,
      default: 'Your transaction is successfully',
    },
    fontSize: {
      required: false,
      type: String,
      default: '1.875rem',
    },
  },
});
</script>
