<template>
  <div
    class="pm-modal pm-modal__scroll"
    style="overflow-x: hidden"
    :class="configModel.isActive ? 'pm-active' : ''"
    :style="customStyles"
  >
    <div
      class="pm-modal-wrapper pm-modal__scroll"
      style="background-color: var(--nc-popup-background-color)"
      :class="customWrapperClass"
    >
      <div class="pm-modal-header">
        <slot name="header">
          <div class="closeBtn n-cursor-pointer tw-flex tw-items-center tw-justify-center" @click="close()">
            <span class="!tw-text-4xl !tw-font-light">&times;</span>
          </div>
        </slot>
      </div>
      <div
        v-show="configModel.isActive"
        class="pm-modal-body tw-overflow-hidden lg:tw-max-h-[80dvh]"
        style="overflow-y: auto; overflow-x: hidden"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapActions } from 'pinia';
import { useCoreStore } from '~/units/core/store';

export type ModalConfig = { isActive: boolean };

export default {
  name: 'Popup',
  props: {
    customWrapperClass: { required: false, type: String, default: '' },
    config: { required: true, type: Object as PropType<ModalConfig> },
    customWidth: { required: false, type: String, default: '750px' },

    customBackgroundColor: { required: false, type: String, default: 'rgba(213, 218, 222, 0.79)' },
  },
  emits: ['close', 'update:config'],

  data() {
    return { scrollPosition: 0 };
  },

  computed: {
    configModel: {
      get() {
        return this.config;
      },
      set(value: ModalConfig) {
        return this.$emit('update:config', value);
      },
    },

    customStyles() {
      return { '--popup-width': this.customWidth, '--popup-background-color': this.customBackgroundColor };
    },
  },

  mounted() {
    if (this.configModel.isActive) {
      this.disableScroll();
    }
  },

  unmounted() {
    this.setAnyModalVisibility(false);
    this.enableScroll();
  },

  watch: {
    'configModel.isActive': {
      handler(newValue: boolean) {
        if (newValue) {
          this.disableScroll();
        } else {
          this.enableScroll();
        }
      },
    },

    config: {
      handler(e) {
        if (e) {
          this.setAnyModalVisibility(e.isActive);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(useCoreStore, ['setAnyModalVisibility']),

    close() {
      this.configModel.isActive = false;
      this.$emit('close');
      this.setAnyModalVisibility(false);
    },

    disableScroll() {
      try {
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${this.scrollPosition}px`;
      } catch (error) {
        console.error('Error in disableScroll:', error);
      }
    },

    enableScroll() {
      try {
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';

        window.scrollTo(0, this.scrollPosition);
      } catch (error) {
        console.error('Error in enableScroll:', error);
      }
    },
  },
};
</script>

<style scoped>
.pm-modal {
  background-color: var(--popup-background-color) !important;
}

.pm-modal-wrapper {
  width: 100%;
}

@media (min-width: 992px) {
  .pm-modal-wrapper {
    width: var(--popup-width);
  }
}

@media (max-width: 991px) {
  .pm-modal {
    background-color: var(--popup-background-color) !important;
  }
}
</style>
