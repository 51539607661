<template>
  <li
    v-if="version === 'v1'"
    :style="styleColors"
    class="n-list-element nc-property-type-element"
    :class="[
      index === activeTypeIndex ? 'nc-property-type-element__active pm-active' : '',
      selectedStylesCount > 0 ? '' : '',
    ]"
    @click="$emit('changeActiveType')"
  >
    <div>
      <lazy-library-text :text="propertyType.name" tag="span"></lazy-library-text>
      <lazy-library-text
        v-if="selectedStylesCount > 0"
        :text="`(${selectedStylesCount})`"
        tag="span"
      ></lazy-library-text>
    </div>
  </li>
  <li
    v-else-if="version === 'v2'"
    :style="styleColors"
    class="n-list-element nc-property-type-element"
    :class="[
      index === activeTypeIndex ? 'nc-property-type-element__active' : '',
      selectedStylesCount > 0 ? 'pm-active' : '',
    ]"
    @click="$emit('changeActiveType')"
  >
    <div>
      <p>{{ propertyType.name }}</p>
      <p v-if="selectedStylesCount > 0">({{ selectedStylesCount }})</p>
    </div>
  </li>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import type { PFilterPropertyTypes } from '~/units/properties/types';
import type { PropertyType } from '~/units/property_type_styles/types';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'PropertyTypeItem',

  mixins: [SubComponentMixin],

  props: {
    activeTypeIndex: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
    propertyType: {
      required: true,
      type: Object as PropType<PropertyType>,
    },
    selectedTypeStyles: {
      required: true,
      type: Array as PropType<PFilterPropertyTypes[]>,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    selectedData() {
      return this.selectedTypeStyles.find((i) => i.property_type_id === this.propertyType.property_type_id);
    },

    selectedStylesCount() {
      if (this.selectedData) {
        return this.selectedData.property_style_ids.length;
      }
      return 0;
    },

    styleColors() {
      if (this.colors) {
        if (this.theme === 'theme2') {
          return {
            '--active-element-background-color': this.colors?.seashell,
            '--active-element-text-color': this.colors['dark-brown'],
            '--element-background-color': 'transparent',
            '--element-text-color': '#222222',
          };
        } else if (this.theme === 'theme3') {
          return {
            '--active-element-background-color': this.colors?.camel,
            '--active-element-text-color': 'white',
            '--element-background-color': this.colors.isabelline,
            '--element-text-color': '#222222',
          };
        }
      }
      return {};
    },
  },
});
</script>

<style scoped>
.nc-property-type-element {
  color: var(--element-text-color);
  background-color: var(--element-background-color);
}

::v-deep(.nc-text) {
  font-weight: 500 !important;
}
</style>
