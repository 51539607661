<template>
  <div class="tw-flex tw-flex-col tw-py-4">
    <h3 class="n_applicant_cards_list--title">Please select the alert you want to update</h3>
    <div v-if="isLoading"><loader /></div>
    <div v-else-if="!isLoading" style="max-height: 600px" class="px-2 tw-overflow-y-auto">
      <applicant-profile-card
        v-for="(applicant, index) in applicants"
        :key="`applicant-profile-card-${index}`"
        :item="applicant"
        :is-visible-update-button="isVisibleUpdateButton"
        @on-selected-new-applicant="(applicantKey) => $emit('onSelectedNewApplicant', applicantKey)"
      />
    </div>
    <div class="tw-mt-2 tw-px-2">
      <nc-button label="Create new alert" @on-click="goRegister()"></nc-button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { PropType } from 'vue';
import { useApplicantsStore } from '~/units/applicants/store';
import Loader from '~/components/common/Loader.vue';
import ApplicantProfileCard from '~/components/common/properties/ApplicantProfileCard.vue';
import { PFilterSearchType } from '~/units/properties/types';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'ApplicantProfiles',
  components: { NcButton, ApplicantProfileCard, Loader },

  props: {
    searchType: {
      required: false,
      type: String as PropType<PFilterSearchType>,
    },

    isVisibleUpdateButton: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async created() {
    await callOnce(async () => {
      const nuxtApp = useNuxtApp();
      const applicantsStore = useApplicantsStore();

      try {
        this.isLoading = true;
        await nuxtApp.runWithContext(async () => {
          await applicantsStore.fetchApplicants();
        });
      } catch (e) {
        console.error('Failed to load applicants:', e);
      } finally {
        this.isLoading = false;
        await nuxtApp.runWithContext(async () => {
          this.$emit('didApplicantsLoad', applicantsStore.applicants);
        });
      }
    });
  },

  computed: {
    ...mapState(useApplicantsStore, ['listApplicantsByType']),

    applicants() {
      return this.listApplicantsByType(this.searchType);
    },
  },

  methods: {
    goRegister() {
      this.redirectTo(ROUTES.REGISTER);
    },
  },
});
</script>
