<template>
  <lazy-library-form v-if="form" :form="form" :component="component" />
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import type { Form } from '~/components/library/Form.vue';

export default defineNuxtComponent({
  name: 'FormContainer',

  mixins: [ComponentMixin],

  data(): {
    form: Form | null;
  } {
    return {
      form: null,
    };
  },

  created() {
    if (this.component && getVariable(this.component, 'main-form')) {
      this.form = JSON.parse(getVariable(this.component, 'main-form') as string);
    }
  },
});
</script>
