<template>
  <div v-if="locationsModel && locationsModel.length > 0" class="tw-my-4 tw-flex tw-flex-wrap" style="gap: 15px">
    <div
      v-for="(location, index) in locationsModel"
      :key="`location-${index}`"
      class="tw-flex tw-items-center tw-justify-around tw-px-6 tw-py-2 tw-text-[var(--white)]"
      :class="boxClasses"
    >
      <lazy-library-text class="tw-mr-4" :text="`${location.location} - ${location.distance} miles`">
      </lazy-library-text>
      <span class="n-cursor-pointer" @click="onRemovedLocation(index)"
        ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.854 12.1465C12.9005 12.193 12.9373 12.2481 12.9625 12.3088C12.9876 12.3695 13.0006 12.4346 13.0006 12.5003C13.0006 12.566 12.9876 12.631 12.9625 12.6917C12.9373 12.7524 12.9005 12.8076 12.854 12.854C12.8076 12.9005 12.7524 12.9373 12.6917 12.9625C12.631 12.9876 12.566 13.0006 12.5003 13.0006C12.4346 13.0006 12.3695 12.9876 12.3088 12.9625C12.2481 12.9373 12.193 12.9005 12.1465 12.854L8.00028 8.70715L3.85403 12.854C3.76021 12.9478 3.63296 13.0006 3.50028 13.0006C3.3676 13.0006 3.24035 12.9478 3.14653 12.854C3.05271 12.7602 3 12.633 3 12.5003C3 12.3676 3.05271 12.2403 3.14653 12.1465L7.2934 8.00028L3.14653 3.85403C3.05271 3.76021 3 3.63296 3 3.50028C3 3.3676 3.05271 3.24035 3.14653 3.14653C3.24035 3.05271 3.3676 3 3.50028 3C3.63296 3 3.76021 3.05271 3.85403 3.14653L8.00028 7.2934L12.1465 3.14653C12.2403 3.05271 12.3676 3 12.5003 3C12.633 3 12.7602 3.05271 12.854 3.14653C12.9478 3.24035 13.0006 3.3676 13.0006 3.50028C13.0006 3.63296 12.9478 3.76021 12.854 3.85403L8.70715 8.00028L12.854 12.1465Z"
            fill="white"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import type { LocationComponent } from '~/units/properties/types';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'LocationBoxes',

  props: {
    locations: {
      required: true,
      type: Array as PropType<LocationComponent[]>,
    },

    isFirstLocationExcluded: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    locationsModel: {
      get(): LocationComponent[] {
        if (this.isFirstLocationExcluded) {
          return this.locations.filter((item: LocationComponent, index) => index !== 0);
        }
        return this.locations as LocationComponent[];
      },
      set(value: LocationComponent) {
        this.$emit('update:locations', value);
      },
    },

    boxClasses() {
      switch (this.theme) {
        case 'theme3':
          return 'tw-bg-[var(--camel)]';
        case 'theme2':
          return 'tw-bg-[#11273e]';
        default:
          return 'tw-bg-[var(--c-primary)]';
      }
    },
  },

  methods: {
    onRemovedLocation(index: number) {
      this.locationsModel = this.locationsModel.filter(
        (item: LocationComponent, locationIndex) => locationIndex !== index,
      );
    },
  },
});
</script>

<style scoped></style>
