<template>
  <div v-if="version === 'v1'" class="filter-tab pm-active pm-tab w-100">
    <span class="bckBtn" @click="$emit('close')"></span>
    <lazy-library-title text="Property Style" class="all-style-title" tag="h3"> </lazy-library-title>
    <lazy-library-text text="Please select style"></lazy-library-text>
    <div class="checkbox-wrapper">
      <div class="select-all">
        <div class="pm-checkbox-group type-2" @click="clickedAllSelection()">
          <input id="all-checkbox" :checked="isAllSelected" disabled type="checkbox" class="pm-checkbox" />
          <label for="all-checkbox"><lazy-library-text text="All"></lazy-library-text></label>
        </div>
        <span class="clear text-uppercase" @click="setAllRemoved()">
          <lazy-library-text text="Clear" type="bold"></lazy-library-text>
        </span>
      </div>
      <property-style-item
        v-for="(style, index) in showingPropertyStyles"
        :key="`property_style-${index}`"
        v-model:selected-type-styles="selectedTypeStylesModel"
        :index="index"
        :property-style="style"
        :update-property-type-style="updatePropertyTypeStyle"
      />
    </div>
  </div>

  <div v-if="version === 'v2'" class="filter-tab pm-active pm-tab w-100">
    <span class="bckBtn" @click="$emit('close')"></span>
    <h4>Property Style</h4>
    <p>Please select style</p>
    <div class="checkbox-wrapper">
      <div class="select-all">
        <div class="pm-checkbox-group type-2" @click="clickedAllSelection()">
          <input id="all-checkbox" :checked="isAllSelected" disabled type="checkbox" class="pm-checkbox" />
          <label for="all-checkbox"><lazy-library-text text="All"></lazy-library-text></label>
        </div>
        <span class="clear text-uppercase" @click="setAllRemoved()">
          <p>Clear</p>
        </span>
      </div>
      <property-style-item
        v-for="(style, index) in showingPropertyStyles"
        :key="`property_style-${index}`"
        v-model:selected-type-styles="selectedTypeStylesModel"
        :index="index"
        :property-style="style"
        :update-property-type-style="updatePropertyTypeStyle"
        version="v2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import PropertyStyleItem from '~/components/common/properties/PropertyStyleItem.vue';
import type { PFilterPropertyTypes } from '~/units/properties/types';
import type { PropertyStyle, PropertyType } from '~/units/property_type_styles/types';

export default defineNuxtComponent({
  name: 'PropertyTypeStyleList',

  components: {
    PropertyStyleItem,
  },
  props: {
    showingPropertyStyles: {
      required: true,
      type: Array as PropType<PropertyStyle[]>,
    },
    selectedTypeStyles: {
      required: true,
      type: Array as PropType<PFilterPropertyTypes[]>,
    },
    activePropertyType: {
      required: true,
      type: Object as PropType<PropertyType>,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },
  computed: {
    selectedTypeStylesModel: {
      get() {
        return this.selectedTypeStyles;
      },
      set(value: PFilterPropertyTypes[]) {
        this.$emit('update:selectedTypeStyles', value);
      },
    },

    isAllSelected() {
      if (this.activePropertyType) {
        // find selected property type index in filters
        if (this.selectedPropertyTypeIndex >= 0 && this.selectedTypeStylesModel) {
          // if showing style length equals to selected property type's styles length
          return (
            this.selectedTypeStylesModel[this.selectedPropertyTypeIndex].property_style_ids.length ===
            this.showingPropertyStyles.length
          );
        }
      }
      return false;
    },

    selectedPropertyTypeIndex() {
      if (this.selectedTypeStylesModel) {
        return this.selectedTypeStylesModel.findIndex(
          (type) => type.property_type_id === this.activePropertyType.property_type_id,
        );
      }
      return 0;
    },
  },
  methods: {
    updatePropertyTypeStyle(data: {
      type: 'UPDATE_PROPERTY_TYPE' | 'ADD_NEW_PROPERTY_TYPE' | 'REMOVE_PROPERTY_TYPE';
      payload?: PFilterPropertyTypes;
      index?: number;
    }) {
      if (data.type === 'UPDATE_PROPERTY_TYPE' && data.payload) {
        this.selectedTypeStylesModel = this.selectedTypeStylesModel.map((item, itemIndex) => {
          if (data.index === itemIndex && data.payload) {
            return data.payload;
          }
          return item;
        });
      } else if (data.type === 'ADD_NEW_PROPERTY_TYPE' && data.payload) {
        this.selectedTypeStylesModel = [...this.selectedTypeStylesModel, { ...data.payload }];
      } else if (data.type === 'REMOVE_PROPERTY_TYPE') {
        this.selectedTypeStylesModel = this.selectedTypeStylesModel.filter(
          (item, itemIndex) => itemIndex !== data.index,
        );
      }
    },

    clickedAllSelection() {
      if (this.isAllSelected) {
        this.setAllRemoved();
      } else {
        this.setAllSelected();
      }
    },

    setAllSelected() {
      const allSelectedStyles = this.showingPropertyStyles.map((style) => style.property_style_id);

      if (this.selectedPropertyTypeIndex >= 0) {
        this.updatePropertyTypeStyle({
          type: 'UPDATE_PROPERTY_TYPE',
          payload: {
            property_style_ids: allSelectedStyles,
            property_type_id: this.activePropertyType.property_type_id,
          },
          index: this.selectedPropertyTypeIndex,
        });
      } else {
        this.updatePropertyTypeStyle({
          type: 'ADD_NEW_PROPERTY_TYPE',
          payload: {
            property_type_id: this.activePropertyType.property_type_id,
            property_style_ids: allSelectedStyles,
          },
        });
      }
    },

    setAllRemoved() {
      // selected property type's index
      if (this.selectedPropertyTypeIndex >= 0) {
        this.updatePropertyTypeStyle({
          type: 'REMOVE_PROPERTY_TYPE',
          index: this.selectedPropertyTypeIndex,
        });
      }
    },
  },
});
</script>

<style scoped></style>
