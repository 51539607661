import { defineStore } from 'pinia';
import { RequestMethod, RequestName, RequestProxy, useCaller } from '~/hydration/bridges';
import { useCoreStore } from '~/units/core/store';

export const useAuthStore = defineStore('auth', {
  state: (): {
    isLoggedIn: boolean;
    loggedInUser: null | {
      contact_id: number;
    };
  } => {
    return {
      isLoggedIn: false,
      loggedInUser: null,
    };
  },
  actions: {
    authenticate(user: { contact_id: number | null }) {
      this.loggedInUser = { ...user };
      this.isLoggedIn = true;
    },

    async sendMagicLinkRequest(payload: { email: string }) {
      const coreStore = useCoreStore();
      const recaptchaClientToken = await coreStore.getRecaptchaInstantToken();

      return await useCaller(
        RequestProxy.NEURON_API,
        RequestName.SEND_MAGIC_LINK_REQUEST,
        '/contacts/oauth/magic-links',
        RequestMethod.POST,
        {},
        {
          ...payload,
          recaptcha_token: recaptchaClientToken.token,
        },
      );
    },
  },
});
