<template>
  <div class="tw-flex tw-flex-col tw-space-y-5 tw-py-8" id="login">
    <slot name="title"> </slot>
    <slot name="explanation-text">
      <h4>
        This is a pre-market property. You need to create an account and register to our property alerts in order to
        view it.
      </h4>
    </slot>
    <slot v-if="isRegisterButtonVisible" name="register-button">
      <div class="tw-flex tw-w-1/6">
        <library-button label="Register" @did-click="goRegister()" />
      </div>
    </slot>
    <slot v-if="!isRequestSent" name="email-input-explanation-text">
      <div>
        If you think you are already registered and can't see this property enter your email address here and we will
        send you a magic link:
      </div>
    </slot>
    <div
      v-if="!isRequestSent"
      class="tw-flex tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-items-center lg:tw-space-x-2 lg:tw-space-y-0"
    >
      <div>
        <nc-input
          v-model="email"
          :is-required="true"
          label="Your Email"
          type="email"
          name="email"
          placeholder="john.doe@mail.com"
          @on-keyup-enter="didSend()"
        />
        <span class="tw-text-xs">
          This site is protected by reCAPTCHA and the Google
          <a style="padding: 0 !important" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a>
          and
          <a style="padding: 0 !important" href="https://policies.google.com/terms" target="_blank">Terms of Service</a>
          apply.
        </span>
      </div>
      <div class="tw-pt-1">
        <library-button label="Send me the link" @did-click="didSend()" :is-disabled="isLoading" />
      </div>
    </div>
    <small v-else-if="isRequestSent"> <strong>Email:</strong> {{ emailSentRequest }} </small>
    <small v-if="isRequestSent" style="color: var(--success_message_color)">
      <slot name="success-message">
        Thank you for submitting your request. If you have registered with us, you will receive an email. If you do not
        receive the email, please register to view this and other exclusive properties.
      </slot>
    </small>
  </div>
</template>

<script lang="ts">
import LibraryButton from '~/components/common/Button.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import { ROUTES } from '~/constants/ui.constants';
import { EmailValidator } from '~/server/core/validators/email.validator';
import { mapActions } from 'pinia';
import { useAuthStore } from '~/units/auth/store';
import { useContactsStore } from '~/units/contacts/store';

export default defineNuxtComponent({
  name: 'LibraryLogin',
  components: { NcInput, LibraryButton },

  props: {
    isRegisterButtonVisible: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data(): {
    email: string;
  } {
    return {
      email: '',

      isRequestSent: false,
      isLoading: false,
      emailSentRequest: '',
    };
  },

  setup() {
    const contactsStore = useContactsStore();
    if (contactsStore.isLogged) {
      navigateTo(ROUTES.PROPERTIES, {
        external: true,
      });
    }
  },

  methods: {
    ...mapActions(useAuthStore, ['sendMagicLinkRequest']),

    goRegister() {
      this.redirectTo(ROUTES.REGISTER);
    },

    async didSend() {
      try {
        const email = new EmailValidator().validate(this.email);
        // email is valid - go for it

        this.isLoading = true;
        try {
          await this.sendMagicLinkRequest({
            email,
            request_page_url: window.location.href,
          });
        } catch (e) {
          // clears error with try/catch
        }
        this.isLoading = false;
        this.isRequestSent = true;
        this.emailSentRequest = this.email;
        this.email = '';
      } catch (e) {
        this.warningMessage(e.message);
      }
    },
  },
});
</script>
