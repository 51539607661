<template>
  <template v-if="version === 'v1'">
    <div class="col-12 tw-mb-2">
      <lazy-library-text type="input-label" class="no-uppercase" text="Property Type"> </lazy-library-text>
    </div>
    <div
      v-if="filtersModel.property_types"
      :class="
        isWrappingEnabled
          ? 'tw-grid tw-grid-cols-12'
          : 'tw-gap-x-3 tw-gap-y-3 max-md:tw-grid max-md:tw-grid-cols-12 md:tw-flex md:tw-flex-row md:tw-gap-y-0'
      "
      :style="isWrappingEnabled ? 'gap: 15px' : ''"
    >
      <featured-property-type-and-style-box
        v-for="(typeAndStyle, index) in featuredTypeAndStyles"
        :key="`typeAndStyle-${index}`"
        v-model:selected-type-styles="filtersModel.property_types"
        :class="isWrappingEnabled ? 'tw-col-span-6 md:tw-col-span-4' : 'max-md:tw-col-span-6'"
        class="tw-bg-white"
        :style="isWrappingEnabled ? 'height: 125px;' : ''"
        :item="typeAndStyle"
        :filters="filters"
      />
      <div
        v-if="isWrappingEnabled"
        class="tw-col-span-6 tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-around tw-bg-white tw-py-4 md:tw-col-span-4"
        :class="[isOtherOptionSelected ? 'nc-type-style-box-active' : '', boxContainerClass]"
        style="
          border-color: var(--c-border);
          border-radius: var(--rounded-input);
          border-style: solid;
          border-width: 1px;
        "
        :style="isWrappingEnabled ? 'height: 125px;' : ''"
        @click="toggle()"
      >
        <span>
          <span :class="`nc-icon-more-circle`" :style="`font-size: 30px`"></span>
        </span>
        <lazy-library-text text="Other/Advanced"></lazy-library-text>
      </div>
    </div>

    <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
      <template #content>
        <all-property-types-styles
          v-model:selected-type-styles="filtersModel.property_types"
          @close="allStylesConfig.isActive = false"
        />
      </template>
    </popup>
  </template>

  <template v-if="version === 'v2'">
    <div class="tw-grid-cols-12 tw-pb-5">
      <p>Property Type</p>
    </div>
    <div
      v-if="filtersModel.property_types"
      :class="
        isWrappingEnabled
          ? 'tw-grid tw-grid-cols-12'
          : 'tw-gap-x-3 tw-gap-y-3 max-md:tw-grid max-md:tw-grid-cols-12 md:tw-flex md:tw-flex-row md:tw-gap-y-0'
      "
      :style="isWrappingEnabled ? 'gap: 15px' : ''"
    >
      <featured-property-type-and-style-box
        v-for="(typeAndStyle, index) in featuredTypeAndStyles"
        :key="`typeAndStyle-${index}`"
        v-model:selected-type-styles="filtersModel.property_types"
        :class="isWrappingEnabled ? 'tw-col-span-12 lg:tw-col-span-6 2xl:tw-col-span-4' : 'max-md:tw-col-span-6'"
        class="input-group custom-col-span2 property-type"
        :style="isWrappingEnabled ? 'height: 125px;' : ''"
        :item="typeAndStyle"
        :filters="filters"
        version="v2"
      />
      <div
        v-if="isWrappingEnabled"
        class="advanced-box tw-col-span-6 tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-around tw-py-4 2xl:tw-col-span-4"
        :class="[isOtherOptionSelected ? 'nc-type-style-box-active' : '', boxContainerClass]"
        style="
          border-color: var(--c-border);
          border-radius: var(--rounded-input);
          border-style: solid;
          border-width: 1px;
        "
        :style="[isWrappingEnabled ? 'height: 125px;' : '']"
        @click="toggle()"
      >
        <span>
          <span :class="`nc-icon-more-circle`" :style="`font-size: 30px !important`"></span>
        </span>
        <p>Other/Advanced</p>
      </div>
    </div>

    <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
      <template #content>
        <all-property-types-styles
          v-model:selected-type-styles="filtersModel.property_types"
          @close="allStylesConfig.isActive = false"
        />
      </template>
    </popup>
  </template>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import type { PropType } from 'vue';
import { usePropertyTypeStyleStore } from '~/units/property_type_styles/store';
import type { PropertiesFilter } from '~/units/properties/types';
import FeaturedPropertyTypeAndStyleBox from '~/components/common/properties/FeaturedPropertyTypeAndStyleBox.vue';
import type { ModalConfig } from '~/components/common/popup/Popup.vue';
import Popup from '~/components/common/popup/Popup.vue';
import AllPropertyTypesStyles from '~/components/common/properties/AllPropertyTypeStyle.vue';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'FeaturedPropertyTypeAndStyles',
  components: {
    AllPropertyTypesStyles,
    Popup,
    FeaturedPropertyTypeAndStyleBox,
  },

  props: {
    filters: {
      required: true,
      type: Object as PropType<PropertiesFilter>,
    },

    isWrappingEnabled: {
      required: false,
      type: Boolean,
      default: false,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  data(): {
    allStylesConfig: ModalConfig;
  } {
    return {
      allStylesConfig: {
        isActive: false,
      },
    };
  },

  methods: {
    ...mapActions(usePropertyTypeStyleStore, { fetchTypeAndStyles: 'fetch' }),

    toggle() {
      this.allStylesConfig.isActive = true;
    },
  },

  computed: {
    ...mapState(usePropertyTypeStyleStore, ['featuredTypeAndStyles']),
    ...mapState(useWebsiteStore, ['theme']),

    boxContainerClass() {
      switch (this.theme) {
        case 'theme3':
          return 'tw-border tw-border-[#DDDDDD]';
        case 'theme2':
          return '';
        default:
          return '';
      }
    },

    filtersModel: {
      get() {
        return this.filters;
      },
      set(value: string) {
        this.$emit('update:filters', value);
      },
    },

    isOtherOptionSelected() {
      let featuredStyleIds = [];
      const featuredTypeIds = [];
      this.featuredTypeAndStyles.forEach((type) => {
        featuredTypeIds.push(type.property_type_id);
        if (type.styles) {
          featuredStyleIds = [...featuredStyleIds, ...type.styles];
        }
      });

      let selectedStyleIds = [];
      const selectedTypeIds = [];
      this.filtersModel.property_types.forEach((type) => {
        selectedTypeIds.push(type.property_type_id);
        if (type.property_style_ids) {
          selectedStyleIds = [...selectedStyleIds, ...type.property_style_ids];
        }
      });
      const isTypeSelected = selectedTypeIds.some((i) => !featuredTypeIds.includes(i));
      if (isTypeSelected) {
        return isTypeSelected;
      }
      const isStyleSelected = selectedStyleIds.some((i) => !featuredStyleIds.includes(i));
      if (isStyleSelected) {
        return isStyleSelected;
      }
      return false;
    },
  },

  created() {
    this.fetchTypeAndStyles();
  },
});
</script>

<style scoped></style>
