<template>
  <div>
    <div>
      <span
        v-if="question.question_key === 'do_you_need_to_sell_the_property_you_currently_live_in'"
        class="nc-divider"
      ></span>
      <nc-select
        v-model="applicantAnswers"
        :label="question.question"
        :options="question.contact_question_options"
        label-field="label"
        :is-required="question.is_required"
        value-field="contact_question_option_id"
      />
    </div>
  </div>
</template>

<script lang="ts">
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';

export default defineNuxtComponent({
  name: 'RegisterQuestion',
  components: { NcSelect },

  props: {
    question: {
      required: true,
      type: Object,
    },

    applicantAnswersModel: {
      required: true,
    },
  },

  mixins: [SubComponentMixin],

  computed: {
    applicantAnswers: {
      get() {
        return this.applicantAnswersModel[this.question.contact_question_id];
      },
      set(value) {
        this.$emit('update:applicantAnswersModel', {
          ...this.applicantAnswersModel,
          [this.question.contact_question_id]: value,
        });
      },
    },
  },

  methods: {},
});
</script>

<style scoped></style>
