import type { PropType } from 'vue';
import { ViewTypes } from '~/constants/ui.constants';
import { getFromState } from '~/composables';
import { currencyFormat } from '~/helpers';

import { PropertiesRepo, PropertyItemComponent } from '~/units/properties/types';
import { mapProperty } from '~/units/properties/store';
import { Component } from '~/types';

export const PropertiesMixin = defineNuxtComponent({
  props: {
    component: {
      required: true,
      type: Object as PropType<Component>,
    },
  },

  data() {
    return {
      ViewTypes,
    };
  },

  computed: {
    properties(): PropertyItemComponent[] {
      const propertiesRepo = <PropertiesRepo>getFromState('propertiesRepo');
      if (propertiesRepo && propertiesRepo[0]) {
        const { data: properties } = propertiesRepo[0];

        if (properties) {
          return properties.map((item, index) => mapProperty(item, index));
        }
      }
      return [];
    },
  },

  methods: {
    convert(value: number): string {
      return currencyFormat(value);
    },

    cutText(value: string): string {
      if (value) {
        return `${value.trim().slice(0, 120)}...`;
      }
      return '';
    },
  },
});
