<template>
  <div></div>
</template>

<script lang="ts">
import { useApplicantsStore } from '~/units/applicants/store';
import { ContactProfiles } from '~/constants/contacts';
import { PFilterSearchType } from '~/units/properties/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import type { Component } from '~/types';

export default {
  name: 'PropertiesEntry',

  mixins: [ComponentMixin],

  setup({ component }: { component: Component }) {
    const applicantStore = useApplicantsStore();

    const searchType = getVariable(component, 'company_default_search_type-text') as PFilterSearchType;

    const route = useRoute();

    const applicant = applicantStore.getDesiredApplicant(searchType, true);

    if (applicant && applicant.applicant_key) {
      if (applicant.profile_id === ContactProfiles.BUYER) {
        navigateTo(
          {
            path: '/sales/property-for-sale',
            query: route.query,
          },
          {
            redirectCode: 301,
          },
        );
      } else if (applicant.profile_id === ContactProfiles.TENANT) {
        navigateTo(
          {
            path: '/lettings/property-to-rent',
            query: route.query,
          },
          {
            redirectCode: 301,
          },
        );
      }
    } else if (searchType) {
      if (searchType === PFilterSearchType.SALES) {
        navigateTo(
          {
            path: '/sales/property-for-sale',
            query: route.query,
          },
          {
            redirectCode: 301,
          },
        );
      } else if (searchType === PFilterSearchType.LETTINGS) {
        navigateTo(
          {
            path: '/lettings/property-to-rent',
            query: route.query,
          },
          {
            redirectCode: 301,
          },
        );
      }
    }
  },

  computed: {},
};
</script>
