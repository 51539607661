import { defineStore } from 'pinia';
import { LeadsServices } from '~/units/leads/services/leads.services';
import { useContactsStore } from '~/units/contacts/store';

export const useLeadsStore = defineStore('leads', {
  state: () => {
    return {};
  },
  actions: {
    async sendLead(body: any) {
      const contactsStore = useContactsStore();
      const { data } = await new LeadsServices().sendLead(body);
      await contactsStore.updateSession(null, data.contact.contact_id);
      return true;
    },
  },
  getters: {},
});
