<template>
  <div v-if="variants.v1 === variant">
    <lazy-library-container style="margin-top: 100px; padding-top: 100px; position: sticky; display: block">
      <div
        v-if="(componentModes.UPDATE === mode && applicantUpdateView) || componentModes.CREATE === mode"
        class="page-padding"
      >
        <div class="container-large">
          <div class="row g-0 mb-5">
            <lazy-library-title tag="h1" :text="mainTitle"></lazy-library-title>
            <lazy-library-text :text="introText" class="tw-mt-5"> </lazy-library-text>
          </div>
          <div class="tw-visible lg:tw-hidden">
            <div class="tw-grid tw-grid-cols-12 tw-gap-x-3">
              <div
                v-for="(tab, index) in tabs"
                :key="`tab-${index}`"
                class="nc-tab nc-tab tw-col-span-4 tw-flex tw-h-[100px] tw-flex-col tw-items-center tw-justify-between tw-border tw-pb-3 tw-pt-4 tw-text-center"
                :class="activeTab === index ? 'nc-tab-active' : ''"
                @click="changeTab(index)"
              >
                <i class="tw-text-2xl tw-text-black" :class="`nc-icon-${tab.icon}`"></i>
                <lazy-library-title tag="h6" :text="tab.title"></lazy-library-title>
              </div>
            </div>
          </div>
          <div class="tw-mt-0 tw-grid tw-grid-cols-12 tw-gap-x-5 md:tw-mt-5">
            <div
              class="tw-col-span-12 lg:tw-col-span-4"
              :class="activeTab === 0 ? 'tw-flex tw-flex-col' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
            >
              <register-contact-area ref="contactArea" :component="component" :mode="mode" />
            </div>
            <div
              class="tw-col-span-12 lg:tw-col-span-4"
              :class="activeTab === 1 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
            >
              <register-property-criteria-area
                ref="propertyCriteriaArea"
                :component="component"
                :search-type="searchType"
                :mode="mode"
              />
            </div>
            <div
              class="tw-col-span-12 lg:tw-col-span-4"
              :class="activeTab === 2 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
            >
              <register-questions-area
                ref="questionsArea"
                :mode="mode"
                :search-type="searchType"
                :component="component"
              />
            </div>
          </div>
          <div
            class="row g-0 tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-w-dvw tw-bg-white tw-px-4 tw-pb-4 tw-pt-2 tw-shadow-2xl tw-shadow-black md:tw-static md:tw-bottom-auto md:tw-w-auto md:tw-bg-transparent md:tw-pt-0 md:tw-shadow-none"
          >
            <div class="col-12 tw-invisible md:tw-visible">
              <div class="nc-divider"></div>
            </div>
            <div class="col-12 tw-flex tw-justify-end">
              <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
                <lazy-library-text type="bold" class="tw-cursor-pointer" text="Cancel" @click="onCancelled()">
                </lazy-library-text>
                <div class="tw-w-[140px]">
                  <nc-button :label="submitButtonLabel" :is-disabled="isLoading" @on-click="onSave()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </lazy-library-container>
    <section class="section-product-content" style=""></section>

    <popup :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </div>

  <section
    v-else-if="variants.v2 === variant"
    class="register tw-mt-32"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      v-if="(componentModes.UPDATE === mode && applicantUpdateView) || componentModes.CREATE === mode"
      class="page-padding"
    >
      <div class="container-large">
        <div class="register_intro" :class="{ 'tw-text-center': vars.textCenterBool }">
          <h2 v-if="vars.titleText">{{ vars.titleText }}</h2>
          <div
            v-else-if="vars.titleHtml"
            class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-start lg:tw-gap-4"
            v-html="vars.titleHtml"
          ></div>
          <p v-if="!vars.customDescriptionText">{{ introText }}</p>
          <p v-else>{{ vars.customDescriptionText }}</p>
        </div>
        <div class="tw-visible lg:tw-hidden">
          <div class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-x-3">
            <div
              v-for="(tab, index) in tabs"
              :key="`tab-${index}`"
              class="nc-tab nc-tab tw-relative tw-col-span-4 tw-flex tw-h-[100px] tw-flex-col tw-items-center tw-justify-between tw-border tw-pb-3 tw-pt-4 tw-text-center"
              :class="activeTab === index ? 'nc-tab-active nc-tab-active-v2' : ''"
              @click="changeTab(index)"
            >
              <i class="tw-text-2xl tw-text-black" :class="`nc-icon-${tab.icon}`"></i>
              <p class="tw-text-sm">{{ tab.title }}</p>
            </div>
          </div>
        </div>
        <div class="tw-mt-0 tw-grid tw-grid-cols-12 tw-gap-x-5 md:tw-mt-5">
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 0 ? 'tw-flex tw-flex-col' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-contact-area ref="contactArea" :component="component" :mode="mode" :title="tabs[0].title" />
          </div>
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 1 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-property-criteria-area
              ref="propertyCriteriaArea"
              :component="component"
              :search-type="searchType"
              :mode="mode"
              version="v2"
              :title="tabs[1].title"
            />
          </div>
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 2 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-questions-area
              ref="questionsArea"
              :mode="mode"
              :search-type="searchType"
              :component="component"
              :title="tabs[2].title"
            />
          </div>
        </div>
        <div
          class="row g-0 tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-w-dvw tw-bg-white tw-px-4 tw-pb-4 tw-pt-2 tw-shadow-2xl tw-shadow-black md:tw-static md:tw-bottom-auto md:tw-w-auto md:tw-bg-transparent md:tw-pt-0 md:tw-shadow-none"
        >
          <div class="col-12 tw-invisible md:tw-visible">
            <div class="nc-divider"></div>
          </div>
          <div class="col-12 tw-flex tw-justify-end">
            <div class="register-buttons tw-flex tw-flex-row tw-items-center tw-gap-x-3">
              <lazy-library-text type="bold" class="tw-cursor-pointer" text="Cancel" @click="onCancelled()">
              </lazy-library-text>
              <div class="tw-w-[140px]">
                <nc-button :label="submitButtonLabel" :is-disabled="isLoading" @on-click="onSave()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section-product-content" style=""></section>

    <popup :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </section>

  <section
    v-else-if="variants.v3 === variant"
    class="register n-section-primary tw-mt-20"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      v-if="(componentModes.UPDATE === mode && applicantUpdateView) || componentModes.CREATE === mode"
      class="page-padding"
    >
      <div class="container-large">
        <div
          class="register_intro tw-hidden tw-flex-col tw-items-start tw-justify-center tw-text-start lg:tw-flex"
          :class="{ 'tw-text-center': vars.textCenterBool }"
        >
          <h1 v-if="vars.titleText" class="n-title-primary tw-mb-10 !tw-text-7xl xl:tw-mb-14">{{ vars.titleText }}</h1>
          <div
            v-else-if="vars.titleHtml"
            class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-start lg:tw-gap-4"
            v-html="vars.titleHtml"
          ></div>
          <p v-if="!vars.customDescriptionText">{{ introText }}</p>
          <p v-else>{{ vars.customDescriptionText }}</p>
        </div>
        <div class="tw-visible lg:tw-hidden">
          <div class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-x-3">
            <div
              v-for="(tab, index) in tabs"
              :key="`tab-${index}`"
              class="nc-tab nc-tab tw-relative tw-col-span-4 tw-flex tw-h-[100px] tw-flex-col tw-items-center tw-justify-between tw-border tw-pb-3 tw-pt-4 tw-text-center"
              :class="activeTab === index ? 'nc-tab-active' : ''"
              @click="changeTab(index)"
            >
              <i class="tw-text-2xl tw-text-black" :class="`nc-icon-${tab.icon}`"></i>
              <p class="tw-text-sm">{{ tab.title }}</p>
            </div>
          </div>
        </div>
        <div
          class="tw-mt-0 tw-grid tw-max-w-[600px] tw-grid-cols-12 tw-gap-x-5 md:tw-mt-5 lg:tw-mt-10 lg:tw-max-w-none xl:tw-mt-16"
        >
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 0 ? 'tw-flex tw-flex-col' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-contact-area ref="contactArea" :component="component" :mode="mode" :title="tabs[0].title" />
          </div>
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 1 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-property-criteria-area
              ref="propertyCriteriaArea"
              :component="component"
              :search-type="searchType"
              :mode="mode"
              version="v2"
              :title="tabs[1].title"
            />
          </div>
          <div
            class="tw-col-span-12 lg:tw-col-span-4"
            :class="activeTab === 2 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
          >
            <register-questions-area
              ref="questionsArea"
              :mode="mode"
              :search-type="searchType"
              :component="component"
              :title="tabs[2].title"
            />
          </div>
        </div>
        <div
          class="row g-0 tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-w-dvw tw-bg-white tw-px-4 tw-pb-4 tw-pt-2 tw-shadow-2xl tw-shadow-black md:tw-static md:tw-bottom-auto md:tw-w-auto md:tw-bg-transparent md:tw-pt-0 md:tw-shadow-none"
        >
          <div class="col-12 tw-invisible md:tw-visible">
            <div class="nc-divider"></div>
          </div>
          <div class="col-12 tw-flex tw-justify-end">
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
              <lazy-library-text type="bold" class="tw-cursor-pointer" text="Cancel" @click="onCancelled()">
              </lazy-library-text>
              <div class="tw-w-[140px]">
                <nc-button :label="submitButtonLabel" :is-disabled="isLoading" @on-click="onSave()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section-product-content" style=""></section>

    <popup :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </section>

  <section
    v-else-if="variants.v4 === variant"
    class="register n-section-primary tw-mt-20"
    :style="`background-color: var(--c-${vars.backgroundColor}) !important`"
  >
    <div v-if="(componentModes.UPDATE === mode && applicantUpdateView) || componentModes.CREATE === mode">
      <div
        class="register_intro tw-hidden tw-flex-col tw-items-start tw-justify-center tw-text-start lg:tw-flex"
        :class="{ 'tw-text-center': vars.textCenterBool }"
      >
        <h1 v-if="vars.titleText" class="n-title-primary tw-mb-10" :style="`color: var(--c-${vars.titleColor})`">
          {{ vars.titleText }}
        </h1>
        <div
          v-else-if="vars.titleHtml"
          class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-start lg:tw-gap-4"
          v-html="vars.titleHtml"
        ></div>
        <p v-if="!vars.customDescriptionText">{{ introText }}</p>
        <p v-else>{{ vars.customDescriptionText }}</p>
      </div>
      <div class="tw-visible lg:tw-hidden">
        <div class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-x-3">
          <div
            v-for="(tab, index) in tabs"
            :key="`tab-${index}`"
            class="nc-tab nc-tab tw-relative tw-col-span-4 tw-flex tw-h-[100px] tw-flex-col tw-items-center tw-justify-between tw-border tw-pb-3 tw-pt-4 tw-text-center"
            :class="activeTab === index ? 'nc-tab-active' : ''"
            @click="changeTab(index)"
          >
            <i class="tw-text-2xl tw-text-black" :class="`nc-icon-${tab.icon}`"></i>
            <p>{{ tab.title }}</p>
          </div>
        </div>
      </div>
      <div
        class="tw-mt-0 tw-grid tw-max-w-[600px] tw-grid-cols-12 tw-gap-x-5 md:tw-mt-5 lg:tw-mt-10 lg:tw-max-w-none xl:tw-mt-16"
      >
        <div
          class="tw-col-span-12 lg:tw-col-span-4"
          :class="activeTab === 0 ? 'tw-flex tw-flex-col' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
        >
          <register-contact-area ref="contactArea" :component="component" :mode="mode" :title="tabs[0].title" />
        </div>
        <div
          class="tw-col-span-12 lg:tw-col-span-4"
          :class="activeTab === 1 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
        >
          <register-property-criteria-area
            ref="propertyCriteriaArea"
            :component="component"
            :search-type="searchType"
            :mode="mode"
            version="v2"
            :title="tabs[1].title"
          />
        </div>
        <div
          class="tw-col-span-12 lg:tw-col-span-4"
          :class="activeTab === 2 ? '' : 'tw-hidden lg:tw-flex lg:tw-flex-col'"
        >
          <register-questions-area
            ref="questionsArea"
            :mode="mode"
            :search-type="searchType"
            :component="component"
            :title="tabs[2].title"
          />
        </div>
      </div>
      <div>
        <button
          v-if="activeTab < 2"
          class="n-primary !tw-w-full !tw-px-6 lg:!tw-hidden"
          @click="(changeTab(activeTab + 1), scrollToTop())"
        >
          NEXT STEP
        </button>

        <div v-else class="register-btns">
          <lazy-library-text type="bold" class="tw-cursor-pointer" text="CANCEL" @click="onCancelled()">
          </lazy-library-text>
          <nc-button class="" :label="submitButtonLabel" :is-disabled="isLoading" @on-click="onSave()" />
        </div>

        <div class="register-btns !tw-hidden tw-border-t-2 tw-pt-5 lg:!tw-flex" style="border-color: var(--c-border)">
          <lazy-library-text type="bold" class="tw-cursor-pointer" text="CANCEL" @click="onCancelled()">
          </lazy-library-text>
          <nc-button class="" :label="submitButtonLabel" :is-disabled="isLoading" @on-click="onSave()" />
        </div>
      </div>
    </div>

    <popup :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import { PFilterSearchType } from '~/units/properties/types';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import RegisterContactArea from '~/components/common/register/RegisterContactArea.vue';
import RegisterPropertyCriteriaArea from '~/components/common/register/RegisterPropertyCriteriaArea.vue';
import RegisterQuestionsArea from '~/components/common/register/RegisterQuestionsArea.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMode } from '~/units/core/types';
import { useApplicantsStore } from '~/units/applicants/store';
import { deepClone } from '~/units/core/providers';
import { ContactProfiles } from '~/constants/contacts';
import { ContactSourcesConstants } from '~/units/contacts/constants/contactSources.constants';
import { useContactsStore } from '~/units/contacts/store';
import Popup from '~/components/common/popup/Popup.vue';
import Success from '~/components/common/popup/Success.vue';
import type { Component } from '~/types';
import { useSettingsStore } from '~/units/settings/store';

export default defineNuxtComponent({
  name: 'Register',
  components: {
    Success,
    Popup,
    NcButton,
    RegisterQuestionsArea,
    RegisterPropertyCriteriaArea,
    RegisterContactArea,
    MultipleInputGroup,
    Locations,
  },

  mixins: [ComponentMixin],

  data() {
    return {
      successModalConfig: {
        isActive: false,
      },
      isLoading: false,

      tabs: [
        {
          id: 'contact',
          title: 'Contact',
          icon: 'id-card',
        },
        {
          id: 'property-reference',
          title: 'Property Type',
          icon: 'home',
        },
        {
          id: 'questions',
          title: 'Circumstances',
          icon: 'question-circle',
        },
      ],

      activeTab: 0,

      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
        v4: 'v4',
      },
    };
  },

  computed: {
    ...mapState(useApplicantsStore, ['applicantUpdateView']),

    mainTitle() {
      if (this.mode === ComponentMode.CREATE) {
        return 'How our Heads Up Property Alerts help you';
      } else if (this.mode === ComponentMode.UPDATE) {
        return 'Update your Heads Up Property Alert';
      }
      return '';
    },

    introText() {
      const settingsStore = useSettingsStore();
      if (this.mode === ComponentMode.UPDATE) {
        return settingsStore.updateRegistrationText;
      }
      return settingsStore.registrationText;
    },

    searchType(): PFilterSearchType {
      if (this.applicantUpdateView && this.mode === ComponentMode.UPDATE) {
        return this.applicantUpdateView.profile_id === ContactProfiles.BUYER
          ? PFilterSearchType.SALES
          : PFilterSearchType.LETTINGS;
      }
      return getVariable(this.component, 'type-text') as PFilterSearchType;
    },

    mode(): ComponentMode {
      return getVariable(this.component, 'mode-text') as ComponentMode;
    },

    componentModes() {
      return ComponentMode;
    },

    submitButtonLabel() {
      return this.mode === ComponentMode.CREATE ? 'Register' : 'Update';
    },

    successPopupText() {
      return this.mode === ComponentMode.CREATE
        ? 'You have been registered successfully.'
        : 'Your preferences have been updated successfully.';
    },

    variant() {
      return useVariant(this.component);
    },
  },

  async setup({ component }: { component: Component }) {
    const route = useRoute();
    const applicantStore = useApplicantsStore();

    if (useAppMode().server) {
      // if it is update, fetch applicant
      if (route.params.applicant_key && getVariable(component, 'mode-text') === ComponentMode.UPDATE) {
        await applicantStore.fetchApplicantToUpdate(route.params.applicant_key as string);
      } else {
        // if it is register, fetch fresh contact data
        const contactsStore = useContactsStore();
        if (contactsStore.contactInfo && !contactsStore.contact) {
          await contactsStore.fetchContact(contactsStore.contactInfo.contact_id);
        }
      }
    }

    if (useAppMode().client && route.params.applicant_key && !applicantStore.applicantUpdateView) {
      alert('Applicant not found');
    }
  },

  methods: {
    changeTab(index: number) {
      this.activeTab = index;
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    async onSave() {
      interface CustomError {
        warning: boolean;
        message: string;
      }

      try {
        this.isLoading = true;
        const contactsRef = this.$refs.contactArea?.contacts || [];
        const applicantCriteria = this.$refs.propertyCriteriaArea?.filters || {};
        const questionAnswers = this.$refs.questionsArea?.answeredQuestions || {};

        const contactsStore = useContactsStore();

        if (contactsRef.length === 0) {
          this.changeTab(0);
          throw {
            warning: true,
            message: `You must add a contact at least.`,
          };
        }

        if (applicantCriteria.locations && applicantCriteria.locations.length === 0) {
          this.changeTab(1);
          throw {
            warning: true,
            message: `The location field is required.`,
          };
        }

        if (applicantCriteria) {
          if (applicantCriteria.min_price === 0) {
            this.changeTab(1);
            throw {
              warning: true,
              message: `The min price field is required.`,
            };
          }

          if (applicantCriteria.max_price === 0) {
            this.changeTab(1);
            throw {
              warning: true,
              message: `The max price field is required.`,
            };
          }
        }

        const applicantsStore = useApplicantsStore();

        // required question checks
        const requiredQuestions = applicantsStore.requiredQuestionsByType(this.searchType);
        if (requiredQuestions && requiredQuestions.length > 0) {
          requiredQuestions.forEach((question) => {
            if (!(question.contact_question_id in questionAnswers)) {
              this.changeTab(2);
              throw {
                warning: true,
                message: `"${question.question}" question is required.`,
              };
            }
          });
        }

        const contacts = deepClone(contactsRef);

        contacts.forEach((contact) => {
          contact.communication_info_delete = true;
          contact.create_without_email = true;
          delete contact.avatar;
        });

        const payload = {
          profile_id: this.searchType === 'sales' ? ContactProfiles.BUYER : ContactProfiles.TENANT,
          contacts: contacts.map((contact) => {
            return {
              ...contact,
              subscription_types: contactsStore.subscriptions.map((subscription) => subscription.subscription_type_id),
            };
          }),
          property_criteria: applicantCriteria,
          online_registration: true,
          contact_questions: Object.keys(questionAnswers).map((key) => {
            return {
              contact_question_id: +key,
              answer: questionAnswers[key],
            };
          }),
        };

        if (contactsStore.kickbackLogId) {
          payload.kickback_log_id = contactsStore.kickbackLogId;
        }

        let result = false;
        if (this.mode === ComponentMode.UPDATE && this.applicantUpdateView) {
          result = await applicantsStore.updateApplicant(this.applicantUpdateView.contact_applicant_id, payload);
        } else {
          payload.contacts = payload.contacts.map((contact: any) => {
            return {
              ...contact,
              contact_source_id: ContactSourcesConstants.ONLINE_REGISTRATION,
              contact_profiles: [
                ...new Set([
                  ...contact.contact_profiles,
                  this.searchType === PFilterSearchType.LETTINGS ? ContactProfiles.TENANT : ContactProfiles.BUYER,
                ]),
              ],
            };
          });
          result = await applicantsStore.createApplicant(payload);
        }

        if (result) {
          this.successModalConfig.isActive = true;
        } else {
          this.errorMessage(result);
        }
      } catch (e: CustomError) {
        this.isLoading = false;
        if (e && e.warning) {
          this.warningMessage(e.message);
        } else {
          this.errorMessage(e);
        }
      }
    },

    onCancelled() {
      this.redirectTo(
        {
          path: '/properties',
        },
        true,
      );
    },

    onSuccessModalClosed() {
      this.redirectTo(
        {
          path: '/properties',
        },
        true,
      );
    },
  },
});
</script>

<style scoped>
.nc-tab {
  border: 1px solid #aca39a;
}

.nc-tab-active {
  position: relative;
  border: 1px solid #11273e !important;
}

.nc-tab-active::after {
  content: '\e907';
  position: absolute;
  top: -8px;
  right: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: var(--white);
  background-color: #af624e;
  font-family: 'neuron-common-icons';
  font-size: 0.5rem;
  border-radius: 50%;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition:
    transform 0.3s ease-in,
    -webkit-transform 0.3s ease-in;
}

.nc-tab-active-v2::after {
  background-color: var(--c-primary) !important;
}
::v-deep(.properties-search-locations),
::v-deep(.live-search-container) {
  border-radius: var(--rounded-input);
}
</style>
