<template>
  <div
    id="register-contact-area"
    class="tw-mb-5 tw-hidden tw-w-full tw-border-b tw-border-[var(--default-border-color)] lg:tw-flex lg:tw-flex-row lg:tw-justify-center"
  >
    <lazy-library-title tag="h3" :text="title" class="tw-py-6"></lazy-library-title>
  </div>
  <div v-if="contacts && contacts.length > 0" class="g-0 tw-grid tw-grid-cols-12 tw-pt-16">
    <div class="tw-col-span-12 tw-flex tw-flex-col">
      <div
        style="background-color: #f8f8f8"
        class="card tw-flex tw-flex-row tw-items-center tw-justify-start tw-px-6 tw-py-4"
      >
        <span style="font-size: 28px" class="nc-icon-info-circle"> </span>
        <lazy-library-text
          class="tw-ml-3"
          :text="`You added ${contacts.length} contact${contacts.length > 1 ? 's' : ''} to the registration`"
        >
        </lazy-library-text>
      </div>
    </div>
  </div>
  <div v-if="contacts && contacts.length > 0" class="g-0 tw-grid tw-grid-cols-12 tw-pt-11">
    <div class="tw-col-span-12 tw-flex tw-flex-col">
      <register-contact-card
        v-for="(contact, index) in contacts"
        :key="`contact-${index}`"
        class="card mb-2"
        :length-of-contacts="contacts.length"
        :contact="contact"
        @remove="onContactRemoved(index)"
        @edit="onContactEdited(index)"
      />
    </div>
  </div>
  <div v-if="!isFormActive" class="tw-my-4">
    <nc-button label="Add new contact" @on-click="onClickedAddNewContact()">
      <template #label>
        <div class="tw-flex tw-items-center">
          <span class="nc-icon-plus-circle tw-mr-2" style="font-size: 30px"></span>
          <div>Add new contact</div>
        </div>
      </template>
    </nc-button>
  </div>
  <div v-if="isFormActive" class="register-contact-area tw-grid tw-grid-cols-12 tw-gap-4 tw-gap-y-4 tw-pb-6 tw-pt-11">
    <div class="custom-col-span tw-col-span-6">
      <nc-select
        v-model="contactForm.salutation_id"
        :options="salutations"
        label-field="name"
        value-field="salutation_id"
        label="Title"
        :is-required="true"
      />
    </div>
    <div class="custom-col-span tw-col-span-6">
      <nc-input v-model="contactForm.name" label="Name" placeholder="Name" :is-required="true" />
    </div>
    <div class="tw-col-span-12">
      <nc-input v-model="contactForm.surname" label="Surname" placeholder="Surname" :is-required="true" />
    </div>
    <div class="tw-col-span-12">
      <nc-input v-model="contactForm.email" label="Email" placeholder="Email" :is-required="true" />
    </div>
    <div class="tw-col-span-12">
      <nc-input v-model="contactForm.mobilePhone" label="Mobile Phone" placeholder="Mobile Phone" :is-required="true" />
    </div>
    <div class="tw-col-span-12">
      <postcode-searcher v-model="fullAddress" />
    </div>
    <div class="tw-col-span-12">
      <nc-checkbox
        v-model="contactForm.isSubscriptionConfirmed"
        label="Please confirm that it is okay for us to contact you about this information as well as products and services. (You will always be given the right to unsubscribe at any point in the future)*"
      />
    </div>
    <div class="tw-col-span-12 tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-x-2">
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
        <lazy-library-text v-if="false" type="bold" class="tw-cursor-pointer" text="Cancel" @click="onCancelled()">
        </lazy-library-text>
        <div class="custom-find-btn">
          <nc-button label="Save" @on-click="onSave()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import { PFilterSearchType } from '~/units/properties/types';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import { SalutationsConstants } from '~/units/contacts/constants/salutations.constants';
import PostcodeSearcher from '~/components/common/address/PostcodeSearcher.vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import type { ContactAddress } from '~/units/contacts/types';
import { ContactsRegistrationMixin } from '~/units/contacts/mixins/contactsRegistration.mixin';
import RegisterContactCard from '~/components/common/register/RegisterContactCard.vue';
import { useContactsStore } from '~/units/contacts/store';
import { ComponentMode } from '~/units/core/types';
import { useApplicantsStore } from '~/units/applicants/store';

export default defineNuxtComponent({
  name: 'RegisterContactArea',
  components: {
    RegisterContactCard,
    NcButton,
    NcCheckbox,
    PostcodeSearcher,
    NcSelect,
    NcInput,
    MultipleInputGroup,
    Locations,
  },

  props: {
    mode: {
      required: true,
      type: String as PropType<ComponentMode>,
    },

    title: {
      required: false,
      type: String,
      default: 'Contact',
    },
  },

  mixins: [ComponentMixin, ContactsRegistrationMixin],

  data(): {
    contactForm: {
      avatar: null | string;
      contact_profiles: number[];
      contact_id: null | string;

      salutation_id: number;
      name: string;
      surname: string;
      email: string;
      mobilePhone: string;
      isSubscriptionConfirmed: boolean;
    };
    fullAddress: ContactAddress | null;
    communications: any[];

    contacts: any[];
    onTheFlyContacts: any[];

    isFormActive: boolean;
  } {
    return {
      contactForm: {
        avatar: null,
        contact_profiles: [],
        contact_id: null,

        salutation_id: 2,
        name: '',
        surname: '',
        email: '',
        mobilePhone: '',
        isSubscriptionConfirmed: false,
      },

      fullAddress: null,
      communications: [],

      contacts: [],
      onTheFlyContacts: [],

      isFormActive: false,
    };
  },

  computed: {
    ...mapState(useApplicantsStore, ['applicantUpdateView']),
    ...mapState(useContactsStore, ['contact']),

    searchType(): PFilterSearchType {
      return getVariable(this.component, 'type-text') as PFilterSearchType;
    },

    salutations() {
      return SalutationsConstants.salutations;
    },
  },

  async setup() {
    const store = useContactsStore();
    await store.fetchSubscriptions();
  },

  mounted() {
    if (this.mode === ComponentMode.UPDATE && this.applicantUpdateView) {
      const contacts = this.applicantUpdateView?.contacts;
      this.onTheFlyContacts = contacts.map((contact) => contact.contact);
      this.parseOnTheFlyContacts();
    } else if (this.mode === ComponentMode.CREATE) {
      const store = useContactsStore();
      if (store.contact) {
        this.contacts.push(this.parseOnTheFlyContact(store.contact));
        // checking address data of contacts to open form
        if (this.contacts.length > 0) {
          this.checkRequirementsForContacts();
        }
      } else {
        this.openContactForm();
      }
    }
  },

  methods: {
    onSave() {
      this.save();
    },

    onCancelled() {
      this.cancel();
    },

    onContactRemoved(index: number) {
      this.removeContact(index);
    },

    onContactEdited(index: number) {
      this.editContact(index);
    },

    onClickedAddNewContact() {
      this.isFormActive = true;
    },
  },
});
</script>

<style scoped></style>
