<template>
  <div v-if="version === 'v1'" class="live-search-container tw-h-full tw-w-4/6">
    <div class="tw-flex tw-h-full tw-flex-row tw-items-center">
      <div v-if="!isLoading" class="search-icon-container tw-pl-6">
        <i v-if="!isSearchIconHidden" class="nc-icon nc-icon-search tw-text-lg"></i>
      </div>
      <div v-else-if="isLoading" class="tw-pl-6">
        <loader :loader-size="loadingCircleSize" :loader-padding="'0px'" />
      </div>
      <input
        v-if="debounceHandler"
        v-model="keyword"
        id="location-live-search-input"
        class="form-input w-input n-input live-search-input tw-h-full tw-w-full tw-pl-2 focus:tw-outline-none"
        style="margin-bottom: 0 !important; border: unset !important"
        maxlength="256"
        placeholder="Search by address"
        type="text"
        @keyup="debounceHandler()"
        @keyup.enter="debounceHandler()"
        autocomplete="search-by-address"
      />
    </div>
    <div v-if="isSearchResultsVisible" class="live-search-popup p-3">
      <div v-if="!isLoading && (!searchResultLocations || searchResultLocations.length === 0)">
        <span> Couldn't find the address </span>
      </div>
      <template v-else-if="!isLoading">
        <div
          v-for="(item, index) in searchResultLocations"
          :key="`search-result-item-${index}`"
          class="d-flex flex-column mb-2 live-search-result-item p-2 n-cursor-pointer"
          @click="onClickedResultItem(item)"
        >
          <div class="font-weight-bold">
            {{ item.name }}
          </div>
          <span style="color: #adadad"> {{ item.description }}</span>
        </div>
      </template>
    </div>
  </div>

  <div v-else-if="version === 'v2'" class="live-search-container tw-h-full tw-w-full">
    <div class="tw-flex tw-h-full tw-flex-row tw-items-center">
      <div v-if="isLoading" class="tw-absolute tw-left-2">
        <loader :loader-size="loadingCircleSize" :loader-padding="'0px'" />
      </div>
      <input
        v-if="debounceHandler"
        v-model="keyword"
        class="live-search"
        id="location-live-search-input"
        :class="{
          '!tw-pl-12': isLoading,
          '!tw-shadow-none lg:!tw-border-none': horizontalLayout,
        }"
        placeholder="Enter a search location"
        type="text"
        @keyup="debounceHandler()"
        @keyup.enter="debounceHandler()"
      />

      <!-- inline button -->
      <slot />
    </div>
    <div v-if="isSearchResultsVisible" v-clicking-outside-directive="onClickedOutside" class="live-search-popup p-3">
      <div v-if="!isLoading && (!searchResultLocations || searchResultLocations.length === 0)">
        <span> Couldn't find the address </span>
      </div>
      <template v-else-if="!isLoading">
        <div
          v-for="(item, index) in searchResultLocations"
          :key="`search-result-item-${index}`"
          class="live-search-result-item n-cursor-pointer tw-mb-1 tw-flex tw-flex-col tw-gap-1 tw-p-2"
          @click="onClickedResultItem(item)"
        >
          <div class="tw-ml-2">
            <div class="font-weight-bold">
              {{ item.name }}
            </div>
            <span style="color: #adadad"> {{ item.description }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';
import { clickingOutsideDirective } from '~/units/core/directives/clickingOutside.directive';
import { debounce } from '~/units/core/providers/debounce.provider';
import Loader from '~/components/common/Loader.vue';
import { useAddressesStore } from '~/units/addresses/store';

export type SearchMethod = () => Promise<any>;

export default {
  name: 'LiveSearch',
  components: { Loader },

  directives: {
    clickingOutsideDirective,
  },

  mixins: [SubComponentMixin],

  props: {
    modelValue: {
      required: true,
      type: String,
    },

    searchMethod: {
      required: true,
      type: Function as PropType<SearchMethod>,
    },

    searchResultLocations: {
      required: true,
      type: Array<any>,
    },

    loadingCircleSize: {
      required: false,
      default: 30,
      type: Number,
    },

    isSearchIconHidden: {
      required: false,
      default: false,
      type: Boolean,
    },

    version: {
      required: false,
      default: 'v1',
      type: String,
    },

    horizontalLayout: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'onClickedResultItem', 'onLocationReset', 'onNewLocationSelected'],

  data() {
    return {
      isSearchResultsVisible: false,
      isLoading: false,

      debounceHandler: null,
    };
  },

  computed: {
    keyword: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  mounted() {
    this.initDebounceHandler();
  },

  methods: {
    async onSearched() {
      const keyword = this.keyword ? this.keyword.trim() : '';

      if (keyword) {
        try {
          this.isLoading = true;
          await this.searchMethod();
          this.isSearchResultsVisible = true;
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
        }
      } else if (!keyword) {
        this.$emit('onLocationReset');
      }

      await this.$nextTick(() => {
        document.getElementById('location-live-search-input')?.focus();
      });
    },

    onClickedOutside() {
      this.isSearchResultsVisible = false;
    },

    initDebounceHandler() {
      if (!this.debounceHandler) {
        this.debounceHandler = debounce(this.onSearched, 1500);
      }
    },

    async onClickedResultItem(item: any) {
      this.isLoading = true;
      this.isSearchResultsVisible = false;
      try {
        const addressesStore = useAddressesStore();
        const { data } = await addressesStore.getGeocodeByPlaceId(item.place_id);

        this.keyword = item.name;
        this.$emit('onNewLocationSelected', {
          selectedLocationItem: item,
          selectedAddressDetails: data,
        });
      } catch (e) {}
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.live-search-container {
  position: relative;
}

.live-search-popup {
  position: absolute;
  top: calc(100% + 0.1rem);
  box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0s,
    z-index 0s ease-in,
    -webkit-transform 0.3s;
  background-color: var(--white);
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 7px;
  z-index: 1;
}

.live-search-result-item {
}

.live-search-result-item:hover {
  background-color: #f8f8f8;
}

.live-search-input {
}

.live-search-input:disabled {
  background: unset !important;
}
</style>
