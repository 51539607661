import { mapActions, mapState } from 'pinia';
import { useCoreStore } from '~/units/core/store';
import { type FullScreenModalConfig, type FullScreenModalType } from '~/units/core/types';

export const FullScreenPopupMixin = defineNuxtComponent({
  computed: {
    ...mapState(useCoreStore, ['fullScreenModalConfig']),

    fullScreenPopupType(): FullScreenModalType {
      return null;
    },

    fullScreenPopupConfig(): FullScreenModalConfig {
      const data = this.fullScreenModalConfig;
      if (!this.fullScreenPopupType && data) {
        return data;
      } else if (data && this.fullScreenPopupType === data.type) {
        return data;
      }
      return {
        is_active: false,
        item: null,
        type: null,
      };
    },
  },

  methods: {
    ...mapActions(useCoreStore, ['setFullScreenModalConfig']),

    showOnLightbox(item: any, type: FullScreenModalType) {
      const data = this.fullScreenModalConfig;
      if (!data) {
        this.setFullScreenModalConfig({
          is_active: true,
          item,
          type,
        });
      }
    },

    openJustLightBox(type: FullScreenModalType) {
      const data = this.fullScreenModalConfig;
      if (!data) {
        this.setFullScreenModalConfig({
          is_active: true,
          type,
        });
      }
    },

    closeLightbox() {
      this.setFullScreenModalConfig(null);
    },
  },
});
