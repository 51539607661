<template>
  <div
    class="properties-search-locations nc-text tw-flex tw-w-full tw-cursor-pointer tw-flex-row tw-items-center tw-border-r tw-bg-[var(--white)]"
    :class="[isMultipleLocation ? containerClasses : '', isMultipleLocation ? 'tw-h-[60px]' : 'tw-h-full']"
  >
    <live-search
      :version="version"
      v-model="keyword"
      :search-result-locations="searchResultLocations"
      :search-method="onSearchLocation"
      :loading-circle-size="loadingCircleSize"
      :is-search-icon-hidden="isSearchIconHidden"
      @on-new-location-selected="onNewLocationSelected"
      @on-location-reset="onLocationReset"
      :horizontal-layout="horizontalLayout"
    >
      <!-- version V2 -->
      <div
        v-if="isMileSelectionVisible && version === 'v2'"
        class="location-selectbox tw-absolute tw-right-2 tw-z-10 tw-h-full tw-cursor-pointer"
        :class="[
          isMultipleLocation ? 'tw-flex tw-items-center tw-justify-between tw-px-2' : 'tw-px-1',
          fullHeightMileAndLocationSearchSeparator
            ? 'tw-flex tw-h-full tw-flex-col tw-justify-center tw-border-l'
            : 'tw-border-l',
        ]"
        style="border-color: var(--c-border)"
      >
        <select
          v-model="mile"
          class="tw-mt-[2px] tw-h-[92%] tw-cursor-pointer tw-outline-none"
          style="border: unset !important"
        >
          <option v-for="(mileOption, index) in miles" :key="`mile-${index}`" :value="mileOption.value">
            {{ mileOption.display }}
          </option>
        </select>
        <nc-button
          v-if="isMultipleLocation"
          :height="36"
          :width="2"
          class="tw-ms-3"
          styleClass="!tw-p-3"
          @on-click="onAddLocation()"
        >
          <template #label>
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 7.30435H8.69565V0H7.30435V7.30435H0V8.69565H7.30435V16H8.69565V8.69565H16V7.30435Z"
                  fill="#fff"
                />
              </svg>
            </span>
          </template>
        </nc-button>
      </div>
    </live-search>
    <!-- version V1 -->
    <div
      v-if="isMileSelectionVisible && version === 'v1'"
      class="n-locations-filter custom-find-btn tw-cursor-pointer"
      :class="[
        isMultipleLocation ? 'd-flex flex-row justify-content-between align-items-center px-2 tw-w-3/6' : 'px-4',
        fullHeightMileAndLocationSearchSeparator
          ? 'tw-flex tw-h-full tw-flex-col tw-justify-center tw-border-l tw-border-[#CCCCCC]'
          : 'tw-border-l tw-border-[#635c59]',
      ]"
    >
      <select v-model="mile" class="tw-cursor-pointer" style="border: unset !important">
        <option v-for="(mileOption, index) in miles" :key="`mile-${index}`" :value="mileOption.value">
          {{ mileOption.display }}
        </option>
      </select>
      <nc-button v-if="isMultipleLocation" :height="36" :width="2" class="ms-3" @on-click="onAddLocation()">
        <template #label>
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 7.30435H8.69565V0H7.30435V7.30435H0V8.69565H7.30435V16H8.69565V8.69565H16V7.30435Z"
                class="nc-button-icon"
              />
            </svg>
          </span>
        </template>
      </nc-button>
    </div>
  </div>
  <location-boxes v-if="isMultipleLocation" v-model:locations="locationsModel" />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';
import LiveSearch from '~/components/common/LiveSearch.vue';
import CriteriaPreferencesConstants from '~/units/properties/constants/criteriaPreferences.constants';
import type { LocationComponent, PropertySearchFilter } from '~/units/properties/types';
import NcButton from '~/components/common/inputs/NcButton.vue';
import LocationBoxes from '~/components/common/LocationBoxes.vue';
import { useWebsiteStore } from '~/units/website/store';
import { useAddressesStore } from '~/units/addresses/store';

export default {
  name: 'Locations',
  components: { LocationBoxes, NcButton, LiveSearch },

  mixins: [SubComponentMixin],

  props: {
    modelValue: {
      required: true,
      type: Object as PropType<PropertySearchFilter>,
    },

    locations: {
      required: true,
      type: Array as PropType<LocationComponent[]>,
    },

    isMultipleLocation: {
      required: false,
      type: Boolean,
    },

    isMileSelectionVisible: {
      required: false,
      default: true,
      type: Boolean,
    },

    loadingCircleSize: {
      required: false,
      default: 30,
      type: Number,
    },

    isSearchIconHidden: {
      required: false,
      default: false,
      type: Boolean,
    },

    inputComponentName: {
      required: false,
      default: 'live-search',
      type: String,
    },

    version: {
      required: false,
      default: 'v1',
      type: String,
    },

    horizontalLayout: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:locations'],

  data(): {
    searchResultLocations: any[];
    selectedLocation: LocationComponent | null;
  } {
    return {
      searchResultLocations: [],
      selectedLocation: null,
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    mile: {
      get() {
        return this.modelValue.mile;
      },
      set(value: number) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          mile: value,
        });
      },
    },

    keyword: {
      get() {
        return this.modelValue.keyword;
      },
      set(value: string) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          keyword: value,
        });
      },
    },

    locationsModel: {
      get(): LocationComponent[] {
        return this.locations;
      },
      set(value: LocationComponent) {
        this.$emit('update:locations', value);
      },
    },

    miles() {
      return CriteriaPreferencesConstants.miles;
    },

    fullHeightMileAndLocationSearchSeparator() {
      return this.theme === 'theme3';
    },

    containerClasses() {
      switch (this.theme) {
        case 'theme3':
          return 'tw-border tw-border-[var(--default-border-color)]';
        case 'theme2':
          return 'tw-border tw-border-[var(--default-border-color)]';
        default:
          return '';
      }
    },
  },

  methods: {
    onAddLocation() {
      if (this.selectedLocation) {
        this.locationsModel = [
          ...this.locations,
          {
            ...this.selectedLocation,
            distance: this.modelValue.mile,
          },
        ];
      }
      this.selectedLocation = null;
      this.keyword = '';
    },

    onRemovedLocation(index: number) {
      this.locationsModel = this.locationsModel.filter(
        (item: LocationComponent, locationIndex) => locationIndex !== index,
      );
    },

    setSelectedLocation(location: LocationComponent) {
      this.selectedLocation = location;
      if (!this.isMultipleLocation) {
        this.locationsModel = [location];
      }
    },

    async onSearchLocation() {
      const addressesStore = useAddressesStore();
      const result = await addressesStore.searchPlaces(this.modelValue.keyword);
      this.searchResultLocations = [...result.data];
    },

    onLocationReset() {
      this.searchResultLocations = [];
      if (this.locations.length === 1) {
        this.selectedLocation = null;
        this.locationsModel = [];
      }
      // this.successMessage('Selected location has been removed');
    },

    onNewLocationSelected({
      selectedLocationItem,
      selectedAddressDetails,
    }: {
      selectedLocationItem: any;
      selectedAddressDetails: any;
    }) {
      this.setSelectedLocation({
        location: selectedLocationItem.name,
        place_id: selectedAddressDetails.place_id,
        bounds: selectedAddressDetails.geometry.bounds,
        location_type: selectedAddressDetails.geometry.location_type,
        ...selectedAddressDetails.geometry.location,
        distance: this.modelValue.mile,
      });

      this.keyword = selectedLocationItem.name;
      this.searchResultLocations = [];
    },
  },
};
</script>
