<template>
  <div v-if="version === 'v1'" class="tw-mt-2 tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-10 lg:tw-gap-y-12">
    <nuxt-link
      v-for="(property, index) in properties"
      :key="`property-${index}`"
      :external="true"
      :to="property.detail_page_url"
      class="d-flex flex-column mb-3 mb-md-0 n-cursor-pointer property-card tw-col-span-12 tw-border-b tw-border-[#CCCCCC] md:tw-col-span-6 xl:tw-col-span-4"
    >
      <div class="tw-flex tw-flex-col">
        <div class="image-wrapper project property-image w-100">
          <NuxtImg
            :src="property.image"
            loading="lazy"
            alt=""
            quality="60"
            class="cover-image project"
            style="height: 320px; object-fit: cover; object-position: center"
          />
          <div v-if="property.custom_property_status_title" class="property-image-badge">
            {{ property.custom_property_status_title }}
          </div>
        </div>
        <div>
          <div
            class="mt-2 p-4 d-flex flex-row justify-content-between align-items-center"
            style="background-color: var(--tertiary_color); color: var(--white); height: 100px"
          >
            <div class="w-100 d-flex flex-column">
              <div class="n-capitalized" style="font-size: 12px">
                {{ property.price.type.name }}
              </div>
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div style="font-size: 24px">
                  {{ property.price.price }}
                </div>
                <div class="d-flex flex-row align-items-center" style="column-gap: 10px; position: relative">
                  <div v-if="property.match_percentage" style="font-size: 16px" class="d-flex align-items-center pt-1">
                    {{ property.match_percentage }} MATCH
                  </div>
                  <nc-tooltip>
                    <template #item>
                      <span class="nc-icon-info-circle d-flex align-items-center" style="font-size: 20px"></span>
                    </template>
                    <template #tooltip>
                      <h4 class="mb-2 pt-2">Match Score:</h4>
                      <p>
                        This is a prediction of what our software thinks you may find an interesting property, based on
                        the unique search criteria that you gave us. You can improve the relevance of your match score
                        by adjusting and saving your search criteria.
                      </p>
                    </template>
                  </nc-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex flex-row align-items-start tw-h-[80px]">
        <h3 style="color: var(--tertiary_color)" class="tw-text-xl tw-font-extralight">
          {{ property.advert_heading }}
        </h3>
      </div>
      <div class="mt-3 d-flex flex-row align-items-start tw-h-[70px]">
        <div class="d-flex flex-column" style="color: var(--tertiary_color)">
          <div style="letter-spacing: 5px; font-weight: 100; font-size: 12px">ADDRESS</div>
          <div class="tw-mt-1 tw-text-sm tw-font-extralight">
            {{ property.display_address }}
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex flex-row align-items-start tw-h-auto tw-pb-12 md:tw-h-[160px] md:tw-pb-0">
        <div class="d-flex flex-column" style="color: var(--tertiary_color)">
          <div style="letter-spacing: 5px; font-weight: 100; font-size: 12px">DESCRIPTION</div>
          <div class="tw-mt-1 tw-line-clamp-6 tw-text-sm tw-font-extralight">
            {{ property.summary }}
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>

  <div v-else-if="version === 'v2'" class="tw-grid tw-grid-cols-12 tw-gap-y-10 lg:tw-gap-x-10 lg:tw-gap-y-12">
    <template v-for="(property, index) in properties" :key="`property-${index}`">
      <!-- Property card -->
      <PropertyListItem :property="property" class="tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4" />

      <!-- Custom card after every nth property -->
      <div
        v-if="customCardIndex && (index + 1) % customCardIndex === 0"
        class="tw-col-span-12 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-p-10 lg:tw-col-span-6 xl:tw-col-span-4"
        :style="`border-radius: var(--rounded); background-color: var(--c-${vars.backgroundColor})`"
      >
        <h4 :style="`color: var(--c-${vars.titleColor}`" class="tw-w-full">{{ vars.titleText }}</h4>
        <div class="tw-flex tw-flex-col tw-gap-6">
          <p :style="`color: var(--c-${vars.textColor}`">{{ vars.descriptionText }}</p>
          <nuxt-link :class="`n-${vars.buttonTypeText} tw-self-start`" :to="vars.buttonLinkUrl">
            {{ vars.buttonLabelText }}
            <span class="btn-arrow"
              ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg>
            </span>
          </nuxt-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import type { PropertyItemComponent } from '~/units/properties/types';
import NcTooltip from '~/components/common/NcTooltip.vue';
import PropertyListItem from '~/components/theme2/properties/PropertyListItem.vue';

export default defineNuxtComponent({
  name: 'PropertiesListBox',
  components: { NcTooltip, PropertyListItem },

  mixins: [ComponentMixin],

  props: {
    properties: {
      required: true,
      type: Array as PropType<PropertyItemComponent[]>,
    },

    version: {
      default: 'v1',
      type: String,
    },

    customCardIndex: {
      required: false,
      type: Number,
    },

    vars: {
      required: false,
      type: Object,
    },
  },
});
</script>

<style scoped>
.property-card {
  transition:
    transform 0.3s linear,
    -webkit-transform 0.3s linear;
}
.property-card:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.property-image {
  position: relative;
}

.property-image-badge {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--dark-brown);
  color: var(--white);
  padding: 10px;
  font-weight: bold;
}
</style>
