import { RequestMethod, RequestName, RequestProxy, useCaller } from '~/hydration/bridges';

export class PropertyTypeStylesService {
  async fetchTypes() {
    try {
      return await useCaller(
        RequestProxy.LS_AXON,
        RequestName.FETCH_PROPERTY_TYPES,
        'properties/property_types',
        RequestMethod.GET,
        {
          __limit: 250,
        },
      );
    } catch (e) {
      return [];
    }
  }

  async fetchStyles() {
    try {
      return await useCaller(
        RequestProxy.LS_AXON,
        RequestName.FETCH_PROPERTY_TYPES,
        'properties/property_styles',
        RequestMethod.GET,
        {
          __limit: 250,
        },
      );
    } catch (e) {
      return [];
    }
  }
}
