<template>
  <div
    class="pm-card profile-card n-cursor-pointer n_applicant-card tw-flex tw-flex-col tw-pb-3"
    :class="isSelectedApplicant ? 'active-card n_selected' : 'mouse-click'"
    @click="onSelected()"
  >
    <!-- Bar: start -->
    <div class="nc-radius n_applicant-card__top-bar tw-mt-2 tw-flex tw-justify-between tw-px-2 tw-py-2">
      <div class="tw-flex tw-flex-row tw-items-center">
        <div
          v-if="isVisibleUpdateButton"
          class="applicant-update-button tw-flex tw-items-start tw-justify-start tw-pe-2"
          @click.stop=""
        >
          <input
            :id="item.contact_applicant_id"
            v-model="status"
            :value="status"
            class="pm-toggle"
            :name="`contactApplicantStatus-${item.contact_applicant_id}`"
            type="checkbox"
            @change="onUpdatedStatus()"
          />
          <label
            class="pm-toggle-label n_top-bar__toggle"
            :class="isSelectedApplicant ? 'selected' : ''"
            :for="item.contact_applicant_id"
          ></label>
        </div>
        <div class="pm-card-header">
          <span class="pm-card-box n_top-bar__info-box last-search" :class="isSelectedApplicant ? '' : 'reverse'">{{
            createdDate
          }}</span>
        </div>
      </div>
      <div class="pm-card-header tw-flex tw-flex-row tw-items-center">
        <div class="user-info">
          <span class="pm-card-box n_top-bar__info-box contacts" :class="isSelectedApplicant ? '' : 'reverse'">
            {{ `${otherContactCount > 0 ? '+' : ''} ${otherContactCount}` }}
          </span>
          <div v-if="contactGroup.length > 0" class="pm-mini-dd pm-active">
            <template v-for="(contact, index) in contactGroup" :key="`contact-group-${index}`">
              <span class="pm-fs-11">
                <i class="nc-icon-user-alt" aria-hidden="true"></i>
                <p class="ms-1">
                  {{ contact.contact.full_name }}
                </p>
              </span>
            </template>
          </div>
        </div>
        <span class="tag" :class="item.profile_id === ContactProfilesConstants.TENANT ? 'pm-bg-tenant' : ''">
          {{ profileLetter }}
        </span>
      </div>
    </div>
    <!-- Bar: finish -->
    <div class="tw-flex tw-flex-col tw-px-2">
      <!-- Locations: start -->
      <div v-if="item.criteria.locations.length > 0" class="criteria-text tw-mt-3">
        <i class="nc-icon-map-marker-alt tw-me-1" style="font-size: 18px" />
        <span v-for="(location, key) in item.criteria.locations" :key="`location-${key}`">
          {{ location.location }} - {{ location.distance }} mile{{ location.distance > 1 ? 's' : ''
          }}{{ item.criteria.locations.length !== key + 1 ? ',' : '' }}
        </span>
      </div>
      <!-- Locations: finish -->
      <!-- Price: start -->
      <div v-if="priceCriteria" class="criteria-text tw-my-3">
        <i class="nc-icon-tag" style="font-size: 18px" />
        <span class="tw-ms-1">
          {{ priceCriteria }}
        </span>
      </div>
      <!-- Price: finish -->
      <!-- Criteria: start -->
      <div v-if="applicantCriterion && applicantCriterion.length" class="pm-card-footer tw-mb-3">
        <template v-for="(criteria, index) in applicantCriterion" :key="`criteria-${index}`">
          <div class="tw-flex tw-items-center">
            <i
              class="tw-me-1"
              :class="`nc-icon-${criteria.type.icon}`"
              :style="`font-size: ${criteria.type.size}px`"
              aria-hidden="true"
            ></i>
            <div class="criteria-text tw-me-2 tw-flex tw-flex-row tw-items-center">
              <div v-if="criteria.show_range" class="tw-me-1 tw-flex tw-flex-row tw-items-center">
                <span v-if="!criteria.max" class="tw-me-1"> from </span>
                {{ criteria.min }}
              </div>
              <div v-if="criteria.max" class="tw-flex tw-flex-row tw-items-center">
                <span v-if="criteria.show_range" class="me-1"> to </span>
                {{ criteria.max }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- Criteria: finish -->
    </div>
    <div v-if="isVisibleUpdateButton && !isDisabled" class="tw-flex tw-flex-row tw-justify-end">
      <nc-button :height="50" label="Update" @on-click="goUpdateSearchProfile()"></nc-button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from 'pinia';
import type { PropType } from 'vue';
import { uiDateFormat } from '~/units/core/providers/date.provider';
import { ContactProfiles } from '~/constants/contacts';
import { ApplicantsConstants } from '~/units/applicants/constants';
import { currencyFormat } from '~/helpers';
import { useApplicantsStore } from '~/units/applicants/store';
import { useContactsStore } from '~/units/contacts/store';
import NcButton from '~/components/common/inputs/NcButton.vue';
import type { Applicant } from '~/units/applicants/types';

export default defineNuxtComponent({
  name: 'ApplicantProfileCard',
  components: { NcButton },
  props: {
    item: {
      required: true,
      type: Object as PropType<Applicant>,
    },
    selectedApplicantOnTheFly: {
      type: Object,
      default: null,
      required: false,
    },
    isVisibleUpdateButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    operationType: {
      type: String as PropType<'select' | 'save'>,
      default: 'select',
    },
  },
  data() {
    return {
      criteriaTypes: [
        {
          name: 'bedroom',
          icon: 'bed',
          size: 24,
        },
        {
          name: 'bathroom',
          icon: 'bath',
          size: 20,
        },
        {
          name: 'reception',
          icon: 'loveseat',
          size: 20,
        },
      ],
      status: false,
      IS_DISABLED_ID: 2,
      ContactProfilesConstants: ContactProfiles,
      currentlySelected: null,
    };
  },
  computed: {
    ...mapState(useApplicantsStore, ['applicant']),
    ...mapState(useContactsStore, ['contactInfo']),

    applicantCriterion() {
      return this.criteriaTypes
        .map((type) => {
          const min = this.item.criteria[`min_${type.name}`];
          const max = this.item.criteria[`max_${type.name}`];

          return {
            type,
            min,
            max,
            has_criteria: !!(min || max),
            show_range: min !== max,
          };
        })
        .filter((criteria) => criteria.has_criteria);
    },

    createdDate() {
      return uiDateFormat(this.item.created_at);
    },

    isSelectedApplicant() {
      if (this.selectedApplicantOnTheFly) {
        return this.selectedApplicantOnTheFly.contact_applicant_id === this.item.contact_applicant_id;
      }
      return this.applicant?.contact_applicant_id === this.item.contact_applicant_id;
    },

    isDisabled() {
      return this.item.contact_applicant_status_id === this.IS_DISABLED_ID;
    },

    profileLetter() {
      return this.item.profile_id === ContactProfiles.BUYER ? 'B' : 'T';
    },

    otherContactCount() {
      return this.contactGroup.length;
    },

    contactGroup() {
      if (this.item.contacts) {
        return this.item.contacts.filter(
          (contact: any) => this.contactInfo && contact.contact_id !== this.contactInfo.contact_id,
        );
      }
      return [];
    },

    priceCriteria() {
      if (this.item.criteria.min_price && this.item.criteria.max_price) {
        return `${currencyFormat(this.item.criteria.min_price)} - ${currencyFormat(this.item.criteria.max_price)}`;
      }
      if (this.item.criteria.min_price) {
        return `from ${currencyFormat(this.item.criteria.min_price)}`;
      }
      if (this.item.criteria.max_price) {
        return `to ${currencyFormat(this.item.criteria.max_price)}`;
      }
      return null;
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(e) {
        if (e) {
          this.status = this.item.contact_applicant_status_id === ApplicantsConstants.STATUS_ON;
        }
      },
    },
  },
  methods: {
    ...mapActions(useApplicantsStore, ['changeActiveLoggedApplicant', 'updateStatusOfApplicant']),
    onSelected() {
      if (!this.isSelectedApplicant) {
        this.runByOperationType()();
      }
    },

    runByOperationType() {
      if (this.operationType === 'select') {
        return this.setAsActiveApplicant;
      } else if (this.operationType === 'save') {
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return function () {};
    },

    async setAsActiveApplicant() {
      const applicantsStore = useApplicantsStore();

      if (this.item.contact_applicant_status_id === this.IS_DISABLED_ID) {
        await applicantsStore.updateStatusOfApplicant(this.item.contact_applicant_id, true);
      }
      await applicantsStore.changeActiveLoggedApplicantAndSearch(this.item);
      this.$emit('onSelectedNewApplicant', this.item.applicant_key);
    },

    async goUpdateSearchProfile() {
      await this.changeActiveLoggedApplicant(this.item);
      this.redirectToUpdate();
    },

    redirectToUpdate() {
      this.redirectTo({
        path: `/heads-up-alerts/profiles/${this.item.applicant_key}`,
      });
    },

    onUpdatedStatus() {
      this.updateStatusOfApplicant(this.item.contact_applicant_id, this.status);
      this.successMessage('Alert has been updated');
    },
  },
});
</script>

<style scoped>
.pm-card {
  display: block;
  padding: 1rem 1.5rem;
  font-family: Inter, sans-serif !important;
}

.pm-card.profile-card {
  display: block;
  background-color: #f8f8f8;
  padding: 1.5rem;
  border: 1px solid transparent;
  border-radius: 0;
  margin: 1rem 0;
  -webkit-transition: border-color 0.3s ease-in;
  transition: border-color 0.3s ease-in;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pm-card.profile-card .pm-card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pm-card.profile-card .pm-card-header span.pm-card-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--nc-ternary-color);
  background-color: var(--nc-secondary-bg-color);
  padding: 0.4rem 1rem;
  border-radius: 0;
  font-size: 0.75rem;
  line-height: 20px;
  font-weight: 400;
  margin: 0 0.5rem 0 0;
}

.pm-card.profile-card .pm-card-header span.pm-card-box.reverse {
  color: var(--white);
  background-color: var(--nc-ternary-bg-color);
}

.pm-card.profile-card .pm-card-header span.pm-card-box.last-search:before {
  content: '\e942';
  font-family: 'neuron-common-icons', sans-serif;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.pm-card.profile-card .pm-card-header span.pm-card-box.contacts:before {
  content: '\e92c';
  font-family: 'neuron-common-icons', sans-serif;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.pm-card.profile-card .pm-card-header span.tag {
  display: block;
  color: #fff;
  background-color: var(--nc-buyer);
  padding: 0.4rem 0.75rem;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 600;
}

.pm-card.profile-card .pm-card-header .user-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.pm-card.profile-card .pm-card-header .user-info .pm-mini-dd {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding: 0.75rem 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -9;
  width: 200px;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  -webkit-transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
}

.pm-card.profile-card .pm-card-header .user-info .pm-mini-dd.pop-down {
  top: calc(100% + 1rem);
  bottom: unset;
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}

.pm-card.profile-card .pm-card-header .user-info .pm-mini-dd span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  margin: 0;
  padding: 0.25rem 0;
}

.pm-card.profile-card .pm-card-header .user-info:hover .pm-mini-dd {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  width: 200px;
  -webkit-transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0s,
    z-index 0s ease-in;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0s,
    z-index 0s ease-in,
    -webkit-transform 0.3s;
}

.pm-card.profile-card .criteria-text {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 0.75rem;
  line-height: 20px;
  font-weight: 300;
}

.pm-card.profile-card .pm-card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pm-card.profile-card .pm-card-footer span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 0 1rem 0 0;
  line-height: 1rem !important;
}

.pm-card.profile-card .pm-card-footer span i {
  color: #8b8b8b;
  margin: 0 0.5rem 0 0;
}

.pm-card.profile-card .pm-card-footer span i.pm-bed {
  margin-bottom: -0.25rem;
}

.pm-card.profile-card .pm-card-footer span span {
  color: #000;
  font-size: 1.125rem;
  line-height: 129.7%;
  font-weight: 500;
}

.pm-card.profile-card.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.75;
  pointer-events: none;
}

.pm-card.profile-card:hover {
  border-color: #000;
}

.icon {
  color: #8b8b8b !important;
}

.profile-card {
  padding: 8px;
}

.pm-bg-tenant {
  background-color: var(--nc-tenant) !important;
}

input[type='checkbox'].pm-toggle {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.pm-toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 9px;
  background: #d8d8d8;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-bottom: 0;
}

label.pm-toggle-label:after {
  content: '';
  position: absolute;
  top: -3.5px;
  right: 0;
  width: 16px;
  height: 16px;
  background: #d8d8d8;
  border-radius: 90px;
  transition: 0.4s;
  border: 1px solid #fff;
}

input.pm-toggle:checked + label.pm-toggle-label {
  background: var(--nc-ternary-color);
}

input.pm-toggle:checked + label.pm-toggle-label.selected {
  background: var(--white);
}

input.pm-toggle:checked + label.pm-toggle-label:after {
  background: var(--nc-ternary-color);
}

input.pm-toggle:checked + label.pm-toggle-label.selected:after {
  background: var(--nc-ternary-color);
}

input.pm-toggle:checked + label.pm-toggle-label:after {
  right: calc(100%);
  transform: translateX(100%);
}

label.pm-toggle-label:active:after {
  width: 40px;
}

@media only screen and (max-width: 500px) {
  label.pm-toggle-label {
    top: 0;
  }
}

.active-card {
  border-color: var(--nc-ternary-color) !important;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.active-card:hover {
  border-color: var(--nc-ternary-color) !important;
}

.active-card::after {
  content: '\e907';
  position: absolute;
  top: -10px;
  right: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: var(--nc-primary-color);
  font-family: 'neuron-common-icons', sans-serif;
  font-size: 0.9rem;
  border-radius: 50%;
}
</style>
