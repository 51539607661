<template>
  <div v-if="!isLoading" class="tw-grid tw-grid-cols-12 tw-gap-4" style="row-gap: 20px">
    <div class="tw-col-span-12">
      <span class="nc-text" style="font-weight: bold"> Email About: </span>
      <span class="nc-text text-capitalize">{{ property.display_address }}</span>
    </div>
    <div v-if="inputs.isGeneralInformationInputVisible" class="tw-col-span-4">
      <nc-select
        v-model="leadFormData.salutation_id"
        :options="salutations"
        label-field="name"
        value-field="salutation_id"
        label="Title"
        :is-required="true"
      />
    </div>
    <div v-if="inputs.isGeneralInformationInputVisible" class="tw-col-span-4">
      <nc-input v-model="leadFormData.name" label="Name" placeholder="Name" :is-required="true" />
    </div>
    <div v-if="inputs.isGeneralInformationInputVisible" class="tw-col-span-4">
      <nc-input v-model="leadFormData.surname" label="Surname" placeholder="Surname" :is-required="true" />
    </div>
    <div class="tw-col-span-12">
      <client-only>
        <div class="tw-flex tw-flex-col">
          <lazy-library-text
            :text="`When would be your ideal time for a property viewing? We'll confirm availability for you.`"
            tag="span"
            type="input-label"
          ></lazy-library-text>
          <v-date-picker
            v-model="leadFormData.viewing_lead_date"
            mode="dateTime"
            :masks="masks"
            :valid-hours="[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]"
            is24hr
          >
          </v-date-picker>
        </div>
      </client-only>
    </div>
    <div class="tw-col-span-12" style="margin-bottom: -20px">
      <nc-input
        v-model="leadFormData.comment"
        label="Your message"
        :is-required="true"
        placeholder="Your message"
        type="textarea"
        :max-length="255"
      ></nc-input>
    </div>
    <div v-if="inputs.isEmailInputVisible" class="tw-col-span-12">
      <nc-input v-model="leadFormData.email" label="Email" placeholder="Email" :is-required="true" />
    </div>
    <div v-if="inputs.isPhoneInputVisible" class="tw-col-span-12">
      <nc-input
        v-model="leadFormData.mobile_phone"
        label="Mobile Phone"
        placeholder="Mobile Phone"
        :is-required="true"
      />
    </div>
    <div v-if="inputs.isAddressInputVisible" class="tw-col-span-12">
      <postcode-searcher v-model="fullAddress" />
    </div>
    <div class="tw-col-span-12">
      <nc-select
        v-model="leadFormData.i_have_a_property_to_sell"
        value-field="value"
        label-field="display"
        label="I have a property to sell"
        :is-required="true"
        :options="salesQuestionOptions"
        :is-please-select-active="true"
      />
    </div>
    <div class="tw-col-span-12">
      <nc-select
        v-model="leadFormData.i_have_a_property_to_let"
        value-field="value"
        label-field="display"
        label="I have a property to let"
        :is-required="true"
        :options="lettingsQuestionOptions"
        :is-please-select-active="true"
      />
    </div>

    <div class="tw-col-span-12 tw-flex tw-w-full tw-flex-row tw-items-center">
      <span class="nc-text tw-me-3"> I would also like: </span>
      <div>
        <nc-checkbox v-model="leadFormData.my_property_valued" label="My property valued" />
      </div>
    </div>
    <div class="tw-col-span-12 tw-grid tw-grid-cols-12 tw-justify-end">
      <div class="tw-col-span-12 tw-flex tw-items-center tw-justify-end">
        <div
          style="font-size: 16px; font-weight: normal; color: var('--dark-brown')"
          class="nc-text tw-me-4 tw-cursor-pointer"
          @click="onCancelled()"
        >
          Cancel
        </div>
        <div style="min-width: 120px">
          <nc-button label="Send" @on-click="onSend()" />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isLoading"><loader /></div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapActions, mapState } from 'pinia';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import { SalutationsConstants } from '~/units/contacts/constants/salutations.constants';
import { addDayToDate, backendDateFormat } from '~/units/core/providers/date.provider';
import { PFilterSearchType } from '~/units/properties/types';

import type { PropertyItemComponent } from '~/units/properties/types';
import PostcodeSearcher from '~/components/common/address/PostcodeSearcher.vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { useContactsStore } from '~/units/contacts/store';
import CommunicationMethodsConstants from '~/units/core/constants/communication.methods';
import { isTrimmedValueFilled, isValueFilled } from '~/units/core/providers';
import Loader from '~/components/common/Loader.vue';
import { LeadLangs } from '~/units/leads/langs';
import { useApplicantsStore } from '~/units/applicants/store';
import { useLeadsStore } from '~/units/leads/store';
import TransactionTypesConstants from '~/constants/transactionTypes.constants';

/* import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css'; */

export default defineNuxtComponent({
  name: 'SendLead',
  components: {
    Loader,
    NcButton,
    NcCheckbox,
    PostcodeSearcher,
    NcSelect,
    NcInput,
    /* DatePicker, */
  },

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },
  },

  data() {
    return {
      leadFormData: {
        salutation_id: 2,
        name: '',
        surname: '',

        viewing_lead_date: backendDateFormat(
          addDayToDate(new Date(), 1).set('minute', 0).set('seconds', 0).set('milliseconds', 0),
          true,
        ),

        comment: '',
        email: '',
        mobile_phone: '',
        i_have_a_property_to_sell: undefined,
        i_have_a_property_to_let: undefined,

        my_property_valued: false,
        i_would_like: ['to-view-a-property'],
      },

      fullAddress: null,

      masks: {
        input: 'DD/MM/YYYY',
      },

      salesQuestionOptions: [
        {
          id: 1,
          display: 'No',
          value: 'no',
        },
        {
          id: 2,
          display: 'Yes, it is not yet on the market',
          value: 'yes-it-is-not-yet-on-the-market',
        },
        {
          id: 3,
          display: 'Yes, it is on the market already',
          value: 'yes-it-is-on-the-market-already',
        },
        {
          id: 4,
          display: 'Yes, it is under offer',
          value: 'yes-it-is-under-offer',
        },
        {
          id: 5,
          display: 'Yes, it is already exchanged',
          value: 'yes-it-is-already-exchanged',
        },
      ],
      lettingsQuestionOptions: [
        {
          id: 1,
          display: 'No',
          value: 'no',
        },
        {
          id: 2,
          display: 'Not yet, I intend to buy to let',
          value: 'not-yet-i-intend-to-buy-to-let',
        },
        {
          id: 3,
          display: 'Yes, it is available to let now',
          value: 'yes-it-is-available-to-let-now',
        },
        {
          id: 4,
          display: 'Yes, it will be available to let soon',
          value: 'yes-it-will-be-available-to-let-soon',
        },
        {
          id: 5,
          display: 'Yes, it is currently occupied',
          value: 'yes-it-is-currently-occupied',
        },
      ],

      inputs: {
        isAddressInputVisible: true,
        isEmailInputVisible: true,
        isPhoneInputVisible: true,
        isGeneralInformationInputVisible: true,
      },

      validationControls: {
        email: true,
        mobile_phone: true,
      },

      isLoading: false,

      lang: 'en',
    };
  },

  async mounted() {
    this.isLoading = true;
    const contactsStore = useContactsStore();
    await contactsStore.fetchContactBySession();
    this.parseSelectContactData();
    this.isLoading = false;
  },

  methods: {
    ...mapActions(useContactsStore, ['getPreferredCommunicationInfoValue']),

    ...mapActions(useLeadsStore, ['sendLead']),

    async onSend() {
      try {
        const applicantsStore = useApplicantsStore();
        const lang = this.lang as 'en';

        if (this.leadFormData.i_would_like && this.leadFormData.i_would_like.length === 0) {
          throw LeadLangs.REQUIRED_I_WOULD_LIKE[lang];
        }

        if (!isTrimmedValueFilled(this.leadFormData.name)) {
          this.inputs.isGeneralInformationInputVisible = true;
          throw 'Name is required';
        }

        if (this.leadFormData.name.trim().length < 3) {
          this.inputs.isGeneralInformationInputVisible = true;
          throw 'Name must be at least 3 characters';
        }

        if (!isTrimmedValueFilled(this.leadFormData.surname)) {
          this.inputs.isGeneralInformationInputVisible = true;
          throw 'Surname is required';
        }

        if (this.leadFormData.surname.trim().length < 3) {
          this.inputs.isGeneralInformationInputVisible = true;
          throw 'Surname must be at least 3 characters';
        }

        if (!isValueFilled(this.leadFormData.comment)) {
          throw 'Your message is required';
        }

        if (this.leadFormData.comment.trim().length < 3) {
          throw 'Your message must be at least 3 characters';
        }

        if (this.leadFormData.comment.trim().length > 255) {
          throw 'Your message may not be greater than 255 characters.';
        }

        if (!isTrimmedValueFilled(this.leadFormData.email)) {
          this.inputs.isEmailInputVisible = true;
          throw 'Email is required';
        }

        if (!this.validationControls.email) {
          this.inputs.isEmailInputVisible = true;
          throw 'Please enter a valid email address.';
        }

        if (!isTrimmedValueFilled(this.leadFormData.mobile_phone)) {
          this.inputs.isEmailInputVisible = true;
          throw 'Mobile phone is required';
        }

        if (!this.validationControls.mobile_phone) {
          this.inputs.isPhoneInputVisible = true;
          throw 'Please enter a valid mobile phone number.';
        }

        if (this.leadFormData.mobile_phone.trim().length < 9) {
          this.inputs.isPhoneInputVisible = true;
          throw 'Mobile phone must be at least 9 characters';
        }

        if (!isValueFilled(this.fullAddress)) {
          this.inputs.isAddressInputVisible = true;
          throw 'Address is required';
        }

        if (!isTrimmedValueFilled(this.fullAddress?.display_name)) {
          this.inputs.isAddressInputVisible = true;
          throw 'Address must be selected';
        }

        if (!isValueFilled(this.leadFormData.i_have_a_property_to_sell)) {
          throw '"I have a property to sell" field is required';
        }

        if (!isValueFilled(this.leadFormData.i_have_a_property_to_let)) {
          throw '"I have a property to let" field is required';
        }

        const payload = {
          ...this.leadFormData,
          my_property_valued: this.leadFormData.my_property_valued ? 'yes' : 'no',
          contact_address: this.fullAddress,
          viewing_lead_date: backendDateFormat(this.leadFormData.viewing_lead_date, true),

          property_address: this.property.full_address,
          url: window.location.href,
          transaction_type_id: this.property.transaction_type_id,
          property_profile_id: this.property.property_profile_id,
          price: this.property.price.numberPrice,
          bedrooms: this.property.bedroom,
          confirm: 'confirmed',
          branch_id: this.property.branch_id,
        };

        const contactApplicantId = applicantsStore.getContactApplicantIdByType(
          this.property.transaction_type_id === TransactionTypesConstants.SALES
            ? PFilterSearchType.SALES
            : PFilterSearchType.LETTINGS,
        );
        if (contactApplicantId) {
          payload.contact_applicant_id = contactApplicantId;
        }

        if (!this.leadFormData.i_would_like.includes('to-view-a-property')) {
          delete payload.viewing_lead_date;
        }

        const result = await this.sendLead(payload);
        if (result) {
          this.$emit('onSuccess');
        }
      } catch (e: any) {
        if (e && e.apiErrorMessage) {
          if (e.apiErrorMessage === 'The viewing lead date must be a date after now.') {
            this.warningMessage('Please choose ideal viewing time in the future');
          } else {
            this.warningMessage(e.apiErrorMessage);
          }
        } else {
          this.warningMessage(e);
        }
      }
    },

    onCancelled() {
      this.$emit('close');
    },

    parseSelectContactData() {
      if (this.contact) {
        const email =
          this.getPreferredCommunicationInfoValue(
            this.contact.communication_infos,
            CommunicationMethodsConstants.EMAIL,
          ) || '';
        const mobilePhone =
          this.getPreferredCommunicationInfoValue(
            this.contact.communication_infos,
            CommunicationMethodsConstants.MOBILE_PHONE_NUMBER,
          ) || '';

        this.leadFormData.salutation_id = this.contact.salutation_id;
        this.leadFormData.name = this.contact.name;
        this.leadFormData.surname = this.contact.surname;
        this.leadFormData.email = email;
        this.leadFormData.mobile_phone = mobilePhone;

        if (this.contact.salutation_id && this.contact.name && this.contact.surname) {
          this.inputs.isGeneralInformationInputVisible = false;
        }

        if (isTrimmedValueFilled(email)) {
          this.inputs.isEmailInputVisible = false;
        }

        if (isTrimmedValueFilled(mobilePhone)) {
          this.inputs.isPhoneInputVisible = false;
        }

        const addresses = this.contact.contact_addresses;
        if (addresses && addresses.length > 0 && addresses[0].display_name) {
          [this.fullAddress] = addresses;
          if (this.fullAddress) {
            this.fullAddress = {
              ...this.fullAddress,
              country_id: 1,
            };
            this.inputs.isAddressInputVisible = false;
          }
        }
      }
    },
  },

  computed: {
    ...mapState(useContactsStore, ['contact']),
    ...mapState(useApplicantsStore, ['applicant']),

    salutations() {
      return SalutationsConstants.salutations;
    },
  },
});
</script>

<style scoped>
.revert-with-field {
  width: revert;
}
.DatePicker {
  display: block;
  width: 100%;
  color: #000;
  background-color: var(--nc-secondary-bg-color);
  padding: 1.1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  -webkit-box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
  box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
  -webkit-transition:
    background-color 0.3s,
    color 0.3s ease-in;
  transition:
    background-color 0.3s,
    color 0.3s ease-in;
}
.DatePicker > span > input {
  background: #f8f8f8;
}
.custom-position {
  position: relative;
  top: -20px;
  left: 10px;
}
.picker-label {
  color: #8b8b8b;
  margin: 0 0 0.5rem;
  font-size: 0.75rem;
  line-height: 20px;
  font-weight: 500;
}
</style>
