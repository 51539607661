import { PFilterFurnishing, PFilterInclusionBase, PFilterSearchType } from '~/units/properties/types';

interface CriteriaObject {
  value: null | number;
  display: string;
}

export default class CriteriaPreferencesConstants {
  static defaultMile() {
    return {
      distance: 0.25,
      location: '',
      lat: 0,
      lng: 0,
    };
  }

  static miles = [
    { value: 0.0, display: '+0 Miles' },
    { value: 0.25, display: '+1/4 Miles' },
    { value: 0.5, display: '+1/2 Miles' },
    { value: 1.0, display: '+1 Miles' },
    { value: 3.0, display: '+3 Miles' },
    { value: 5.0, display: '+5 Miles' },
    { value: 10.0, display: '+10 Miles' },
    { value: 15.0, display: '+15 Miles' },
    { value: 20.0, display: '+20 Miles' },
    { value: 30.0, display: '+30 Miles' },
    { value: 40.0, display: '+40 Miles' },
  ];

  static preferences = [
    { value: 1, display: '1' },
    { value: 2, display: '2' },
    { value: 3, display: '3' },
    { value: 4, display: '4' },
    { value: 5, display: '5' },
  ];

  static salesPrices = [
    {
      value: 50000,
      display: '£50,000',
    },
    {
      value: 60000,
      display: '£60,000',
    },
    {
      value: 70000,
      display: '£70,000',
    },
    {
      value: 80000,
      display: '£80,000',
    },
    {
      value: 90000,
      display: '£90,000',
    },
    {
      value: 100000,
      display: '£100,000',
    },
    {
      value: 110000,
      display: '£110,000',
    },
    {
      value: 120000,
      display: '£120,000',
    },
    {
      value: 125000,
      display: '£125,000',
    },
    {
      value: 130000,
      display: '£130,000',
    },
    {
      value: 140000,
      display: '£140,000',
    },
    {
      value: 150000,
      display: '£150,000',
    },
    {
      value: 160000,
      display: '£160,000',
    },
    {
      value: 170000,
      display: '£170,000',
    },
    {
      value: 175000,
      display: '£175,000',
    },
    {
      value: 180000,
      display: '£180,000',
    },
    {
      value: 190000,
      display: '£190,000',
    },
    {
      value: 200000,
      display: '£200,000',
    },
    {
      value: 210000,
      display: '£210,000',
    },
    {
      value: 220000,
      display: '£220,000',
    },
    {
      value: 230000,
      display: '£230,000',
    },
    {
      value: 240000,
      display: '£240,000',
    },
    {
      value: 250000,
      display: '£250,000',
    },
    {
      value: 260000,
      display: '£260,000',
    },
    {
      value: 270000,
      display: '£270,000',
    },
    {
      value: 280000,
      display: '£280,000',
    },
    {
      value: 290000,
      display: '£290,000',
    },
    {
      value: 300000,
      display: '£300,000',
    },
    {
      value: 325000,
      display: '£325,000',
    },
    {
      value: 350000,
      display: '£350,000',
    },
    {
      value: 375000,
      display: '£375,000',
    },
    {
      value: 400000,
      display: '£400,000',
    },
    {
      value: 425000,
      display: '£425,000',
    },
    {
      value: 450000,
      display: '£450,000',
    },
    {
      value: 475000,
      display: '£475,000',
    },
    {
      value: 500000,
      display: '£500,000',
    },
    {
      value: 550000,
      display: '£550,000',
    },
    {
      value: 600000,
      display: '£600,000',
    },
    {
      value: 650000,
      display: '£650,000',
    },
    {
      value: 700000,
      display: '£700,000',
    },
    {
      value: 800000,
      display: '£800,000',
    },
    {
      value: 900000,
      display: '£900,000',
    },
    {
      value: 1000000,
      display: '£1,000,000',
    },
    {
      value: 1250000,
      display: '£1,250,000',
    },
    {
      value: 1500000,
      display: '£1,500,000',
    },
    {
      value: 1750000,
      display: '£1,750,000',
    },
    {
      value: 2000000,
      display: '£2,000,000',
    },
    {
      value: 2500000,
      display: '£2,500,000',
    },
    {
      value: 3000000,
      display: '£3,000,000',
    },
    {
      value: 4000000,
      display: '£4,000,000',
    },
    {
      value: 5000000,
      display: '£5,000,000',
    },
    {
      value: 7500000,
      display: '£7,500,000',
    },
    {
      value: 10000000,
      display: '£10,000,000',
    },
    {
      value: 15000000,
      display: '£15,000,000',
    },
    {
      value: 20000000,
      display: '£20,000,000',
    },
  ];

  static inclusionFilterOptions = [
    {
      title: 'Include',
      value: PFilterInclusionBase.INCLUDE,
    },
    {
      title: 'Exclude',
      value: PFilterInclusionBase.EXCLUDE,
    },
    {
      title: 'Show Only',
      value: PFilterInclusionBase.SHOW_ONLY,
    },
  ];

  static furnishingTypes = [
    {
      title: 'Any',
      value: PFilterFurnishing.ANY,
    },
    {
      title: 'Furnished',
      value: PFilterFurnishing.FURNISHED,
    },
    {
      title: 'Part Furnished',
      value: PFilterFurnishing.PART_FURNISHED,
    },
    {
      title: 'Unfurnished',
      value: PFilterFurnishing.UNFURNISHED,
    },
  ];

  static gardenTypes = [
    {
      property_profile_garden_id: 1,
      name: 'Back',
    },
    {
      property_profile_garden_id: 2,
      name: 'Communal',
    },
    {
      property_profile_garden_id: 3,
      name: 'Enclosed',
    },
    {
      property_profile_garden_id: 4,
      name: 'Front',
    },
    {
      property_profile_garden_id: 5,
      name: 'Patio',
    },
    {
      property_profile_garden_id: 6,
      name: 'Private',
    },
    {
      property_profile_garden_id: 7,
      name: 'Rear',
    },
    {
      property_profile_garden_id: 8,
      name: 'Terrace',
    },
  ];

  static lettingsPrices = [
    {
      value: 100,
      display: '£100',
    },
    {
      value: 150,
      display: '£150',
    },
    {
      value: 200,
      display: '£200',
    },
    {
      value: 250,
      display: '£250',
    },
    {
      value: 300,
      display: '£300',
    },
    {
      value: 350,
      display: '£350',
    },
    {
      value: 400,
      display: '£400',
    },
    {
      value: 450,
      display: '£450',
    },
    {
      value: 500,
      display: '£500',
    },
    {
      value: 600,
      display: '£600',
    },
    {
      value: 700,
      display: '£700',
    },
    {
      value: 800,
      display: '£800',
    },
    {
      value: 900,
      display: '£900',
    },
    {
      value: 1000,
      display: '£1,000',
    },
    {
      value: 1100,
      display: '£1,100',
    },
    {
      value: 1200,
      display: '£1,200',
    },
    {
      value: 1250,
      display: '£1,250',
    },
    {
      value: 1300,
      display: '£1,300',
    },
    {
      value: 1400,
      display: '£1,400',
    },
    {
      value: 1500,
      display: '£1,500',
    },
    {
      value: 1750,
      display: '£1,750',
    },
    {
      value: 2000,
      display: '£2,000',
    },
    {
      value: 2250,
      display: '£2,250',
    },
    {
      value: 2500,
      display: '£2,500',
    },
    {
      value: 2750,
      display: '£2,750',
    },
    {
      value: 3000,
      display: '£3,000',
    },
    {
      value: 3500,
      display: '£3,500',
    },
    {
      value: 4000,
      display: '£4,000',
    },
    {
      value: 4500,
      display: '£4,500',
    },
    {
      value: 5000,
      display: '£5,000',
    },
    {
      value: 5500,
      display: '£5,500',
    },
    {
      value: 6000,
      display: '£6,000',
    },
    {
      value: 6500,
      display: '£6,500',
    },
    {
      value: 7000,
      display: '£7,000',
    },
    {
      value: 8000,
      display: '£8,000',
    },
    {
      value: 9000,
      display: '£9,000',
    },
    {
      value: 10000,
      display: '£10,000',
    },
    {
      value: 12500,
      display: '£12,500',
    },
    {
      value: 15000,
      display: '£15,000',
    },
    {
      value: 17500,
      display: '£17,500',
    },
    {
      value: 20000,
      display: '£20,000',
    },
    {
      value: 25000,
      display: '£25,000',
    },
    {
      value: 30000,
      display: '£30,000',
    },
    {
      value: 35000,
      display: '£35,000',
    },
    {
      value: 40000,
      display: '£40,000',
    },
  ];

  static bedroom = [
    {
      name: 'Studio',
      value: 0,
    },
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
    {
      name: '4',
      value: '4',
    },
    {
      name: '5',
      value: '5',
    },
    {
      name: '6',
      value: '6',
    },
  ];

  static typeOfValuation = [
    {
      name: 'Sales Valuation',
      value: 1,
    },
    {
      name: 'Lettings Valuation',
      value: 2,
    },
  ];

  static salutations = [
    {
      name: 'Dr',
      value: 1,
    },
    {
      name: 'Miss',
      value: 2,
    },
    {
      name: 'Mr',
      value: 3,
    },
    {
      name: 'Mrs',
      value: 4,
    },
    {
      name: 'Ms',
      value: 5,
    },
    {
      name: 'Unknown',
      value: 16,
    },
  ];

  static getPreferences = (level = 'min') => {
    return [
      { value: null, display: `No ${level}` },
      { value: 0, display: 0 },
      ...CriteriaPreferencesConstants.preferences,
    ];
  };

  static getPrices = (
    type: PFilterSearchType = PFilterSearchType.SALES,
    level: 'min' | 'max' = 'min',
    isSearchFilterArea = false,
  ) => {
    let values: Array<CriteriaObject> = [];
    if (!isSearchFilterArea) {
      values = [{ value: 0, display: 'Please select' }];
    }

    const priceList = (() => {
      if (type === PFilterSearchType.LETTINGS) {
        return this.lettingsPrices;
      }
      return this.salesPrices;
    })();

    const prices = priceList.map((price: { display: string; value: number }) => ({
      value: price.value,
      display: `${price.display} ${type !== PFilterSearchType.SALES ? 'PCM' : ''}`,
    }));

    return [...values, { value: null, display: `No ${level} price` }, ...prices];
  };
}
