export class SalutationsConstants {
  static salutations = [
    {
      salutation_id: 2,
      name: 'Miss',
      is_dot: false,
      is_hidden: false,
    },
    {
      salutation_id: 3,
      name: 'Mr',
      is_dot: true,
      is_hidden: false,
    },
    {
      salutation_id: 5,
      name: 'Ms',
      is_dot: true,
      is_hidden: false,
    },
    {
      salutation_id: 1,
      name: 'Dr',
      is_dot: true,
      is_hidden: false,
    },
    {
      salutation_id: 4,
      name: 'Mrs',
      is_dot: true,
      is_hidden: false,
    },
    {
      salutation_id: 16,
      name: 'Unknown',
      is_dot: false,
      is_hidden: true,
    },
  ];
}
