<template>
  <popup
    v-if="applicantProfilesPopupConfig.isActive"
    v-model:config="applicantProfilesPopupConfig"
    custom-width="500px"
    custom-background-color="rgba(213, 218, 222, 0.99)"
    @close="didPopupClose"
  >
    <template #content>
      <applicant-profiles
        :search-type="null"
        @on-selected-new-applicant="onSelectedNewApplicant"
        :is-visible-update-button="false"
        @did-applicants-load="didApplicantsLoad"
      />
    </template>
  </popup>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
import ApplicantProfiles from '~/components/common/properties/ApplicantProfiles.vue';
import { ROUTES } from '~/constants/ui.constants';
import { useContactsStore } from '~/units/contacts/store';
import { mapState } from 'pinia';
import { useApplicantsStore } from '~/units/applicants/store';

export default defineNuxtComponent({
  name: 'LibraryHeadsUpAlertsUpdateProfileEntry',
  components: { ApplicantProfiles, Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      applicantProfilesPopupConfig: {
        isActive: true,
      },
    };
  },

  setup() {
    const contactsStore = useContactsStore();
    if (!contactsStore.isLogged) {
      navigateTo({
        path: ROUTES.REGISTER,
      });
    }
  },

  methods: {
    onSelectedNewApplicant(applicantKey) {
      this.redirectTo({
        path: ROUTES.APPLICANT_UPDATE.replace(':applicantKey', applicantKey),
      });
    },

    didApplicantsLoad(applicants) {
      if (applicants && applicants.length === 0) {
        this.redirectTo({
          path: ROUTES.REGISTER,
        });
      } else if (applicants && applicants.length === 1) {
        const applicant = applicants[0];
        if (applicant) {
          try {
            this.redirectTo({
              path: ROUTES.APPLICANT_UPDATE.replace(':applicantKey', applicant.applicant_key),
            });
          } catch (e) {
            console.log(e);
          }
        }
      }
    },

    didPopupClose() {
      this.redirectTo({
        path: ROUTES.REGISTER,
      });
    },
  },

  computed: {
    ...mapState(useApplicantsStore, ['applicants', 'isApplicantsFetched']),
  },
});
</script>
