<template>
  <div
    id="register-property-criteria-area"
    class="tw-mb-5 tw-hidden tw-w-full tw-border-b tw-border-[var(--default-border-color)] lg:tw-flex lg:tw-flex-row lg:tw-justify-center"
  >
    <lazy-library-title tag="h3" :text="title" class="tw-py-6"></lazy-library-title>
  </div>
  <div class="register-property tw-mt-16 tw-grid tw-grid-cols-12 tw-pt-2">
    <div class="tw-col-span-12 tw-flex tw-flex-col">
      <locations
        v-model="locationCriteria"
        v-model:locations="filters.locations"
        :is-multiple-location="true"
        :loading-circle-size="20"
        :version="theme === 'theme2' || theme === 'theme3' ? undefined : 'v2'"
      />
      <lazy-library-text
        v-if="theme === 'theme2' || theme === 'theme3'"
        type="small"
        class="tw-mt-3"
        text="Choose as many areas as you’d like. You can use Towns, Villages,
        Postcodes, Schools and even Pubs. Type the name of something you feel is
        at the centre of your search area, choose the radius, then press the
        plus button to add that location to your search profile. You must have
        at least one location selected to continue. Give it a try!"
      >
      </lazy-library-text>
      <small v-else class="tw-mt-3">
        Choose as many areas as you’d like. You can use Towns, Villages, Postcodes, Schools and even Pubs. Type the name
        of something you feel is at the centre of your search area, choose the radius, then press the plus button to add
        that location to your search profile. You must have at least one location selected to continue. Give it a try!
      </small>
    </div>
    <div class="tw-col-span-12 tw-mb-2 tw-mt-4">
      <featured-property-type-and-styles v-model:filters="filters" :is-wrapping-enabled="true" :version="version" />
    </div>
    <div class="tw-col-span-12 tw-my-4 tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.min_price"
          value-field="value"
          label-field="display"
          label="Min Price"
          :options="minPrices"
          :is-required="true"
          :is-please-select-active="false"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.max_price"
          value-field="value"
          label-field="display"
          label="Max Price"
          :options="maxPrices"
          :is-required="true"
          :is-please-select-active="false"
        />
      </div>
    </div>
    <div class="tw-col-span-12 tw-my-2 tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.min_bedroom"
          value-field="value"
          label-field="name"
          label="Min Bedroom"
          :options="minBedroomCounts"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.max_bedroom"
          value-field="value"
          label-field="name"
          label="Max Bedroom"
          :options="maxBedroomCounts"
        />
      </div>
    </div>
    <div class="tw-col-span-12 tw-my-2 tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.min_bathroom"
          value-field="value"
          label-field="display"
          label="Min Bathroom"
          :options="minPreferences"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.max_bathroom"
          value-field="value"
          label-field="display"
          label="Max Bathroom"
          :options="maxPreferences"
        />
      </div>
    </div>
    <div class="tw-col-span-12 tw-my-2 tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.min_reception"
          value-field="value"
          label-field="display"
          label="Min Reception"
          :options="minPreferences"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.max_reception"
          value-field="value"
          label-field="display"
          label="Max Reception"
          :options="maxPreferences"
        />
      </div>
    </div>
    <div class="tw-col-span-12 tw-mt-2 tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-12">
        <lazy-library-title
          class="more-options-title tw-pb-3"
          tag="h4"
          text="More Options"
          style="border-bottom: 1px solid var(--default-border-color)"
        ></lazy-library-title>
      </div>
    </div>
    <div
      v-if="searchType === searchTypes.LETTINGS"
      class="tw-col-span-12 tw-mt-5 tw-grid tw-grid-cols-12 tw-items-center tw-gap-2"
      style="row-gap: 20px"
    >
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.short_let"
          value-field="value"
          label-field="title"
          label="Short Let"
          :options="inclusionFilterOptions"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.house_flat_share"
          value-field="value"
          label-field="title"
          label="House Flat Share"
          :options="inclusionFilterOptions"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.pets_allowed"
          value-field="value"
          label-field="title"
          label="Pets Allowed"
          :options="inclusionFilterOptions"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.bills_included"
          value-field="value"
          label-field="title"
          label="Bills Included"
          :options="inclusionFilterOptions"
        />
      </div>
    </div>
    <div
      class="tw-col-span-12 tw-grid tw-grid-cols-12 tw-items-center tw-gap-2 tw-pt-5"
      style="row-gap: 20px"
      :class="searchType === searchTypes.SALES ? 'tw-mb-5' : ''"
    >
      <div v-if="searchType === searchTypes.LETTINGS" class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.furnishing"
          value-field="value"
          label-field="title"
          label="Furnishing"
          :options="furnishingOptions"
        />
      </div>
      <div v-if="searchType === searchTypes.SALES" class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.new_homes"
          value-field="value"
          label-field="title"
          label="New Homes"
          :options="inclusionFilterOptions"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.retirement_homes"
          value-field="value"
          label-field="title"
          label="Retirement Homes"
          :options="inclusionFilterOptions"
        />
      </div>
      <div v-if="searchType === searchTypes.SALES" class="tw-col-span-12 lg:tw-col-span-6">
        <nc-select
          v-model="filters.retirement_homes"
          value-field="value"
          label-field="title"
          label="Shared Ownership"
          :options="inclusionFilterOptions"
        />
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6"></div>
    </div>
    <div class="tw-col-span-12 tw-mt-2" style="">
      <lazy-library-title
        class="pb-3 must-have-title"
        tag="h4"
        text="Must Haves"
        style="border-bottom: 1px solid var(--default-border-color)"
      ></lazy-library-title>
    </div>
    <div class="tw-col-span-12 tw-mt-4">
      <nc-checkbox
        v-model="filters.must_haves"
        :multiple-checkbox-list="[
          {
            value: mustHavesOptions.GARDEN,
            label: 'Garden',
          },
          {
            value: mustHavesOptions.PARKING,
            label: 'Parking',
          },
        ]"
        :mode="'multiple'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import {
  PFilterBillsIncludes,
  PFilterFurnishing,
  PFilterHouseFlatShare,
  PFilterMustHaves,
  PFilterNewHomes,
  PFilterPetsAllowed,
  PFilterRetirementHomes,
  PFilterSearchType,
  PFilterSharedOwnership,
  PFilterShortLet,
} from '~/units/properties/types';

import type { PropertiesFilter } from '~/units/properties/types';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import { SalutationsConstants } from '~/units/contacts/constants/salutations.constants';
import PostcodeSearcher from '~/components/common/address/PostcodeSearcher.vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import FeaturedPropertyTypeAndStyles from '~/components/common/properties/FeaturedPropertyTypeAndStyles.vue';
import CriteriaPreferencesConstants from '~/units/properties/constants/criteriaPreferences.constants';
import { ComponentMode } from '~/units/core/types';
import { useApplicantsStore } from '~/units/applicants/store';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'RegisterPropertyCriteriaArea',
  components: {
    FeaturedPropertyTypeAndStyles,
    NcButton,
    NcCheckbox,
    PostcodeSearcher,
    NcSelect,
    NcInput,
    MultipleInputGroup,
    Locations,
  },

  props: {
    searchType: {
      required: true,
      type: String as PropType<PFilterSearchType>,
    },

    mode: {
      required: true,
      type: String as PropType<ComponentMode>,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },

    title: {
      required: false,
      type: String,
      default: 'Property Preference',
    },
  },

  mixins: [ComponentMixin],

  data(): {
    locationCriteria: {
      keyword: string;
      mile: number;
    };
    filters: PropertiesFilter;
    searchTypes: any;
  } {
    return {
      locationCriteria: {
        keyword: '',
        mile: 0.25,
      },

      filters: {
        locations: [],
        min_price: 0,
        max_price: 0,

        min_bedroom: null,
        max_bedroom: null,

        min_bathroom: null,
        max_bathroom: null,

        min_reception: null,
        max_reception: null,

        new_homes: PFilterNewHomes.INCLUDE,
        shared_ownership: PFilterSharedOwnership.INCLUDE,

        must_haves: [],
        retirement_homes: PFilterRetirementHomes.INCLUDE,
        property_types: [],

        // lettings
        short_let: PFilterShortLet.INCLUDE,
        house_flat_share: PFilterHouseFlatShare.INCLUDE,
        pets_allowed: PFilterPetsAllowed.INCLUDE,
        bills_included: PFilterBillsIncludes.INCLUDE,
        furnishing: PFilterFurnishing.ANY,
      },

      searchTypes: PFilterSearchType,
    };
  },

  computed: {
    ...mapState(useApplicantsStore, ['applicantUpdateView']),
    ...mapState(useWebsiteStore, ['theme']),

    salutations() {
      return SalutationsConstants.salutations;
    },

    minPrices() {
      return CriteriaPreferencesConstants.getPrices(this.searchType, 'min', false);
    },

    maxPrices() {
      return CriteriaPreferencesConstants.getPrices(this.searchType, 'max', false);
    },

    minBedroomCounts() {
      return [{ value: null, name: 'No Min' }, ...CriteriaPreferencesConstants.bedroom];
    },

    maxBedroomCounts() {
      return [{ value: null, name: 'No Max' }, ...CriteriaPreferencesConstants.bedroom];
    },

    minPreferences() {
      return CriteriaPreferencesConstants.getPreferences('min');
    },
    maxPreferences() {
      return CriteriaPreferencesConstants.getPreferences('max');
    },

    inclusionFilterOptions() {
      return CriteriaPreferencesConstants.inclusionFilterOptions;
    },

    furnishingOptions() {
      return CriteriaPreferencesConstants.furnishingTypes;
    },

    mustHavesOptions() {
      return PFilterMustHaves;
    },
  },

  mounted() {
    if (this.mode === ComponentMode.UPDATE && this.applicantUpdateView) {
      const criteria = this.applicantUpdateView?.criteria;
      const fields = [
        'min_price',
        'max_price',
        'min_bedroom',
        'max_bedroom',
        'max_bathroom',
        'min_bathroom',
        'min_reception',
        'max_reception',
        'locations',
        'must_haves',
        'property_types',
        'new_homes',
        'retirement_homes',
        'shared_ownership',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'furnishing',
      ];
      fields.forEach((field) => {
        this.filters[field] = criteria[field];
      });
    }
  },

  methods: {
    onSave() {},

    onCancelled() {},
  },
});
</script>

<style scoped></style>
