import { RequestMethod, RequestName, RequestProxy, useCaller } from '~/hydration/bridges';

export class ApplicantQuestionsService {
  fetch(type: 'tenant' | 'buyer') {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.FETCH_APPLICANT_QUESTIONS,
      `contact_questions/${type}`,
      RequestMethod.GET,
    );
  }
}

export class ApplicantsService {
  fetch(contactId: number) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.FETCH_APPLICANTS,
      `contact_applicants/applicants_of_contact/${contactId}`,
      RequestMethod.GET,
      {
        __order_by: '-created_at',
      },
    );
  }

  get(applicantKey: string) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.FETCH_APPLICANT,
      `contact_applicants/u/${applicantKey}`,
      RequestMethod.GET,
      {},
    );
  }

  create(body: any) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.CREATE_APPLICANT,
      `contact_applicants`,
      RequestMethod.POST,
      {},
      body,
    );
  }

  update(applicantId: number, body: any) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.UPDATE_APPLICANT,
      `contact_applicants/${applicantId}`,
      RequestMethod.PATCH,
      {},
      body,
    );
  }

  updateStatus(contactApplicantId: number, status: boolean) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.UPDATE_APPLICANT,
      `contact_applicants/status/${contactApplicantId}`,
      RequestMethod.PATCH,
      {},
      {
        status,
        contact_applicant_id: contactApplicantId,
      },
    );
  }
}
