<template>
  <div
    v-if="variant === 'v1'"
    class="tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-justify-around tw-border tw-py-6"
    :class="[isSelected ? 'nc-type-style-box-active' : '', boxContainerClass]"
    style="border-color: var(--c-border); border-radius: var(--rounded-input)"
    @click="toggle()"
  >
    <span>
      <span :class="`nc-icon-${item.icon}`" :style="`font-size: ${item.size}px!important;`"></span>
    </span>
    <span>
      <lazy-library-text :text="item.name"> </lazy-library-text>
    </span>
  </div>

  <div
    v-else-if="variant === 'v2'"
    class="input-group tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-justify-around tw-border tw-py-6"
    :class="[isSelected ? 'nc-type-style-box-active' : '', boxContainerClass]"
    style="border-color: var(--c-border); border-radius: var(--rounded-input)"
    @click="toggle()"
  >
    <span>
      <span :class="`nc-icon-${item.icon}`" :style="`font-size: ${item.size}px!important;`"></span>
    </span>
    <span>
      <p>{{ item.name }}</p>
    </span>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { PropType } from 'vue';
import { usePropertyTypeStyleStore } from '~/units/property_type_styles/store';
import type { PFilterPropertyTypes } from '~/units/properties/types';
import type { FeaturedTypeAndStylesComponent } from '~/units/property_type_styles/types';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'FeaturedPropertyTypeAndStyleBox',

  props: {
    selectedTypeStyles: {
      required: true,
      type: Array as PropType<PFilterPropertyTypes[]>,
    },

    item: {
      required: true,
      type: Object as PropType<FeaturedTypeAndStylesComponent>,
    },

    variant: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  methods: {
    toggle() {
      if (this.isSelected) {
        this.setRemoved();
      } else {
        this.setSelected();
      }
    },

    setSelected() {
      if (this.selectedTypeStylesModel) {
        const typeIndex = this.selectedTypeStylesModel.findIndex(
          (i) => i.property_type_id === this.item.property_type_id,
        );

        if (typeIndex >= 0) {
          // adds style into selected type
          this.selectedTypeStylesModel = this.selectedTypeStylesModel.map((item, index) => {
            if (index === typeIndex) {
              return {
                property_type_id: this.selectedTypeStylesModel[typeIndex].property_type_id,
                property_style_ids: [
                  ...this.selectedTypeStylesModel[typeIndex].property_style_ids,
                  ...this.item.styles,
                ],
              };
            }
            return item;
          });
        } else {
          // add new type

          this.selectedTypeStylesModel = [
            ...this.selectedTypeStylesModel,
            {
              property_type_id: this.item.property_type_id,
              property_style_ids: [...this.item.styles],
            },
          ];
        }
      }
    },

    setRemoved() {
      const typeIndex = this.selectedTypeStylesModel.findIndex(
        (i) => i.property_type_id === this.item.property_type_id,
      );

      if (typeIndex >= 0) {
        const propertyStyleIds = [...this.selectedTypeStylesModel[typeIndex].property_style_ids];
        this.item.styles.forEach((style) => {
          const styleIndex = propertyStyleIds.findIndex((i) => i === style);
          if (styleIndex >= 0) {
            propertyStyleIds.splice(styleIndex, 1);
          }
        });

        if (propertyStyleIds.length === 0) {
          this.selectedTypeStylesModel = this.selectedTypeStylesModel?.filter((item, index) => index !== typeIndex);
        } else {
          this.selectedTypeStylesModel = this.selectedTypeStylesModel.map((item, index) => {
            if (index === typeIndex) {
              return {
                property_type_id: this.selectedTypeStylesModel[typeIndex].property_type_id,
                property_style_ids: [...propertyStyleIds],
              };
            }
            return item;
          });
        }
      }
    },
  },

  emits: ['update:selectedTypeStyles'],

  computed: {
    ...mapState(usePropertyTypeStyleStore, ['propertyTypes', 'propertyStyles']),

    ...mapState(useWebsiteStore, ['theme']),

    boxContainerClass() {
      switch (this.theme) {
        case 'theme3':
          return 'tw-border tw-border-[#DDDDDD]';
        case 'theme2':
          return 'tw-border tw-border-[#aca39a]';
        default:
          return '';
      }
    },

    selectedTypeStylesModel: {
      get() {
        return this.selectedTypeStyles;
      },
      set(value: PFilterPropertyTypes[]) {
        this.$emit('update:selectedTypeStyles', value);
      },
    },

    isSelected() {
      if (this.selectedTypeStylesModel) {
        const type = this.selectedTypeStylesModel.find((i) => i.property_type_id === this.item.property_type_id);

        if (this.item.isJustType) {
          return !!type;
        }
        if (type) {
          let notSelected = 0;
          this.item.styles.forEach((i) => {
            if (!type.property_style_ids.includes(i)) {
              notSelected += 1;
            }
          });

          return notSelected === 0;
        }
      }

      return false;
    },
  },
});
</script>
