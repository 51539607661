<template>
  <div class="pm-property-gallery pm-active">
    <div class="gallery-header">
      <a
        v-if="activeMedia.isDownloadable"
        target="_blank"
        :href="activeMedia.background"
        class="pm-button has-icon tw-flex tw-flex-row tw-items-center tw-p-0"
        style="min-width: unset"
        download
      >
        <svg style="font-size: 32px" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-5 -5 24 24">
          <path
            fill="currentColor"
            d="m8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2"
          />
        </svg>
        <div class="ms-1" style="font-size: 18px">Download</div>
      </a>
      <div class="closeBtn n-cursor-pointer" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
          <path fill="currentColor" :d="icons.closeButton" />
        </svg>
      </div>
    </div>
    <div class="gallery-content">
      <figure>
        <img v-if="!activeMedia.mediaLink" :src="activeMedia.background" alt="*property photo" />
        <div v-else-if="activeMedia.isIframePlayable">
          <iframe
            :src="activeMedia.mediaLink"
            class="video__iframe"
            :class="{
              '!tw-w-[unset]': portraitVideo,
            }"
            title="video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div v-else-if="activeMedia.isVirtualTour">
          <a
            :href="activeMedia.mediaLink"
            target="_blank"
            rel="noopener noreferrer"
            class="virtual-tour__button mouse-click pm-fs-20 tw-flex tw-items-center"
          >
            <i class="pm pm-play pm-fs-18" aria-hidden="true"></i>
            <span class="pl-2">Virtual tour</span>
          </a>
        </div>
      </figure>
      <div class="counter">{{ activeMediaIndex + 1 }}/{{ countOfMedias }}</div>
      <div class="photo-next" @click="goNext()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-width="2" d="m9 6l6 6l-6 6" />
        </svg>
      </div>
      <div class="photo-prev" @click="goPrev()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-width="2" d="m15 6l-6 6l6 6" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FullScreenGallery',

  mixins: [ComponentMixin],

  props: {
    activeMedia: {
      required: true,
      type: Object,
    },
    countOfMedias: {
      required: true,
      type: Number,
    },
    activeMediaIndex: {
      required: true,
      type: Number,
    },
    portraitVideo: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateIndex(value: number) {
      this.$emit('update:activeMediaIndex', value);
    },
    goNext() {
      if (this.countOfMedias - 1 !== this.activeMediaIndex) {
        this.updateIndex(this.activeMediaIndex + 1);
      } else {
        this.updateIndex(0);
      }
    },
    goPrev() {
      if (this.activeMediaIndex === 0) {
        this.updateIndex(this.countOfMedias - 1);
      } else {
        this.updateIndex(this.activeMediaIndex - 1);
      }
    },

    close() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
.pm-property-gallery {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
  transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
}

.pm-property-gallery.pm-active {
  opacity: 1;
  visibility: visible;
  z-index: 10000;
  -webkit-transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in;
  transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in;
}

.pm-property-gallery .gallery-header {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 2rem 4rem;
}

.pm-property-gallery .gallery-header p.title {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
}

.pm-property-gallery .gallery-header .pm-button {
  color: #fff;
}

.pm-property-gallery .gallery-header .closeBtn {
  display: block;
  color: #fff;
  margin-left: auto;
  font-size: 1.25rem;
}

.pm-property-gallery .gallery-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.pm-property-gallery .gallery-content figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 65%;
  height: 75%;
  margin: 0 auto;
}

.pm-property-gallery .gallery-content figure img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  border-radius: 12px;
}

.pm-property-gallery .gallery-content .counter {
  position: absolute;
  bottom: 8%;
  left: 50%;
  display: block;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  letter-spacing: 3px;
}

@media (max-width: 991px) {
  .pm-property-gallery .gallery-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.pm-property-gallery .gallery-content .photo-next,
.pm-property-gallery .gallery-content .photo-prev {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 26px;
}

.pm-property-gallery .gallery-content .photo-next {
  right: 10%;
}

.pm-property-gallery .gallery-content .photo-prev {
  left: 10%;
}

.virtual-tour__button {
  border: 1px solid white;
  padding: 10px 50px;
  border-radius: 6px;
  color: white !important;
}

figure {
  width: unset !important;
  height: unset !important;
}

@media (min-width: 577px) {
  .video__iframe {
    width: 70vw;
    height: 500px;
  }
}

@media (max-width: 575px) {
  .video__iframe {
    height: 500px;
  }
}
</style>
