import { defineStore } from 'pinia';
import { SettingsService } from '~/units/settings/services';

export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      registrationEntryPopupText: `Ever missed out on the perfect property just because you heard about it too late, or the Estate Agent never told you about it as it was slightly outside of your criteria? Never miss out again by using our ‘Heads Up Property Alerts’.<br/><br/>Simply create your profile and our system will constantly give you the ‘Heads Up’ on suggested properties and price reductions that might specifically interest you.<br/><br/>You can update, refine or unsubscribe at any time so try it for yourself and be first to get the heads up on properties you might like.`,

      registrationText:
        'Ever missed out on the perfect property just because you heard about it too late, or the Estate Agent never told you about it as it was slightly outside of your criteria? Never miss out again by using our ‘Heads Up Property Alerts’. Simply create your profile and our system will constantly give you the ‘Heads Up’ on suggested properties and price reductions that might specifically interest you. You can update, refine or unsubscribe at any time so try it for yourself and be first to get the heads up on properties you might like.',
      updateRegistrationText:
        'Our Heads Up Property Alerts makes sure that you never miss out on the perfect property by giving you alerts on properties we think you might like but it is important for you to give us the right information in order for our systems to work effectively for you in the background. Therefore if your circumstances or search criteria has changed simply update your details and be sure to get the heads up on properties you might like.',
      isRegistrationTextFetched: false,
    };
  },
  actions: {
    async fetchRegistrationTextSetting() {
      try {
        // useless setting nobody uses it
        if (!this.isRegistrationTextFetched) {
          const settingsService = new SettingsService();

          const result = await settingsService.fetch(51);
          if (result && result.data) {
            this.registrationText = result.data.value;
          }
          this.isRegistrationTextFetched = true;
        }
      } catch (e) {}
    },
  },
  getters: {},
});
