<template>
  <div class="pm-lightbox pm-active">
    <figure>
      <img :src="item" alt="" />
    </figure>
    <div class="closeBtn n-cursor-pointer" @click="$emit('close')">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
        <path fill="currentColor" :d="icons.closeButton" />
      </svg>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FullScreenPopup',
  props: {
    item: {
      required: true,
      type: String,
    },
  },

  mixins: [ComponentMixin],
});
</script>

<style scoped>
.pm-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 99999 !important;
  -webkit-transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
  transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
}

.pm-lightbox.pm-active {
  opacity: 1;
  visibility: visible;
  z-index: 9999999 !important;
  -webkit-transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in;
  transition:
    opacity 0.3s,
    visibility 0s,
    z-index 0s ease-in;
}

.pm-lightbox .closeBtn {
  position: absolute;
  top: 3rem;
  right: 4rem;
  display: block;
  color: #fff;
  margin-left: auto;
  font-size: 1.875rem;
}

@media (max-width: 991px) {
  .pm-lightbox .closeBtn {
    top: 1rem;
    right: 2rem;
  }
}

.pm-lightbox figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 65%;
  height: 75%;
  margin: 0 auto;
}

.pm-lightbox figure img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  border-radius: 12px;
}
</style>
