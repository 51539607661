import { RequestMethod, RequestName, RequestProxy, useCaller } from '~/hydration/bridges';
import { ApiService } from '~/core/services/api.service';

export class LeadsServices extends ApiService {
  async sendLead(body = {}) {
    try {
      return await useCaller(
        RequestProxy.LS_AXON,
        RequestName.SEND_LEAD,
        'leads/enquiry',
        RequestMethod.POST,
        {},
        body,
      );
    } catch (e: any) {
      throw this.handleError(e);
    }
  }
}
