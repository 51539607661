<template>
  <div
    v-if="theme === 'theme3'"
    :class="isMobile ? 'tw-px-3' : 'property-filters-mega-menu tw-px-5'"
    :style="propertiesConfig.advancedSearchPopupStyle"
  >
    <lazy-library-container>
      <div class="row gy-0 gx-3 d-flex flex-row align-items-center page-padding container-large px-0">
        <div class="col-12 col-md-3">
          <multiple-input-group>
            <template #front-item>
              <div class="d-flex flex-column">
                <lazy-library-text type="input-label" class="no-uppercase" text="Bathroom"></lazy-library-text>
                <div
                  class="w-100 d-flex advanced-input flex-row align-items-center justify-content-center p-3 tw-bg-white"
                  style="border: 1px solid var(--default-border-color)"
                >
                  <lazy-library-text :text="bathroomLabel"></lazy-library-text>
                  <svg
                    class="filter-arrow"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="d-flex flex-row w-100 justify-content-around">
                <select v-model="filters.min_bathroom" style="border: unset !important">
                  <option
                    v-for="(minPreference, index) in minPreferences"
                    :key="`minBathroomPreference-${index}`"
                    :value="minPreference.value"
                  >
                    {{ minPreference.display }}
                  </option>
                </select>
                <div class="px-2">-</div>
                <select v-model="filters.max_bathroom" style="border: unset !important">
                  <option
                    v-for="(maxPreference, index) in maxPreferences"
                    :key="`maxBathroomPreference-${index}`"
                    :value="maxPreference.value"
                  >
                    {{ maxPreference.display }}
                  </option>
                </select>
              </div>
            </template>
          </multiple-input-group>
        </div>
        <div class="col-12 col-md-3">
          <multiple-input-group>
            <template #front-item>
              <div class="d-flex flex-column">
                <lazy-library-text type="input-label" class="no-uppercase" text="Reception"></lazy-library-text>
                <div
                  class="w-100 advanced-input d-flex flex-row align-items-center justify-content-center p-3 tw-bg-white"
                  style="border: 1px solid var(--default-border-color)"
                >
                  <lazy-library-text :text="receptionLabel"></lazy-library-text>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="d-flex flex-row w-100 justify-content-around">
                <select v-model="filters.min_reception" style="border: unset !important">
                  <option
                    v-for="(minPreference, index) in minPreferences"
                    :key="`minReceptionPreference-${index}`"
                    :value="minPreference.value"
                  >
                    {{ minPreference.display }}
                  </option>
                </select>
                <div class="px-2">-</div>
                <select v-model="filters.max_reception" style="border: unset !important">
                  <option
                    v-for="(maxPreference, index) in maxPreferences"
                    :key="`maxReceptionPreference-${index}`"
                    :value="maxPreference.value"
                  >
                    {{ maxPreference.display }}
                  </option>
                </select>
              </div>
            </template>
          </multiple-input-group>
        </div>
        <div class="col-12 col-md-3 h-100">
          <div class="d-flex flex-column justify-content-center h-100 mt-4">
            <nc-checkbox
              id="available-properties"
              v-model="isAvailableModel"
              :label="availablePropertiesLabel"
              :single-true-value="false"
              :single-false-value="true"
            />
          </div>
        </div>
        <div class="col-12 col-md-3 h-100 d-flex flex-column">
          <lazy-library-text class="no-uppercase" type="input-label" text="Show me Properties"></lazy-library-text>
          <nc-radio
            v-model="searchTypeFilter"
            :options="searchTypeFilters"
            @on-changed="onSearchTypeChanged"
          ></nc-radio>
        </div>
      </div>
      <div
        v-if="searchType === searchTypes.LETTINGS"
        class="row gy-0 gx-3 d-flex flex-row align-items-center page-padding container-large px-0 mt-4"
      >
        <div class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Short Let"></lazy-library-text>
            <nc-select
              v-model="filters.short_let"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="House Flat Share"></lazy-library-text>
            <nc-select
              v-model="filters.house_flat_share"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Pets Allowed"></lazy-library-text>
            <nc-select
              v-model="filters.pets_allowed"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Bills Included"></lazy-library-text>
            <nc-select
              v-model="filters.bills_included"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
      </div>
      <div class="row gy-0 gx-3 d-flex flex-row align-items-center page-padding container-large px-0 mt-4">
        <div v-if="searchType === searchTypes.LETTINGS" class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Furnishing"></lazy-library-text>
            <nc-select
              v-model="filters.furnishing"
              :options="furnishingOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div v-if="searchType === searchTypes.SALES" class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="New Homes"></lazy-library-text>
            <nc-select
              v-model="filters.new_homes"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Retirement Homes"></lazy-library-text>
            <nc-select
              v-model="filters.retirement_homes"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div v-if="searchType === searchTypes.SALES" class="col-12 col-md-3">
          <div class="d-flex flex-column">
            <lazy-library-text type="input-label" class="no-uppercase" text="Shared Ownership"></lazy-library-text>
            <nc-select
              v-model="filters.shared_ownership"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <multiple-input-group>
            <template #front-item>
              <div class="d-flex flex-column">
                <lazy-library-text type="input-label" class="no-uppercase" text="Must Haves"></lazy-library-text>
                <div
                  class="w-100 advanced-input d-flex flex-row align-items-center justify-content-center p-3 text-capitalize tw-bg-white"
                  style="border: 1px solid var(--default-border-color)"
                >
                  {{ mustHavesLabel }}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="d-flex flex-row w-100 justify-content-around">
                <nc-checkbox
                  v-model="filters.must_haves"
                  mode="multiple"
                  :multiple-checkbox-list="[
                    {
                      value: mustHavesOptions.GARDEN,
                      label: 'Garden',
                    },
                    {
                      value: mustHavesOptions.PARKING,
                      label: 'Parking',
                    },
                  ]"
                ></nc-checkbox>
              </div>
            </template>
          </multiple-input-group>
        </div>
      </div>
      <div class="row gy-0 gx-3 d-flex flex-row align-items-center page-padding container-large px-0 mt-4">
        <featured-property-type-and-styles v-model:filters="filters" />
      </div>
      <div class="row gy-0 gx-3 d-flex flex-row align-items-center page-padding container-large px-0 mt-4">
        <div class="col-12 d-flex flex-row justify-content-center">
          <div class="d-flex flex-row align-items-center n-cursor-pointer" @click="onOpenAllStylesPopup()">
            <span class="nc-icon-more-circle" style="font-size: 24px"></span>
            <lazy-library-text text="Other / Advanced" class="tw-ml-1"></lazy-library-text>
          </div>
        </div>
      </div>
      <div class="advanced-search-button tw-mt-3 lg:tw-hidden">
        <nc-button label="Search" @on-click="onSearchMobile()"> </nc-button>
      </div>
    </lazy-library-container>
  </div>

  <div
    v-else
    class="advanced-search tw-relative"
    :class="
      isMobile
        ? 'tw-px-3'
        : 'property-filters-mega-menu !tw-fixed !tw-left-1/2 !tw-w-[97%] !-tw-translate-x-1/2 !-tw-translate-y-[20%] !tw-px-20 !tw-py-14'
    "
    style="border-radius: var(--rounded)"
  >
    <lazy-library-container>
      <div class="n-buttons-container tw-flex tw-w-full tw-justify-between tw-pb-6">
        <h3 class="nc-advanced-filter-popup-title">Filters</h3>

        <div class="tw-flex tw-items-center tw-gap-4">
          <nc-button
            class="closeBtn search-btn tw-hidden tw-cursor-pointer lg:tw-block"
            label="Search"
            @on-click="didTriggerSearch()"
          >
          </nc-button>

          <div
            class="closeBtn advanced-close-btn n-cursor-pointer tw-flex tw-items-center tw-justify-center max-lg:tw-hidden"
            @click="$emit('close-advanced-filter')"
          >
            <span class="!tw-text-4xl tw-font-light">&times;</span>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4">
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <multiple-input-group>
            <template #front-item>
              <div class="tw-flex tw-flex-col">
                <p class="tw-mb-1">Bathrooms</p>
                <div
                  class="input-group tw-flex tw-w-full tw-items-center tw-justify-between tw-border-[1.5px] tw-p-4"
                  style="border-color: var(--c-border); border-radius: var(--rounded-input)"
                >
                  <p>{{ bathroomLabel }}</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="var(--c-icon)" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="tw-flex tw-w-full tw-gap-4 tw-px-2">
                <select v-model="filters.min_bathroom">
                  <option
                    v-for="(minPreference, index) in minPreferences"
                    :key="`minBathroomPreference-${index}`"
                    :value="minPreference.value"
                  >
                    {{ minPreference.display }}
                  </option>
                </select>
                <select v-model="filters.max_bathroom">
                  <option
                    v-for="(maxPreference, index) in maxPreferences"
                    :key="`maxBathroomPreference-${index}`"
                    :value="maxPreference.value"
                  >
                    {{ maxPreference.display }}
                  </option>
                </select>
              </div>
            </template>
          </multiple-input-group>
        </div>

        <div class="tw-col-span-12 lg:tw-col-span-3">
          <multiple-input-group>
            <template #front-item>
              <div class="tw-flex tw-flex-col">
                <p class="tw-mb-1">Reception</p>
                <div
                  class="input-group tw-flex tw-w-full tw-items-center tw-justify-between tw-border-[1.5px] tw-px-4 tw-py-4"
                  style="border-color: var(--c-border); border-radius: var(--rounded-input)"
                >
                  <p>{{ receptionLabel }}</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="var(--c-icon)" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="tw-flex tw-w-full tw-gap-4 tw-px-2">
                <select v-model="filters.min_reception">
                  <option
                    v-for="(minPreference, index) in minPreferences"
                    :key="`minReceptionPreference-${index}`"
                    :value="minPreference.value"
                    class="tw-text-nowrap"
                  >
                    {{ minPreference.display }}
                  </option>
                </select>
                <select v-model="filters.max_reception">
                  <option
                    v-for="(maxPreference, index) in maxPreferences"
                    :key="`maxReceptionPreference-${index}`"
                    :value="maxPreference.value"
                    class="tw-text-nowrap"
                  >
                    {{ maxPreference.display }}
                  </option>
                </select>
              </div>
            </template>
          </multiple-input-group>
        </div>
        <div class="tw-col-span-12 tw-h-full lg:tw-col-span-6">
          <div class="tw-mt-4 tw-flex tw-h-full tw-flex-col tw-justify-center lg:tw-ml-7">
            <nc-checkbox
              id="available-properties"
              v-model="isAvailableModel"
              :label="availablePropertiesLabel"
              :single-true-value="false"
              :single-false-value="true"
            />
          </div>
        </div>
      </div>
      <div v-if="searchType === searchTypes.LETTINGS" class="tw-grid tw-grid-cols-12 tw-items-center tw-gap-4 tw-pt-5">
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col">
            <p>Short Let</p>
            <nc-select
              v-model="filters.short_let"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col">
            <p>House Flat Share</p>
            <nc-select
              v-model="filters.house_flat_share"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col">
            <p>Pets Allowed</p>
            <nc-select
              v-model="filters.pets_allowed"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col">
            <p>Bills Included</p>
            <nc-select
              v-model="filters.bills_included"
              :options="inclusionFilterOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-12 tw-items-center tw-gap-4 tw-pt-7">
        <div v-if="searchType === searchTypes.LETTINGS" class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col">
            <p>Furnishing</p>
            <nc-select
              v-model="filters.furnishing"
              :options="furnishingOptions"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div v-if="searchType === searchTypes.SALES" class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <p>New Homes</p>
            <nc-select
              v-model="filters.new_homes"
              :options="inclusionFilterOptions"
              class="nc-select"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <p>Retirement Homes</p>
            <nc-select
              v-model="filters.retirement_homes"
              :options="inclusionFilterOptions"
              class="nc-select"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div v-if="searchType === searchTypes.SALES" class="tw-col-span-12 lg:tw-col-span-3">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <p>Shared Ownership</p>
            <nc-select
              v-model="filters.shared_ownership"
              :options="inclusionFilterOptions"
              class="nc-select"
              value-field="value"
              label-field="title"
            ></nc-select>
          </div>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-3">
          <multiple-input-group>
            <template #front-item>
              <div class="tw-flex tw-flex-col">
                <p class="tw-mb-1">Must Haves</p>
                <div
                  class="input-group tw-flex tw-w-full tw-items-center tw-justify-center tw-border-[1.5px] tw-p-4 tw-capitalize"
                  style="border-color: var(--c-border); border-radius: var(--rounded-input)"
                >
                  <p>{{ mustHavesLabel }}</p>
                  <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="var(--c-icon)" />
                  </svg>
                </div>
              </div>
            </template>
            <template #group>
              <div class="dropdown-checkbox tw-flex tw-w-full tw-justify-around">
                <nc-checkbox
                  v-model="filters.must_haves"
                  mode="multiple"
                  :multiple-checkbox-list="[
                    {
                      value: mustHavesOptions.GARDEN,
                      label: 'Garden',
                    },
                    {
                      value: mustHavesOptions.PARKING,
                      label: 'Parking',
                    },
                  ]"
                ></nc-checkbox>
              </div>
            </template>
          </multiple-input-group>
        </div>
      </div>

      <div class="tw-grid tw-items-center tw-pt-7">
        <featured-property-type-and-styles v-model:filters="filters" version="v2" />
      </div>
      <div class="tw-flex tw-items-center tw-justify-center tw-pt-8">
        <div class="tw-flex tw-cursor-pointer tw-items-center" @click="onOpenAllStylesPopup()">
          <span class="nc-icon-more-circle tw-mr-1" style="font-size: 24px"></span>
          <p>Other / Advanced</p>
        </div>
      </div>
      <div class="advanced-search-button tw-mt-3 lg:tw-hidden">
        <nc-button label="Search" @on-click="onSearchMobile()"> </nc-button>
      </div>
    </lazy-library-container>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import { PFilterMustHaves, PFilterSearchType } from '~/units/properties/types';
import type { PropertiesFilter } from '~/units/properties/types';
import CriteriaPreferencesConstants from '~/units/properties/constants/criteriaPreferences.constants';
import FeaturedPropertyTypeAndStyles from '~/components/common/properties/FeaturedPropertyTypeAndStyles.vue';
import Popup from '~/components/common/popup/Popup.vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import { useCoreStore } from '~/units/core/store';
import NcButton from '~/components/common/inputs/NcButton.vue';
import NcRadio from '~/components/common/inputs/NcRadio.vue';
import { useWebsiteStore } from '~/units/website/store';
import NcSelect from '~/components/common/inputs/NcSelect.vue';

export default defineNuxtComponent({
  name: 'AdvancedSearch',
  components: {
    NcSelect,
    NcRadio,
    NcButton,
    NcCheckbox,
    Popup,
    FeaturedPropertyTypeAndStyles,
    MultipleInputGroup,
  },

  props: {
    filters: {
      required: true,
      type: Object as PropType<PropertiesFilter>,
    },

    searchType: {
      required: true,
      type: String as PropType<PFilterSearchType>,
    },

    isAvailable: {
      required: true,
      type: Boolean,
    },

    theme: {
      required: false,
      type: String,
      default: 'theme3',
    },
  },

  data() {
    return {
      searchTypes: PFilterSearchType,
      searchTypeFilter: PFilterSearchType.SALES,
      scrollPosition: 0,
    };
  },

  emits: ['update:isAvailable', 'onOpenAllStylesPopup', 'onSearched', 'open-extended-popup', 'close-advanced-filter'],

  created() {
    this.searchTypeFilter = this.searchType;
  },

  mounted() {
    this.disableScroll();
  },

  unmounted() {
    this.enableScroll();
  },

  computed: {
    ...mapState(useCoreStore, ['isMobile']),
    ...mapState(useWebsiteStore, ['propertiesConfig']),

    searchTypeFilters() {
      return [
        {
          value: PFilterSearchType.SALES,
          label: 'For Sale',
        },
        {
          value: PFilterSearchType.LETTINGS,
          label: 'For Rent',
        },
      ];
    },

    mustHavesOptions() {
      return PFilterMustHaves;
    },

    isAvailableModel: {
      get() {
        return this.isAvailable;
      },
      set(value: boolean) {
        this.$emit('update:isAvailable', value);
      },
    },

    availablePropertiesLabel() {
      return `Include ${this.searchType === PFilterSearchType.SALES ? 'SSTC, Under Offer' : 'Let Agreed'}?`;
    },

    inclusionFilterOptions() {
      return CriteriaPreferencesConstants.inclusionFilterOptions;
    },

    furnishingOptions() {
      return CriteriaPreferencesConstants.furnishingTypes;
    },

    bathroomLabel() {
      const min = this.filters.min_bathroom !== null ? `Min ${this.filters.min_bathroom}` : 'No Min';
      const max = this.filters.max_bathroom !== null ? `Max ${this.filters.max_bathroom}` : 'No Max';

      return `${min} - ${max} Baths`;
    },

    receptionLabel() {
      const min = this.filters.min_reception !== null ? `Min ${this.filters.min_reception}` : 'No Min';
      const max = this.filters.max_reception !== null ? `Max ${this.filters.max_reception}` : 'No Max';

      return `${min} - ${max} Reception`;
    },

    mustHavesLabel() {
      if (!this.filters.must_haves || this.filters.must_haves.length === 0) {
        return 'Please select';
      }
      return this.filters.must_haves.join(', ');
    },

    minPreferences() {
      return CriteriaPreferencesConstants.getPreferences('min');
    },
    maxPreferences() {
      return CriteriaPreferencesConstants.getPreferences('max');
    },
  },

  methods: {
    onOpenAllStylesPopup() {
      this.$emit('onOpenAllStylesPopup');
    },

    onSearchMobile() {
      this.$emit('onSearched');
    },

    onSearchTypeChanged() {
      if (this.searchTypeFilter === PFilterSearchType.SALES) {
        this.redirectTo({
          path: '/sales/property-for-sale',
        });
      } else if (this.searchTypeFilter === PFilterSearchType.LETTINGS) {
        this.redirectTo({
          path: '/lettings/property-to-rent',
        });
      }
    },

    disableScroll() {
      try {
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${this.scrollPosition}px`;
      } catch (error) {
        console.error('Error in disableScroll:', error);
      }
    },

    enableScroll() {
      try {
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, this.scrollPosition);
      } catch (error) {
        console.error('Error in enableScroll:', error);
      }
    },

    didTriggerSearch() {
      this.$emit('open-extended-popup');
    },
  },
});
</script>

<style scoped>
.property-filters-mega-menu {
  position: absolute;
  top: 270px;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
  max-height: 82vh;
  overflow-y: auto;
  background: -webkit-gradient(linear, left top, left bottom, from(#eff1f2), to(#e3e5e7));
  background: var(--white);
  border-bottom: 1px solid var(--brown--secondary);
  box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
  padding: 3rem 0 3rem 0;
  opacity: 1;
  visibility: visible;
  z-index: 50;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
  -webkit-transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in;
  transition:
    opacity 0.3s,
    transform 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    -webkit-transform 0.3s;
}

.property-filters-mega-menu::-webkit-scrollbar {
  width: 8px;
}

.property-filters-mega-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.property-filters-mega-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.property-filters-mega-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 768px) {
  .property-filters-mega-menu {
    max-height: 70vh;
    top: 200px;
  }
}

.nc-select ::v-deep(select) {
  padding: 1rem;
  border: 1.5px solid var(--c-border);
}
</style>
