<template>
  <div
    class="n-card tw-relative tw-flex tw-w-full tw-flex-col tw-gap-4 tw-overflow-hidden tw-text-start"
    style="border-radius: var(--rounded)"
  >
    <nuxt-link
      :external="true"
      :to="property.detail_page_url"
      class="n-link tw-group tw-relative tw-flex tw-flex-col tw-gap-4"
    >
      <!-- Görsel ve Badge Wrapper -->
      <div class="tw-relative tw-overflow-hidden" style="border-radius: var(--rounded)">
        <LibraryImage
          :src="property.image"
          :h-ratio="10"
          :w-ratio="16"
          class="hover-photo tw-h-48 tw-w-full tw-object-cover tw-duration-500 group-hover:tw-scale-110"
          style="border-radius: var(--rounded)"
        />

        <!-- Hover Overlay -->
        <div
          class="tw-absolute tw-inset-0 tw-bg-[var(--c-secondary)] tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-30"
          style="border-radius: var(--rounded)"
        ></div>

        <div
          v-if="property.custom_property_status_title"
          class="nc-property-image-badge tw-absolute tw-left-0 tw-top-0"
        >
          {{ property.custom_property_status_title }}
        </div>
      </div>

      <!-- İçerik -->
      <div class="tw-flex tw-flex-col tw-gap-8">
        <h4 class="tw-line-clamp-2" style="color: var(--c-primary)">
          {{ property.advert_heading }}
        </h4>
        <p class="tw-line-clamp-2 tw-h-[54px]">
          {{ property.display_address }}
        </p>
        <div class="tw-flex tw-flex-col tw-gap-2">
          <span v-if="property.price.type.name">
            {{ property.price.type.name }}
          </span>
          <h4 style="color: var(--c-primary); font-family: var(--f-secondary) !important">
            {{ property.price.price }}
          </h4>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-8">
          <div class="tw-flex tw-flex-col tw-items-start">
            <small>BEDROOM</small>
            <span>{{ property.bedroom }}</span>
          </div>
          <div class="tw-flex tw-flex-col tw-items-start">
            <small>BATHROOM</small>
            <span>{{ property.bathroom }}</span>
          </div>
          <div class="tw-flex tw-flex-col tw-items-start">
            <small>RECEPTION</small>
            <span>{{ property.reception }}</span>
          </div>
        </div>
        <div>
          <button class="n-tertiary !tw-justify-start">
            VIEW THIS PROPERTY
            <span class="btn-arrow">
              <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertyListItem',
  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },
    index: {
      type: Number,
      required: true,
    },
    isMatchInformationHidden: {
      required: false,
      type: Boolean,
      default: false,
    },
    featureCountNumber: {
      required: false,
      type: Number,
      default: 3,
    },
    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },
});
</script>
