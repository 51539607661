<template>
  <div v-if="variants.v1 === variant">
    <register :component="component" />
    <popup :config="modalConfig" custom-width="550px" @close="onSuccessModalClosed()">
      <template #header><div></div></template>
      <template #content>
        <div class="d-flex flex-column nc-text">
          <lazy-library-title
            class="tw-mb-3"
            tag="h2"
            html="How our Heads Up Property
            <br class='tw-hidden lg:tw-visible' />Alerts help you"
          >
          </lazy-library-title>
          <span class="tw-inline">
            <lazy-library-text :html="registrationEntryPopupText" tag="span"> </lazy-library-text>
            <lazy-library-text text="Let's get started..." tag="span"></lazy-library-text>
          </span>
          <div class="md:tw-gap-y0 tw-mt-4 tw-flex tw-flex-col tw-gap-y-2 lg:tw-flex-row" style="column-gap: 10px">
            <nc-button
              link="/heads-up-alerts/register/buy"
              :external="true"
              :is-link="true"
              label="looking to buy"
            ></nc-button>
            <nc-button
              link="/heads-up-alerts/register/rent"
              label="looking to rent"
              :external="true"
              :is-link="true"
            ></nc-button>
          </div>
        </div>
      </template>
    </popup>
  </div>

  <div v-else-if="variants.v2 === variant">
    <register :component="component" />
    <popup :config="modalConfig" custom-width="550px" @close="onSuccessModalClosed()">
      <template #header><div></div></template>
      <template #content>
        <div class="tw-flex tw-flex-col">
          <div v-if="vars.popupTitleHtml" class="tw-mb-3" v-html="vars.popupTitleHtml"></div>
          <h3
            v-else-if="vars.popupTitleText"
            class="register-entry-title tw-mb-3"
            :class="{ 'tw-text-center': vars.textCenterBool }"
          >
            {{ vars.popupTitleText }}
          </h3>

          <h3 v-else class="register-entry-title tw-mb-3">
            How our Heads Up Property
            <br class="tw-hidden lg:tw-visible" />Alerts help you
          </h3>
          <span class="tw-inline">
            <p v-html="registrationEntryPopupText" :class="{ 'tw-text-center': vars.textCenterBool }" />
            <br />
            <p>Let's get started...</p>
          </span>
          <div
            class="md:tw-gap-y0 tw-mt-4 tw-flex tw-flex-col tw-gap-y-2 lg:tw-flex-row"
            style="column-gap: 10px"
            :class="{ 'tw-items-center tw-justify-center': vars.textCenterBool }"
          >
            <nuxt-link v-if="vars.buyBool" to="/heads-up-alerts/register/buy" :external="true" class="n-primary">
              LOOKING TO BUY
            </nuxt-link>

            <nuxt-link v-if="vars.rentBool" to="/heads-up-alerts/register/rent" :external="true" class="n-primary">
              LOOKING TO RENT
            </nuxt-link>
          </div>
        </div>
      </template>
    </popup>
  </div>

  <div v-else-if="variants.v3 === variant">
    <register :component="component" />
    <popup :config="modalConfig" custom-width="710px" @close="onSuccessModalClosed()">
      <template #header><div></div></template>
      <template #content>
        <div class="tw-flex tw-flex-col tw-p-4">
          <div v-if="vars.popupTitleHtml" class="tw-mb-3" v-html="vars.popupTitleHtml"></div>
          <h3
            v-else-if="vars.popupTitleText"
            class="register-entry-title tw-mb-3"
            :class="{ 'tw-text-center': vars.textCenterBool }"
          >
            {{ vars.popupTitleText }}
          </h3>

          <h3 v-else class="register-entry-title tw-mb-3">
            How our Heads Up Property
            <br class="tw-hidden lg:tw-visible" />Alerts help you
          </h3>
          <span class="tw-inline">
            <p v-html="registrationEntryPopupText" :class="{ 'tw-text-center': vars.textCenterBool }" />
            <p>Let's get started...</p>
          </span>
          <div
            class="tw-mt-9 tw-flex tw-flex-col tw-gap-x-6 tw-gap-y-2 md:tw-flex-row md:tw-gap-y-0"
            :class="{ 'tw-items-center tw-justify-center': vars.textCenterBool }"
          >
            <nuxt-link v-if="vars.buyBool" to="/heads-up-alerts/register/buy" :external="true" class="n-primary">
              LOOKING TO BUY
            </nuxt-link>

            <nuxt-link v-if="vars.rentBool" to="/heads-up-alerts/register/rent" :external="true" class="n-primary">
              LOOKING TO RENT
            </nuxt-link>
          </div>
        </div>
      </template>
    </popup>
  </div>

  <div v-else-if="variants.v4 === variant" class="register-popup-container">
    <register :component="component" />
    <popup :config="modalConfig" custom-width="710px" @close="onSuccessModalClosed()">
      <template #header><div></div></template>
      <template #content>
        <div class="tw-flex tw-flex-col tw-p-4">
          <div v-if="vars.popupTitleHtml" class="tw-mb-3" v-html="vars.popupTitleHtml"></div>
          <h3
            v-else-if="vars.popupTitleText"
            class="register-entry-title tw-mb-3"
            style="color: var(--c-primary) !important"
            :class="{ 'tw-text-center': vars.textCenterBool }"
          >
            {{ vars.popupTitleText }}
          </h3>

          <h3 v-else class="register-entry-title tw-mb-3" style="color: var(--c-primary) !important">
            How our Heads Up Property
            <br class="tw-hidden lg:tw-visible" />Alerts help you
          </h3>
          <span class="tw-inline">
            <p v-html="registrationEntryPopupText" :class="{ 'tw-text-center': vars.textCenterBool }" />
            <p>Let's get started...</p>
          </span>
          <div
            class="tw-mt-9 tw-flex tw-flex-col tw-gap-x-6 tw-gap-y-2 md:tw-flex-row md:tw-gap-y-0"
            :class="{ 'tw-items-center tw-justify-center': vars.textCenterBool }"
          >
            <nuxt-link
              v-if="vars.buyBool"
              to="/heads-up-alerts/register/buy"
              :external="true"
              class="n-primary !tw-justify-start"
            >
              LOOKING TO BUY
              <span class="btn-arrow"
                ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>

            <nuxt-link
              v-if="vars.rentBool"
              to="/heads-up-alerts/register/rent"
              :external="true"
              class="n-primary !tw-justify-start"
            >
              LOOKING TO RENT
              <span class="btn-arrow"
                ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>
          </div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
import Register from '~/components/common/register/Register.vue';
import { useSettingsStore } from '~/units/settings/store';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { PFilterSearchType } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'RegisterEntry',
  components: { NcButton, Register, Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      modalConfig: {
        isActive: true,
      },
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
        v4: 'v4',
      },
    };
  },

  computed: {
    ...mapState(useSettingsStore, ['registrationEntryPopupText']),
    searchTypes() {
      return PFilterSearchType;
    },

    variant() {
      return useVariant(this.component);
    },
  },

  methods: {
    onSuccessModalClosed() {},
  },
});
</script>

<style scoped></style>
