<template>
  <div class="nc-tooltip-container" @click.prevent.stop="onClickPrevented()">
    <div class="nc-tooltip-base">
      <slot name="item" />
    </div>
    <transition name="fade">
      <!-- <div class="nc-tooltip-content" :style="`width: ${width}px !important;`">
        <slot name="tooltip" />
      </div> -->
      <div class="nc-tooltip-content">
        <slot name="tooltip" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'NcTooltip',
  props: {
    width: {
      required: false,
      default: 200,
      type: Number,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    onClickPrevented() {},
  },
});
</script>

<style scoped>
.nc-tooltip-container {
  position: relative;
}

.nc-tooltip-base {
}

.nc-tooltip-content {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: -1;
  transition:
    opacity 0.3s,
    visibility 0.4s,
    z-index 0.4s ease-in,
    transform 0.3s;
}

.nc-tooltip-container:hover .nc-tooltip-content {
  opacity: 1 !important;
  display: block;
  visibility: visible !important;
  z-index: 9999999 !important;
  color: #000;
  background-color: #fff;
  bottom: 10px;
  right: -35px;
  padding: 0.25rem 1rem 1rem;
  border: 1px solid var(--ternary-color);
  border-radius: 6px;
  box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
  width: 200px;
  transform: translateY(-10%);
}

.nc-tooltip-content::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 2.8rem;
  z-index: 2;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: var(--ternary-color);
  transform: rotate(135deg) translateY(-63%);
}

@media (max-width: 991px) {
  .nc-tooltip-container:hover .nc-tooltip-content {
    bottom: 10px;
    right: -2px;
  }

  .nc-tooltip-content::before {
    right: 15px;
  }
}
</style>
