import type { PropType } from 'vue';
import { ViewTypes } from '~/constants/ui.constants';
import { getFromState } from '~/composables';
import { currencyFormat } from '~/helpers';

import type { Component } from '~/types';
import { mapProperty } from '~/units/properties/store';
import type { SinglePropertyRepo, SingleProperty } from '~/units/properties/types';
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';
import { mapActions } from 'pinia';
import { usePropertiesStore } from '~/units/properties/stores';

export const PropertyMixin = defineNuxtComponent({
  props: {
    component: {
      required: true,
      type: Object as PropType<Component>,
    },
  },

  mixins: [FullScreenPopupMixin],

  data() {
    return {
      ViewTypes,

      activeMediaIndex: 1,

      sendLeadPopupConfig: {
        isActive: false,
      },
      successModalConfig: {
        isActive: false,
      },

      successPopupText: 'Your request has been sent successfully.',
    };
  },

  async mounted() {
    const propertiesStore = usePropertiesStore();
    await propertiesStore.addPotentialViewing(this.property?.property_profile_id);
  },

  computed: {
    singlePropertyRepoRef() {
      const data = getFromState('singlePropertyRepo') as SinglePropertyRepo;
      const route = useRoute();
      const identifier = route.params.property_id;

      if (data && data.length > 0) {
        return data.find((item) => item.identifier === identifier);
      }
      return null;
    },

    property() {
      const property = this.singlePropertyRepoRef as SingleProperty;

      if (property && property.data) {
        const item = property.data;
        return mapProperty({ info: item }, 0);
      }
      return null;
    },

    activeMedia() {
      if (this.property && this.property.medias) {
        return this.property.medias[this.activeMediaIndex];
      }
      return null;
    },
  },

  methods: {
    ...mapActions(usePropertiesStore, ['goBackToPropertyList']),

    convert(value: number): string {
      return currencyFormat(value);
    },

    cutText(value: string): string {
      if (value) {
        return `${value.trim().slice(0, 120)}...`;
      }
      return '';
    },

    onClickedImage(image: { property_profile_media_id: number } | null) {
      if (this.property && this.property.medias && image?.property_profile_media_id) {
        if (image) {
          this.activeMediaIndex = this.property.medias.findIndex(
            (media) => media.property_profile_media_id === image.property_profile_media_id,
          );
        } else {
          this.activeMediaIndex = 2;
        }

        this.openJustLightBox('gallery');
      }
    },

    onSuccessModalClosed() {
      this.successModalConfig.isActive = false;
    },

    onSuccess() {
      this.sendLeadPopupConfig.isActive = false;
      this.successModalConfig.isActive = true;
    },

    onSendLead() {
      this.sendLeadPopupConfig.isActive = true;
    },
  },
});
