<template>redirecting...</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'RedirectTo',

  mixins: [ComponentMixin],

  data() {
    return {};
  },

  async setup(props) {
    const redirectUrl = getVariable(props.component, 'redirect-url');
    const redirectCode = getVariable(props.component, 'redirect_code-number');
    await navigateTo(redirectUrl as string, {
      redirectCode: redirectCode || 301,
    });
  },
});
</script>
<style scoped></style>
