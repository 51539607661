export const isValueFilled = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return [null, false, undefined, '', ' '].every((type) => type !== value);
};

export const isTrimmedValueFilled = (value: any) => {
  if (value) {
    return isValueFilled(value.trim());
  }
  return false;
};
