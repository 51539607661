<template>
  <div v-if="version === 'v1'" class="pm-checkbox-group type-2" @click="toggle">
    <input :id="`property_style-${index}`" disabled :checked="isSelected" type="checkbox" class="pm-checkbox" />
    <label :for="`property_style-${index}`">
      <lazy-library-text :text="propertyStyle.name"></lazy-library-text>
    </label>
  </div>

  <div v-else-if="version === 'v2'" class="pm-checkbox-group type-2 !tw-bg-red-500" @click="toggle">
    <input
      :id="`property_style-${index}`"
      disabled
      :checked="isSelected"
      type="checkbox"
      class="pm-checkbox !tw-bg-red-500"
    />
    <label :for="`property_style-${index}`">
      <p>{{ propertyStyle.name }}</p>
    </label>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import type { PFilterPropertyTypes } from '~/units/properties/types';
import type { PropertyStyle } from '~/units/property_type_styles/types';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';

export default {
  name: 'PropertyStyleItem',
  components: {},

  mixins: [SubComponentMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    propertyStyle: {
      required: true,
      type: Object as PropType<PropertyStyle>,
    },

    selectedTypeStyles: {
      required: true,
      type: Array as PropType<PFilterPropertyTypes[]>,
    },

    updatePropertyTypeStyle: {
      required: true,
      type: Function,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  emits: ['update:selectedTypeStyles'],
  computed: {
    selectedTypeStylesModel: {
      get() {
        return this.selectedTypeStyles;
      },
      set(value: PFilterPropertyTypes[]) {
        this.$emit('update:selectedTypeStyles', value);
      },
    },

    propertyTypeIndex() {
      return this.selectedTypeStyles.findIndex((i) => i.property_type_id === this.propertyStyle.property_type_id);
    },

    isSelected() {
      if (this.propertyTypeIndex >= 0) {
        return this.selectedTypeStyles[this.propertyTypeIndex].property_style_ids.includes(
          this.propertyStyle.property_style_id,
        );
      }
      return false;
    },
  },
  methods: {
    toggle() {
      if (this.isSelected) {
        this.setRemoved();
      } else {
        this.setSelected();
      }
    },

    setSelected() {
      // if property type is already selected, just push style into array
      if (this.propertyTypeIndex >= 0) {
        const activePropertyType = this.selectedTypeStyles[this.propertyTypeIndex];

        this.updatePropertyTypeStyle({
          type: 'UPDATE_PROPERTY_TYPE',
          payload: {
            property_style_ids: [...activePropertyType.property_style_ids, this.propertyStyle.property_style_id],
            property_type_id: activePropertyType.property_type_id,
          },
          index: this.propertyTypeIndex,
        });
      } else {
        this.updatePropertyTypeStyle({
          type: 'ADD_NEW_PROPERTY_TYPE',
          payload: {
            property_type_id: this.propertyStyle.property_type_id,
            property_style_ids: [this.propertyStyle.property_style_id],
          },
        });
      }
    },

    setRemoved() {
      const selectedFilters = this.selectedTypeStyles;

      // find index of style in selected styles array
      const styleIndex = selectedFilters[this.propertyTypeIndex].property_style_ids.findIndex(
        (i) => i === this.propertyStyle.property_style_id,
      );

      // if style exists in selected filters
      if (styleIndex >= 0) {
        // set data with reference of active property type
        const activePropertyType = selectedFilters[this.propertyTypeIndex];

        // removes style that clicked on
        activePropertyType.property_style_ids.splice(styleIndex, 1);

        // if property type does not have any property style ids, remove it.
        if (activePropertyType.property_style_ids.length === 0) {
          this.updatePropertyTypeStyle({
            type: 'REMOVE_PROPERTY_TYPE',
            index: this.propertyTypeIndex,
          });
        } else {
          // otherwise just update property type with new format.
          this.updatePropertyTypeStyle({
            type: 'UPDATE_PROPERTY_TYPE',
            payload: activePropertyType,
            index: this.propertyTypeIndex,
          });
        }
      }
    },
  },
};
</script>
