import { mapState } from 'pinia';
import { isValueFilled, isTrimmedValueFilled, deepClone } from '~/units/core/providers';
import { produceKey } from '~/server/core/helpers';

import CommunicationMethodsConstants from '~/units/core/constants/communication.methods';
import { ContactProfiles } from '~/constants/contacts';
import { PFilterSearchType } from '~/units/properties/types';
import { useContactsStore } from '~/units/contacts/store';

const getPreferredCommunicationInfoIndex = (infos: any, type: any) => {
  const preferredInfo = infos.findIndex((info: any) => info.communication_method_id === type && info.preferred);
  if (preferredInfo > -1) {
    return preferredInfo;
  }

  const methodMatchedInfo = infos.findIndex((info: any) => info.communication_method_id === type);
  if (methodMatchedInfo > -1) {
    return methodMatchedInfo;
  }

  return null;
};

export const ContactsRegistrationMixin = defineNuxtComponent({
  data() {
    return {
      validationControls: {
        email: true,
        mobilePhone: true,
      },
    };
  },
  computed: {
    ...mapState(useContactsStore, ['subscriptions']),

    defaultProfiles() {
      if (this.searchType === PFilterSearchType.LETTINGS) {
        return [ContactProfiles.TENANT];
      }
      return [ContactProfiles.BUYER];
    },
  },
  methods: {
    save() {
      try {
        const contactData = deepClone(this.contactForm);

        if (!isTrimmedValueFilled(contactData.name)) {
          throw 'Name is required';
        }

        if (!isTrimmedValueFilled(contactData.surname)) {
          throw 'Surname is required';
        }

        if (!isTrimmedValueFilled(contactData.email) && !isTrimmedValueFilled(contactData.mobilePhone)) {
          throw 'Email or phone number is required';
        }

        if (isTrimmedValueFilled(contactData.email) && !this.validationControls.email) {
          throw 'Please enter a valid email address.';
        }

        if (isTrimmedValueFilled(contactData.mobilePhone) && !this.validationControls.mobilePhone) {
          throw 'Please enter a valid mobile phone number.';
        }

        if (
          !this.fullAddress ||
          !this.fullAddress.display_name ||
          !isTrimmedValueFilled(this.fullAddress.display_name)
        ) {
          throw 'Please enter an address.';
        }

        if (!contactData.isSubscriptionConfirmed) {
          throw 'Please confirm subscription.';
        }

        const communication_infos = this.prepareCommunicationInfos(contactData);
        const contact_id = this.prepareContactId(contactData);
        const contact_addresses = this.prepareContactAddress();
        const contact_profiles = this.prepareContactProfiles(contactData);

        const contact = {
          avatar: contactData.avatar,
          contact_profiles,
          salutation_id: contactData.salutation_id,
          name: contactData.name.trim(),
          surname: contactData.surname.trim(),
          contact_id,
          communication_infos,
          contact_addresses,
        };

        this.contacts.push(contact);
        this.closeContactForm();
        this.refreshForm();

        this.checkRequirementsForContacts();
      } catch (e) {
        this.raiseSaveValidationError(e);
      }
    },

    cancel() {
      if (this.contacts.length > 0) {
        this.closeContactForm();
      }
      this.refreshForm();
    },

    closeContactForm() {
      this.isFormActive = false;
    },

    openContactForm() {
      this.isFormActive = true;
    },

    prepareCommunicationInfos(contactData) {
      const communication_infos = [];
      if (isValueFilled(contactData.email.trim())) {
        communication_infos.push({
          value: contactData.email.trim(),
          preferred: true,
          communication_method_id: CommunicationMethodsConstants.EMAIL,
        });
      }

      if (isValueFilled(contactData.mobilePhone.trim())) {
        communication_infos.push({
          value: contactData.mobilePhone.trim(),
          preferred: true,
          communication_method_id: CommunicationMethodsConstants.MOBILE_PHONE_NUMBER,
        });
      }

      this.communications.forEach((communication) => {
        const isPreferredExist = communication_infos.findIndex(
          (info) => info.preferred && info.communication_method_id === communication.communication_method_id,
        );

        if (isValueFilled(communication.value.trim())) {
          communication_infos.push({
            value: communication.value.trim(),
            preferred: !(isPreferredExist > -1), // if there is no preferred communication, set is as preferred.
            communication_method_id: communication.communication_method_id,
          });
        }
      });

      if (communication_infos.length === 0) {
        throw 'Missing communication info';
      }

      return communication_infos;
    },

    prepareContactId(contactData) {
      return !contactData.contact_id ? this.generateContactId() : contactData.contact_id;
    },

    prepareContactProfiles(contactData) {
      return [...new Set([...contactData.contact_profiles, ...this.defaultProfiles])];
    },

    prepareContactAddress() {
      // contact addresses
      let contact_addresses = [];
      if (this.fullAddress && isValueFilled(this.fullAddress.display_name)) {
        contact_addresses = [this.fullAddress];
      }

      return contact_addresses;
    },

    raiseSaveValidationError(e) {
      this.errorMessage(e);
    },

    generateContactId() {
      return `contact-${produceKey()}`;
    },

    refreshForm() {
      this.contactForm = {
        avatar: null,
        contact_profiles: this.defaultProfiles,
        contact_id: this.generateContactId(),
        salutation_id: 2,
        name: '',
        surname: '',
        email: '',
        mobilePhone: '',
        isSubscriptionConfirmed: false,
      };

      this.communications = [];

      this.fullAddress = null;
    },

    mapSubscriptionTypes() {
      return this.subscriptions.map((subscription) => subscription.subscription_type_id);
    },

    removeContact(index) {
      if (this.contacts.length > 1) {
        this.contacts.splice(index, 1);
      } else {
        this.errorMessage('You must add a contact at least.');
      }
    },

    editContact(index) {
      const contactData = deepClone(this.contacts[index]);

      const contact = {
        avatar: contactData.avatar,
        contact_profiles: contactData.contact_profiles,
        contact_id: contactData.contact_id,
        salutation_id: contactData.salutation_id,
        name: contactData.name,
        surname: contactData.surname,
        email: '',
        mobilePhone: '',
        isSubscriptionConfirmed: true,
      };

      if (contactData.communication_infos && contactData.communication_infos.length > 0) {
        // retrieve preferred email and pass it as a specific input
        const preferredEmailIndex = getPreferredCommunicationInfoIndex(
          contactData.communication_infos,
          CommunicationMethodsConstants.EMAIL,
        );
        if (preferredEmailIndex !== null) {
          contact.email = contactData.communication_infos[preferredEmailIndex].value;
          contactData.communication_infos.splice(preferredEmailIndex, 1);
        }

        // retrieve preferred phone number and pass it as a specific input
        const preferredPhoneIndex = getPreferredCommunicationInfoIndex(
          contactData.communication_infos,
          CommunicationMethodsConstants.MOBILE_PHONE_NUMBER,
        );
        if (preferredPhoneIndex !== null) {
          contact.mobilePhone = contactData.communication_infos[preferredPhoneIndex].value;
          contactData.communication_infos.splice(preferredPhoneIndex, 1);
        }

        // pass rest communications as a external communications
        this.communications = [...contactData.communication_infos];
      }

      if (contactData.contact_addresses && contactData.contact_addresses.length > 0) {
        this.fullAddress = contactData.contact_addresses[0];
      }

      this.contactForm = contact;
      this.contacts.splice(index, 1);
      this.openContactForm();
    },

    parseOnTheFlyContact(contactData: any) {
      return {
        contact_id: contactData.contact_id,
        salutation_id: contactData.salutation_id,
        name: contactData.name,
        surname: contactData.surname,
        communication_infos: contactData.communication_infos.map((communication: any) => {
          return {
            communication_method_id: communication.communication_method_id,
            preferred: communication.preferred,
            value: communication.value,
          };
        }),
        contact_addresses: contactData.contact_addresses,

        contact_profiles: contactData.contact_profiles.map((profile: any) => profile.profile_id),

        avatar: contactData.avatar,
      };
    },

    parseOnTheFlyContacts() {
      this.onTheFlyContacts.forEach((contactData) => {
        this.contacts.push(this.parseOnTheFlyContact(contactData));
      });

      // checking address data of contacts to open form
      if (this.contacts.length > 0) {
        this.checkRequirementsForContacts();
      }
    },

    checkContactHasAddress(contact) {
      if (!contact.contact_addresses) {
        return false;
      }
      if (contact.contact_addresses && contact.contact_addresses.length === 0) {
        return false;
      }

      // if contact does not have display name
      return !(contact.contact_addresses[0] && !contact.contact_addresses[0].display_name);
    },

    checkRequirementsForContacts() {
      for (let i = 0; i < this.contacts.length; i += 1) {
        if (!this.checkContactHasAddress(this.contacts[i])) {
          this.editContact(i);
          break;
        }
      }
    },
  },
});
